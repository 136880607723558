<script>
import userService from "@/services/user";
import { mapGetters } from "vuex";

export default {
  name: "Profile",

  data() {
    return {
      isUpdatingProfile: false,
      isUpdatingPassword: false,
      profile: {
        firstName: "",
        lastName: "",
      },
    };
  },

  created() {
    this.profile = { ...this.profile, ...this.user };
  },

  computed: {
    ...mapGetters(["user"]),

    updateProfileButtonDisabled() {
      return (
        this.profile.firstName === this.user.firstName &&
        this.profile.lastName === this.user.lastName
      );
    },
  },

  methods: {
    updateProfile() {
      this.isUpdatingProfile = true;
      userService.updateMe({ ...this.profile }).finally(() => {
        this.isUpdatingProfile = false;
      });
    },

    validateProfile() {
      this.updateProfile();
    },
  },
};
</script>

<template lang="pug">
section.detail(v-else)
  .layout
    ValidationObserver(v-slot="{handleSubmit}")
      form.detail__content(@submit.prevent="handleSubmit(validateProfile)")
        h2.detail__headline.title Perfil
        br
        .row
          fieldset.detail__field
            label.label Nombre
            ValidationProvider(name="Nombre", rules="required", v-slot="{ errors }")
              el-input(
                v-model="profile.firstName"
              )
              span.has-error {{ errors[0] }}
        .row
          fieldset.detail__field
            label.label Apellido
            ValidationProvider(name="Apellido", rules="required", v-slot="{ errors }")
              el-input(
                v-model="profile.lastName"
              )
              span.has-error {{ errors[0] }}
        .detail__actions
          el-button(
            type="primary"
            native-type="submit"
            :disabled="updateProfileButtonDisabled"
            :loading="isUpdatingProfile"
          ) Guardar
</template>

<style lang="scss" scoped>
.layout {
  display: flex;
  justify-content: center;
}
.detail__content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.detail__actions {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
}
.title {
  margin-left: 10px;
}
.button {
  min-height: 40px;
}
</style>
