<script>
import Spinner from "@/components/ui/Spinner";
import InstitutionList from "@/components/settings/institution/InstitutionList";
import MedicalSpecialtyList from "@/components/settings/medicalSpecialty/MedicalSpecialtyList";
import LabelList from "@/components/settings/label/LabelList";
import CommissionList from "./commission/CommissionList";
import MedicalInsuranceList from "@/components/settings/medicalInsurance/MedicalInsuranceList";

export default {
  name: "SettingsDashboard",

  components: {
    Spinner,
    InstitutionList,
    LabelList,
    MedicalSpecialtyList,
    CommissionList,
    MedicalInsuranceList,
  },

  data() {
    return {
      category: "locations",
    };
  },

  methods: {
    getClassName(type) {
      const className = `${type}-color`;

      if (this.category === type) {
        return `${className} is-active`;
      } else {
        return className;
      }
    },

    setCategory(category) {
      this.category = category;
    },
  },
};
</script>

<template lang="pug">
section.treatment-tasks
  article.row
    nav.sidebar
      a.sidebar__link(@click="setCategory('locations')", :class="getClassName('locations')")
        micon(name="institution")
        strong.sidebar__text Instituciones
      a.sidebar__link(@click="setCategory('medical-specialty')", :class="getClassName('medical-specialty')")
        micon(name="medical-specialty")
        strong.sidebar__text Especialidades
      a.sidebar__link(@click="setCategory('labels')", :class="getClassName('labels')")
        micon(name="label")
        strong.sidebar__text Etiquetas
      a.sidebar__link(@click="setCategory('commissions')", :class="getClassName('commissions')")
        micon(name="label")
        strong.sidebar__text Comisiones
      a.sidebar__link(@click="setCategory('medical-insurance')", :class="getClassName('medical-insurance')")
        micon(name="label")
        strong.sidebar__text Seguros médicos
        
    .box.box--full.box--with-subnav
      .content
        .data
          InstitutionList(v-if="category==='locations'")
          MedicalSpecialtyList(v-if="category==='medical-specialty'")
          LabelList(v-if="category==='labels'")
          CommissionList(v-if="category==='commissions'")
          MedicalInsuranceList(v-if="category==='medical-insurance'")
              
</template>

<style lang="scss" scoped>
.treatment-tasks {
  .title {
    margin: auto 5px;
  }

  .sidebar {
    box-shadow: -5px 0px 5px 0 rgba(0, 0, 0, 0.1);
    padding-right: 0;
    z-index: 999;
    .sidebar__link {
      &.is-active {
        /* background-color: $primary; */
        background-color: white;
        outline: 0 5px 0 0 solid white;

        .sidebar__text {
          color: $primary;
        }

        svg {
          fill: $primary;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 50px;

    .data {
      width: 100%;
    }
  }

  .vitals-color {
    .micon {
      fill: $vital;
    }
  }
  .checkins-color {
    .micon {
      fill: $checkin;
    }
  }
  .medications-color {
    .micon {
      fill: $medication;
    }
  }
  .appointments-color {
    .micon {
      fill: $appointment;
    }
  }
  .recommendations-color {
    .micon {
      fill: $recommendation;
    }
  }
  .exercises-color {
    .micon {
      fill: $exercise;
    }
  }
  .reports-color {
    .micon {
      fill: $report;
    }
  }

  .box.box--full.box--with-subnav {
    min-height: 82vh;
  }
}
</style>
