<script>
import FileUpload from "@/components/ui/FileUpload.vue";
import fileUploadConfigService from "@/services/fileupload-config";

export default {
  name: "MassivePatientsCreation",

  components: {
    FileUpload,
  },

  data() {
    return {
      hasFiles: false,
      isPosting: false,
    };
  },

  computed: {
    uploadMediaEndpoint() {
      return fileUploadConfigService.getMassiveActionEndpoint(
        "patients/create"
      );
    },
  },

  methods: {
    handleUploadFiles() {
      this.isPosting = true;
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
    },

    uploadFilesSuccess() {
      this.$bus.$emit("go-to-history");
    },

    uploadFilesError() {
      this.isPosting = false;
    },

    downloadCsvFile() {
      const MASSIVE_PATIENT_CREATION_CSV_HEADERS =
        "medicalInsurance;medicalInsuranceNumber;firstName;lastName;governmentIdType;governmentIdNumber;bornAt;gender;email;phone;city;district;neighborhood;companyId;companySector;treatmentName;doctorId;publicId\n";

      const example = [
        [
          "(Id) Id del seguro médico",
          "(String) Número de socio",
          "(String | Obligatorio) Nombre",
          "(String | Obligatorio) Apellido",
          "(String | Obligatorio) Tipo de documento",
          "(String | Obligatorio) Número de documento",
          "(String | Obligatorio) Fecha de nacimiento. Formato mm/dd/yyyy",
          "(String | Obligatorio) Género. Opciones: m | f | x",
          "(String | Obligatorio) Email",
          "(String | Obligatorio) Telefono",
          "(String | Obligatorio) Estado",
          "(String | Obligatorio) Municipio",
          "(String | Obligatorio) Localidad",
          "(Id) Id de empresa",
          "(String) Nombre del sector",
          "(String) Nombre del tratamiento",
          "(Id) Id de doctor",
          "(String) Id proveedor",
        ],
      ];

      let csv = MASSIVE_PATIENT_CREATION_CSV_HEADERS;

      example.forEach((row) => {
        csv += row.join(";");
        csv += "\n";
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = "massive_patients_creation.csv";
      hiddenElement.click();
    },

    getDropdownOptions() {
      return {
        maxFiles: 1,
      };
    },
  },
};
</script>

<template lang="pug">
section
  header.headline
    //- .headline__title
      h1
        | Alta masiva de pacientes
      hr

    .headline__actions
      el-button(
        type="primary",
        @click='downloadCsvFile'
      ) Descargar plantilla

      el-button(
        type="primary",
        :disabled='!hasFiles'
        :loading="isPosting"
        @click="handleUploadFiles"
      ) Asignar

  file-upload(
      type="csv"
      ref="fileUpload",
      :url="uploadMediaEndpoint",
      :dropzone-options="getDropdownOptions()"
      @file-added="onFileAdded",
      @files-removed="onFileRemoved",
      @fileupload-success="uploadFilesSuccess"
      @fileupload-error="uploadFilesError"
    )
</template>

<style lang="scss"></style>
