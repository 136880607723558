<script>
import userService from "@/services/user";

export default {
  name: "OpenEpisode",

  data() {
    return {
      isLoading: false,
      selectedDoctor: "",
      doctors: [],
    };
  },

  props: {
    patientId: {
      type: String,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.getDoctors();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    async getDoctors() {
      this.isLoading = true;
      const { docs } = await userService.getDoctors();
      this.doctors = docs;
      this.isLoading = false;
    },

    async updatePatientList() {
      const patientUpdate = await userService.getById(this.patientId);
      patientUpdate.doctors = [
        this.doctors.find(({ _id }) => this.selectedDoctor === _id),
      ];

      this.$bus.$emit("patient-update", patientUpdate);
    },

    onClick() {
      this.isLoading = true;
      this.$emit("openEpisode", this.selectedDoctor);
      this.updatePatientList();
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-open-episode", :visible.sync="showDialogModal", :before-close="close", title="Abrir Episodio")
    .el-dialog__body
      el-select#doctor-assigned(
        v-model="selectedDoctor"
        placeholder=""
        filterable
        clearable
        default-first-option
      )
        el-option(
          v-for="doctor in doctors"
          :key="doctor._id"
          :label="`${doctor.lastName}, ${doctor.firstName}`"
          :value="doctor._id"
        )

    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="onClick" :disabled="!selectedDoctor") Abrir
</template>
