import Profile from "@/components/profile/Profile.vue";

const profileRoutes = [
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
];

export default profileRoutes;
