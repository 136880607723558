import Vue from "vue";
import Router from "vue-router";
import Main from "@/components/Main.vue";
import Home from "@/components/Home.vue";
import publicRoutes from "./public";
import { verifyToken, trackPage } from "./navigation-guard";
import patientRoutes from "./patient";
import dashboardRoutes from "./dashboard";
import treatmentRoutes from "./treatment";
import userRoutes from "./user";
import profileRoutes from "./profile";
import indicationRoutes from "./indication";
import appointmentRoutes from "./appointment";
import settingsRoutes from "./settings";
import paymentRoutes from "./payment";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "is-active",
  routes: [
    ...publicRoutes,
    {
      path: "/",
      component: Main,
      children: [
        {
          path: "/",
          name: "home",
          component: Home,
        },
        ...dashboardRoutes,
        ...patientRoutes,
        ...treatmentRoutes,
        ...userRoutes,
        ...profileRoutes,
        ...indicationRoutes,
        ...appointmentRoutes,
        ...settingsRoutes,
        ...paymentRoutes,
      ],
    },
  ],
});

router.beforeEach(verifyToken);
router.beforeEach(trackPage);

export default router;
