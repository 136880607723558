import VadiguAppointmentsView from "@/components/appointment/VadiguAppointmentsView";
import AppointmentsView from "@/components/appointment/AppointmentsView";
import AgendasView from "@/components/appointment/AgendasView";
import ScheduleView from "@/components/appointment/ScheduleView";
import AppointmentsRelocationView from "@/components/appointment/AppointmentsRelocationView";
import AppointmentCancel from "@/components/appointment/AppointmentCancel";
import PatientAppointmentsView from "@/components/appointment/PatientAppointmentsView";
// import { theme } from "@/services/config";

const appointmentsEngine = {
  albya: VadiguAppointmentsView,
  default: AppointmentsView,
};

const appointmentRoutes = [
  {
    path: "/appointment",
    name: "appointment",
    // component: appointmentsEngine[theme] || appointmentsEngine.default,
    component: appointmentsEngine.default,
  },
  {
    path: "/appointment/:appointmentId/cancel",
    name: "appointment-cancel",
    component: AppointmentCancel,
  },
  {
    path: "/appointment/agendas",
    name: "appointment-agendas",
    component: AgendasView,
  },
  {
    path: "/appointment/relocation",
    name: "appointment-relocation",
    component: AppointmentsRelocationView,
  },
  {
    path: "/appointment/schedule",
    name: "schedule",
    component: ScheduleView,
  },
  {
    path: "/appointment/patient",
    name: "appointment-patient",
    component: PatientAppointmentsView,
  },
];

export default appointmentRoutes;
