<script>
export default {
  name: "Pagination",

  props: {
    limit: {
      type: Number,
      default: 1,
    },

    total: {
      type: Number,
      default: 0,
    },

    toShow: {
      type: Number,
      default: 5,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
    };
  },

  computed: {
    totalPages() {
      return Math.ceil(this.total / parseInt(this.limit));
    },

    isPreviousDisabled() {
      return this.currentPage === 1;
    },

    isNextDisabled() {
      return this.currentPage === this.totalPages;
    },
  },

  methods: {
    setPage(page) {
      if (this.isLoading) {
        return;
      }

      this.currentPage = page;

      this.$emit("pagination", {
        currentPage: page,
        offset: (page - 1) * parseInt(this.limit),
      });
    },

    showPageNumber(n) {
      const toShow = Math.round(this.toShow / 2);

      return Math.abs(this.currentPage - n) < toShow;
    },
  },
};
</script>

<template lang="pug">
footer.box__footer(v-if="totalPages > 1")
  ul.pagination
    li.pagination__item
      button.pagination__button(v-if="!isPreviousDisabled" @click="setPage(1)")
        micon(name="chevron_left_double").pagination__icon
    li.pagination__item
      button.pagination__button(v-if="!isPreviousDisabled" @click="setPage(currentPage - 1)")
        micon(name="chevron_left").pagination__icon

    li.pagination__number(
      v-for="n in totalPages"
      :class="[currentPage === n ? 'is-active disabled' : '']"
      v-if="showPageNumber(n)"
      @click="setPage(n)") {{ n }}

    li.pagination__item
      button.pagination__button(v-if="!isNextDisabled" @click="setPage(currentPage + 1)")
        micon(name="chevron_right").pagination__icon
    li.pagination__item
      button.pagination__button(v-if="!isNextDisabled" @click="setPage(totalPages)")
        micon(name="chevron_right_double").pagination__icon
</template>
