<script>
import debounce from "debounce";
import Spinner from "@/components/ui/Spinner";
import pagination from "@/mixins/pagination";
import ModalMedicalSpecialty from "@/components/settings/medicalSpecialty/ModalMedicalSpecialty.vue";
import medicalSpecialtyService from "@/services/medical-specialty";
import helperService from "@/services/helper";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "MedicalSpecialtyList",

  mixins: [pagination],

  components: {
    Spinner,
    ModalMedicalSpecialty,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      medicalSpecialties: [],
      isMedicalSpecialtyModalVisible: false,
      selectedMedicalSpecialty: {},
    };
  },

  created() {
    this.getMedicalSpecialties();

    this.$bus.$on("medical-specialty-created", (medicalSpecialty) => {
      this.medicalSpecialties.unshift(medicalSpecialty);
    });
  },

  methods: {
    async getMedicalSpecialties(query = {}) {
      this.isLoading = true;

      const defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      medicalSpecialtyService
        .getMedicalSpecialties(defaultQuery)
        .then((res) => {
          this.medicalSpecialties = res;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceInput: debounce(function () {
      this.getMedicalSpecialties(
        helperService.buildQuery({
          searchMedicalSpecialty: this.searchInput,
          queryProperties: ["name"],
        })
      );
    }, DEBOUNCE_MS_TIMEOUT),

    showMedicalSpecialtyModal(medicalSpecialty) {
      if (medicalSpecialty) {
        this.selectedMedicalSpecialty = medicalSpecialty;
      }
      this.isMedicalSpecialtyModalVisible = true;
    },

    closeMedicalSpecialtyModal() {
      this.isMedicalSpecialtyModalVisible = false;
      this.selectedMedicalSpecialty = null;
    },

    deleteMedicalSpecialty(medicalSpecialty) {
      this.$confirm(
        `¿Desea eliminar la especialidad: ${medicalSpecialty.name}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          medicalSpecialtyService.deleteInstitution(medicalSpecialty._id);

          this.medicalSpecialties = this.medicalSpecialties.filter(
            (m) => m._id !== medicalSpecialty._id
          );
        })
        .catch(() => {});
    },

    onPageChange() {
      this.getMedicalSpecialties();
    },
  },
};
</script>

<template lang="pug">
  section.institution-list
    header.headline
      //- .headline__title
        h1
          | Especialidad Médicas
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input.search__input(
          prefix-icon="el-icon-search"
          @input="debounceInput"
          v-model="searchInput"
          placeholder="Buscar"
          :clearable="true"
        )
        .new_medical-specialty.button.button--blue.box__icon(@click='showMedicalSpecialtyModal()' v-auth="['doctor.create', 'manager.create', 'assistan.create', 'admin.create']")
          micon(name="add_circle_outline").button__icon
          span.button__text Nueva especialidad
    .box
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Abreviación
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(specialty, index) in medicalSpecialties" :key="specialty._id" @click='showMedicalSpecialtyModal(specialty)')
              td {{ specialty.name }}
              td {{ specialty.abbreviation }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                          div(@click="showMedicalSpecialtyModal(specialty)")
                            micon(name="edit")
                            | Editar especialidad
                      //- el-dropdown-item(v-if="!specialty.lockedAt")
                      //-     div(@click="deleteMedicalSpecialty(specialty, index)")
                      //-       micon(name="lock")
                      //-       | Eliminar especialidad

      ModalMedicalSpecialty(
        v-if='isMedicalSpecialtyModalVisible'
        :current-medical-specialty='selectedMedicalSpecialty'
        :show-dialog-modal='isMedicalSpecialtyModalVisible' 
        @close='closeMedicalSpecialtyModal'
      )

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )

</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.box {
  height: 100%;
}

.institution-list {
  height: 65vh;
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }
    }
  }
}
</style>
