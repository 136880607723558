import api from "./api";

const inviteService = {};

inviteService.verificateWithToken = function (token) {
  return api.post(`users/verify/${token}`).then((res) => res.data);
};

inviteService.sendInvite = function (email) {
  return api.put(`users/${email}/invite`).then((res) => res.data);
};

inviteService.setUserPasswordWithToken = function (token, password) {
  return api
    .put(`users/password/${token}`, { password })
    .then((res) => res.data);
};

export default inviteService;
