<script>
import format from "date-fns/format";
import { TASKS_TYPES } from "@/services/constants";
import { parseISO, formatDistanceStrict } from "date-fns";
import dateFnsEsp from "date-fns/locale/es";

export default {
  name: "VitalBox",

  props: {
    name: String,
    vitals: Array,
    patient: Object,
  },

  data() {
    return {
      TASKS_TYPES,
    };
  },

  computed: {
    completedVitals() {
      return this.vitals.filter(({ completedAt }) => completedAt);
    },
    lastAlert() {
      return this.completedVitals[0];
    },
  },

  methods: {
    getAnswers(alert) {
      return `${alert.name}: ${alert.value}`;
    },

    getIMC(weight, height) {
      return (weight / (height / 100) ** 2).toFixed(1);
    },

    getTooltip(vital) {
      const createdBy = vital.createdBy;
      const patient = vital.patient;
      const isManual = createdBy && createdBy !== patient;
      const completedAt = format(
        new Date(vital.completedAt),
        "dd/MM/yyyy HH:mm"
      );
      let tooltip = `<b>Fecha:</b> ${completedAt}`;

      vital.answerValues.forEach((answer) => {
        tooltip += `<br> <b>${answer.name}:</b> ${answer.value}`;
        if (answer.name === "Peso" && this.patient.patient.health.height) {
          tooltip += `<br> <b>IMC:</b> ${this.getIMC(
            answer.value,
            this.patient.patient.health.height
          )}`;
        }
      });
      return `${tooltip} ${isManual ? "<br> Carga Manual" : ""}`;
    },

    getDateDistance(vital) {
      return vital.completedAt
        ? formatDistanceStrict(new Date(), parseISO(vital.completedAt), {
            addSuffix: false,
            locale: dateFnsEsp,
          })
            .replace("segundos", "s")
            .replace("segundo", "s")
            .replace("minutos", "m")
            .replace("minuto", "m")
            .replace("horas", "h")
            .replace("hora", "h")
            .replace("días", "D")
            .replace("día", "D")
            .replace("meses", "M")
            .replace("mes", "M")
        : "";
    },
  },
};
</script>

<template lang="pug">
  .box.vital-box
    .header
      h1 {{ name | capitalize }}
      .buttons
        router-link(:to="{ name: 'patient-tasks', query: { taskType: 'vitals' } }")
          micon(name="calendar", v-tooltip="'Ver tareas'")
        a(@click="$emit('show-modal', { modal: 'task-graph', task: name })")
          micon(name="chart", v-tooltip="'Ver grafico'")
        a(@click="$emit('show-modal', { modal: 'tasks-table', task: name, type: 'vitals' })" v-if='name !== "Electrocardiograma"')
          micon(name="list", v-tooltip="'Ver tabla de tareas'")
        a(@click="$emit('show-modal', { modal: 'ecg-table', task: lastAlert || vitals[0], type: 'vitals' })" v-else)
          micon(name="list", v-tooltip="'Ver electrocardiograma'")
        a(@click="$emit('show-modal', { modal: 'add-manual-task', task: lastAlert || vitals[0] })" v-if='name !== "Electrocardiograma"')
          micon.list-add(name="playlist_add", v-tooltip="'Carga manual'")
    .content(v-if="lastAlert")
      .top.box
        .info(v-for="(vital, index) in completedVitals", v-if="index < 8")
          .block(
            :class="vital.completedMeasurement",
            v-tooltip="{content: getTooltip(vital)}"
          )
            | {{ getDateDistance(vital) }}
      .bottom.box
        .response
          | Último registro:
          .answer(v-for="alert in lastAlert.answerValues" :key="alert.name") 
            | {{ getAnswers(alert) }}
            span(v-if="lastAlert.name === 'Peso' && patient.patient.health.height") IMC: {{getIMC(lastAlert.answerValues[0].value, patient.patient.health.height)}}
          
        .date(v-if="lastAlert.completedAt") Hace {{ lastAlert.completedAt | formatDistanceStrict }}

    .content(v-else)
      .no-data El paciente todavía no ha cargado datos
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

.vital-box {
  .content {
    height: 100%;
    @include media(mobile-up) {
      display: flex;
      flex-direction: column;
    }

    .top,
    .bottom {
      display: flex;
      gap: 5px;
      justify-content: center !important;
      align-items: center;
      padding: 15px;

      @include media(mobile-up) {
        height: 360px;
      }
    }

    .bottom {
      @include media(mobile-up) {
        flex: 1;
        flex-direction: column !important;
        margin-bottom: 0;
      }

      .response {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .micon {
        height: 35px;
        width: 35px;
      }

      .answer {
        color: $primary;
        font-size: 15px;
        text-align: center;
      }

      .divider {
        background: $gainsboro;
        margin: 15px 0;
      }

      .date {
        margin-top: 5px;
        color: $cuttysark;
        font-size: 15px;
      }
    }

    .top {
      margin-bottom: 0;
      @include media(mobile-up) {
        flex: 1;
        flex-direction: row-reverse;
        justify-content: flex-start;
      }

      table {
        width: initial;
        border-collapse: separate;
        border-spacing: 2px;
        td {
          padding: 8px 15px;
          height: auto;
          border: none;
        }
      }
      .label {
        font-size: 17px;
        font-weight: normal;
        color: $cuttysark;
      }
      .block {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;

        color: white;
      }
    }
  }
}
</style>
