import DashboardPatientsEvaluation from "@/components/dashboard/DashboardPatientsEvaluation.vue";
import DashboardConversation from "@/components/dashboard/DashboardConversation.vue";
import DashboardConversations from "@/components/dashboard/DashboardConversations.vue";
import DashboardUnrepliedConversations from "@/components/dashboard/DashboardUnrepliedConversations.vue";

const dashboardRoutes = [
  {
    path: "/dashboard/assistant-evaluation",
    name: "assistant-evaluation",
    component: DashboardPatientsEvaluation,
  },
  {
    path: "/dashboard/unreplied",
    name: "dashboard-conversations-unreplied",
    component: DashboardUnrepliedConversations,
  },
  {
    path: "/dashboard/conversations",
    name: "dashboard-conversations",
    component: DashboardConversations,
    children: [
      {
        path: "/dashboard/conversation/:id",
        name: "dashboard-conversation",
        component: DashboardConversation,
      },
    ],
  },
];

export default dashboardRoutes;
