import api from "./api";

const userService = {};

userService.sendPasswordResetByEmail = function (email) {
  return api.put(`users/${email}/password-reset/`).then((res) => res.data);
};

userService.setUserPasswordWithToken = function (token, password) {
  return api
    .put(`users/password/${token}`, { password })
    .then((res) => res.data);
};

export default userService;
