<script>
import EcgChart from "@/components/patient/dashboard/ecg/ecgChart";

import AlertBadge from "@/components/ui/AlertBadge";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "ModalPatientEcgTable",

  components: {
    EcgChart,
    AlertBadge,
    Spinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  props: {
    selectedTask: {
      type: Object,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
  el-dialog.modal-container(name="modal-patient-ecg-table", :visible.sync="showDialogModal", :before-close="close", width="70%")
    ecg-chart(:data="selectedTask.ecgData.points" :height="200")
    .data(v-if="selectedTask.ecgData.values")
      .value(v-for="value in Object.entries(selectedTask.ecgData.values)")
        .h1 {{value[0].toUpperCase()}} : {{value[1]}}
    .footer
      fieldset(style="margin-left: auto")
        button.button.button--blue(@click="close") Salir
</template>

<style lang="scss" scoped>
.disabled {
  cursor: none;
  pointer-events: none;
}
.head__actions {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
}
.head__search > * {
  margin: 0 0 0 15px;
}
.head__search {
  display: flex;
  align-items: center;
  word-break: normal;
}
.box {
  margin: 10px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.box__footer {
  position: inherit;
  width: 100%;
}
.data {
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: auto auto;
}
.value {
  margin: 5px;
}
</style>
