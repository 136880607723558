<script>
import Spinner from "@/components/ui/Spinner";
import ModalIndication from "@/components/patient/dashboard/ModalIndication.vue";
import configService from "@/services/config";
import { INDICATIONS_TYPES } from "@/services/constants";
import { mapGetters } from "vuex";
import indicationService from "@/services/indication";
import helperService from "@/services/helper";

export default {
  name: "IndicationBox",

  props: {
    patient: {
      type: Object,
      required: true,
    },
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    Spinner,
    ModalIndication,
  },

  data() {
    return {
      INDICATIONS_TYPES,
      isLoading: false,
      indications: [],
      s3Url: configService.s3Url,
      isIndicationModalVisible: false,
      selectedIndication: null,
      isContentHidden: this.isCollapsable,
      filterModalShowed: false,
      indicationsFilter: [],
    };
  },

  created() {
    this.indicationsFilter = INDICATIONS_TYPES.map((indicationType) => ({
      name: indicationType.name,
      type: indicationType.type,
      checked: true,
    })).concat({ name: "orderByDate", type: "orderByDate", checked: true });

    this.$bus.$on("indication-created", (indication) => {
      this.indications.unshift(indication);
    });
  },

  computed: {
    ...mapGetters(["user", "isPatient"]),

    selectedIndicationFiles() {
      const { files = [] } = this.selectedIndication;
      const sorted = files.sort((a, b) => {
        a.mimeType < b.mimeType;
      });
      return sorted;
    },
  },

  watch: {
    indicationsFilter: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },

  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const indicationTypesFilter = this.indicationsFilter.filter(
          (item) => item.checked
        );

        const indications = await indicationService.get(
          this.patient._id,
          indicationTypesFilter
        );

        this.indications = indications;
      } finally {
        this.isLoading = false;
      }
    },

    getFullName(patient) {
      return helperService.getFullName(patient);
    },

    getIndicationName(indication) {
      const indicationType = INDICATIONS_TYPES.filter(
        (indicationType) => indicationType.type === indication.type
      );
      return indicationType[0].name;
    },

    selectAll() {
      this.indicationsFilter.forEach((type) => (type.checked = true));
    },

    toggleIndication(indicationType) {
      this.indicationsFilter.forEach((indication) => {
        if (indication.type === indicationType) {
          indication.checked = !indication.checked;
        }
      });
    },

    isSelectedIndication(indicationType) {
      return this.indicationsFilter.filter(
        (indication) => indication.type === indicationType
      )[0].checked;
    },

    isAllSelected() {
      return this.indicationsFilter.every((indication) => indication.checked);
    },

    closeIndicationModal() {
      this.isIndicationModalVisible = false;
      this.selectedIndication = null;
    },

    showIndicationModal(indication) {
      if (indication) {
        this.selectedIndication = indication;
        this.selectedIndication.name = this.getIndicationName(indication);
      } else {
        this.selectedIndication = {
          patient: this.patient,
          modalType: "create-indication",
        };
      }
      this.isIndicationModalVisible = true;
    },

    openModalFilter() {
      this.filterModalShowed = true;
    },

    closeFilterModal() {
      this.filterModalShowed = false;
    },

    getFileName(key = "") {
      return key.split("/").pop();
    },

    toggleHideContent() {
      this.isContentHidden = !this.isContentHidden;
    },
  },
};
</script>

<template lang="pug">
  .box.alert-box
    .header
      .expand(v-if="isCollapsable" @click='toggleHideContent()' :class="isContentHidden ? 'rotate' : ''")
          micon(name="expand_more")
      h1 Indicaciones
      .buttons
        .new_indication.box__icon(v-if="!isPatient" @click='showIndicationModal()')
          micon(v-show="!isContentHidden" name="add" v-tooltip="'Agregar indicacion'")
        .filter.box__icon(@click="openModalFilter()")
          micon(v-show="!isContentHidden" name="filter" v-tooltip="'Filtrar'" )
    .content(:class="(isCollapsable && isContentHidden) ? 'hide-content' : '' ")
      ModalIndication(
        v-if='isIndicationModalVisible'
        :current-indication='selectedIndication'
        :show-dialog-modal='isIndicationModalVisible' 
        :isModal="!isCollapsable"
        @close='closeIndicationModal'
      )

      span(v-else)
        .no-data(v-if="!isLoading && !indications.length") No hay indicaciones

        spinner(v-if="isLoading")

        .box.clickable(
          v-else 
          v-for="indication in indications", 
          :key="indication._id",
          @click='showIndicationModal(indication)'
        )
          h4.underline {{getIndicationName(indication)}}
            micon(
              v-if="indication.files && indication.files.length",
              name="attach_file",
              height="20px",
              width="20px",
              v-tooltip="'Archivos Adjuntos'"
            )
          h4.date {{ indication.createdAt | formatDateAndTime }}
          h4 Creado por {{ getFullName(indication.doctor) }}
      
      el-dialog.filter-dialog(
          title='Filtro'
          custom-class="event-detail-modal",
          :visible.sync="filterModalShowed"
          :before-close="closeFilterModal"
        )
          .filters
              .order-by 
                h2 Ordenar por
                .description 
                  el-radio(v-model="indicationsFilter[9].checked" :label='true') Fecha
                .description 
                  el-radio(v-model="indicationsFilter[9].checked" :label='false') Categoría
              
              h2 Filtrar por
              .indication-options.tags
                el-tag.indication-option(key='all' :class="isAllSelected() ? 'selected' : '' " @click="selectAll()" effect="plain"
                    size="medium") Todos
                el-tag.indication-option(
                    v-for="indication in indicationsFilter"
                    v-if="indication.type != 'orderByDate'"
                    :key="indication.name"
                    :class="isSelectedIndication(indication.type)? 'selected': ''"
                    @click="toggleIndication(indication.type)",
                    @close="toggleIndication(indication.type)",
                    effect="plain"
                    size="medium"
                  ) {{ indication.name }}

          span(slot="footer")
            button.button.button--blue(@click="closeFilterModal()") Salir
</template>

<style lang="scss">
.form-box {
  .el-timeline-item {
    &:last-child {
      padding-bottom: 0;
    }
    &__timestamp {
      margin: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
.alert-box {
  .header {
    position: sticky;
    top: 0;
    .buttons {
      display: flex;
    }
  }

  .expand {
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }

  .content {
    max-height: 400px;
    visibility: visible;
    transition: all 0.4s ease-in-out;

    &.hide-content {
      visibility: hidden;
      overflow: hidden;
      max-height: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition: all 0.4s ease-in-out;
    }

    .box {
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      .micon {
        margin-left: 5px;
      }
    }
  }
}

.selectAll {
  margin: 15px 0 10px;
  cursor: pointer;
}

.indications-options {
  display: flex;
}

.indication-option {
  cursor: pointer;
  border-color: $cuttysark;
  color: $cuttysark;
  padding: 0px 7px;
  transition: opacity 250ms;

  &:hover {
    opacity: 0.9;
  }
}

.selected {
  border-color: $primary;
  color: $primary;
  background: $primary;
  color: #ffffffff;

  &::after {
    content: "╳";
    font-size: 8px;
    margin-left: 6px;
    color: #ffffffff;
    font-weight: bold;
  }
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .order-by {
    margin-bottom: 25px;
  }

  .el-dialog__body {
    padding-top: 0;
  }

  .form-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding-right: 120px;

    .checkbox {
      margin-left: 15px;
    }

    .description {
      word-break: break-word;
      margin-bottom: 5px;
      font-size: 16px;
    }
  }
}
</style>
