import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import * as Rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import { isToday } from "date-fns";
import { isFuture } from "date-fns";
import { isBefore } from "date-fns";
import Vue from "vue";

localize("es", es);

localize({
  es: {
    messages: {
      integer: "El campo {_field_} debe ser un número.",
    },
  },
});

extend("pastToday", {
  message: `La {_field_} tiene que ser después del dia de hoy.`,
  validate: (value) => {
    return isFuture(value);
  },
});

extend("includeToday", {
  message: `La {_field_} tiene que ser mayor o igual a el dia de hoy.`,
  validate: (value) => {
    return isToday(value) || isFuture(value);
  },
});

extend("priorToday", {
  message: `La {_field_} tiene que ser antes del dia de hoy.`,
  validate: (value) => {
    const actualDate = new Date();
    return isBefore(new Date(value), actualDate);
  },
});

extend("includes_uppercase", {
  message: `La {_field_} debe contener al menos una mayúscula`,
  validate: (value) => {
    const regexValidation = new RegExp("^(?=.*[A-Z])");
    return regexValidation.test(value);
  },
});

extend("special_characters", {
  message: `La {_field_} debe contener al menos un carácter especial`,
  validate: (value) => {
    const regexValidation = new RegExp("^(?=.*[$@¡!=%*¿?&_-])");
    return regexValidation.test(value);
  },
});

extend("alpha_number", {
  message: `La {_field_} debe contener al menos un número y un carácter`,
  validate: (value) => {
    const regexValidation = new RegExp("^(?=.*[a-z])(?=.*[0-9])");
    return regexValidation.test(value);
  },
});

extend("sale_price", {
  message: "El {_field_} debe ser igual o mayor al precio sugerido",
  params: ["suggested_price"],
  validate: (value, { suggested_price }) => {
    if (Number(value) >= suggested_price) return true;
    else return false;
  },
});

extend("greaterThan", {
  params: ["target"],
  validate(value, { target }) {
    return value > target;
  },
  message: "El {_field_} debe ser mayor a {target}",
});

//This will be removed on vee-validate v4
extend("url", {
  message: `La {_field_} debe ser una url valida`,
  validate: (value) => {
    const regexValidation = new RegExp(
      "(http(s)?://.)?(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)"
    );
    return regexValidation.test(value);
  },
});

extend("no_numeric", {
  message: "El campo no debe ser numérico",
  validate: (value) => {
    return isNaN(Number(value));
  },
});

Object.keys(Rules).forEach((rule) => {
  extend(rule, Rules[rule]);
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
