import api from "./api";

const commissionService = {};
const commissionEndpoint = "/commissions";

commissionService.createCommission = function (commission) {
  return api.post(commissionEndpoint, commission).then(({ data }) => data);
};

commissionService.updateCommission = function (commission) {
  return api
    .put(`${commissionEndpoint}/${commission._id}`, commission)
    .then(({ data }) => data);
};

commissionService.getCommissions = function (queryParams) {
  return api
    .get(commissionEndpoint, { params: queryParams })
    .then((res) => res.data);
};

export default commissionService;
