<script>
import FileUpload from "@/components/ui/FileUpload.vue";
import fileUploadConfigService from "@/services/fileupload-config";

export default {
  name: "MassiveRecommendationsCreation",

  components: {
    FileUpload,
  },

  data() {
    return {
      hasFiles: false,
      isPosting: false,
    };
  },

  computed: {
    uploadMediaEndpoint() {
      return fileUploadConfigService.getMassiveActionEndpoint(
        "recommendations/create"
      );
    },
  },

  methods: {
    handleUploadFiles() {
      this.isPosting = true;
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
    },

    uploadFilesSuccess() {
      this.$bus.$emit("go-to-history");
    },

    uploadFilesError() {
      this.isPosting = false;
    },

    downloadCsvFile() {
      const MASSIVE_PATIENT_CREATION_CSV_HEADERS =
        "name;description;tags;category;linksName;linskUrl;mediaType;mediaValue;measurementGreen;measurementYellow;measurementRed;shouldAlert;isFeatured\n";

      const example = [
        [
          "(String | Obligatorio) Nombre de la recomendación ",
          "(String) Descripción de la recomendación",
          "(String) Etiquetas. Permite multiples campos separados con |. Ej Tag1|Tag2",
          "(String | Obligatorio) Categorias. Opciones: category1 | category2 | category3 | category4",
          "(String) Nombre del link. Permite multiples campos separados con |. Ej Nombre1|Nombre2",
          "(String) URL de link. Permite multiples campos separados con |. Ej http://www.google.com|http://www.gmail.com",
          "(String) Tipo de archivo adjunto. Opciones: image | audio | video | text",
          "(String) En el caso de image o audio, nombre del archivo adjunto con su extensión. Ej image1.jpg. En el caso de text, texto de la recomendación, En el caso de video, URL del video. Ej http://www.google.com",
          "(Number) Rango correcto de valor en horas",
          "(Number) Rango tolerable de valor en horas",
          "(Number) Rango anormal de valor en horas",
          "(String) Mostrar alertas en Dashboard. Opciones yes | no",
          "(String) Mostrar tarea como destacada. Opciones yes | no",
        ],
      ];

      let csv = MASSIVE_PATIENT_CREATION_CSV_HEADERS;

      example.forEach((row) => {
        csv += row.join(";");
        csv += "\n";
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
      hiddenElement.target = "_blank";

      //provide the name for the CSV file to be downloaded
      hiddenElement.download = "massive_recommendations_creation.csv";
      hiddenElement.click();
    },

    getDropdownOptions() {
      return {
        uploadMultiple: true,
        maxFiles: 1000,
        parallelUploads: 1000,
      };
    },
  },
};
</script>

<template lang="pug">
  section
    header.headline
      //- .headline__title
        h1
          | Alta masiva de recomendaciones
        hr

      .headline__actions
        el-button(
          type="primary",
          @click='downloadCsvFile'
        ) Descargar plantilla
        
        el-button(
          type="primary",
          :disabled='!hasFiles'
          :loading="isPosting"
          @click="handleUploadFiles"
        ) Asignar

    file-upload(
      type="csvAndMedia"
      ref="fileUpload",
      :url="uploadMediaEndpoint",
      :dropzone-options="getDropdownOptions()"
      @file-added="onFileAdded",
      @files-removed="onFileRemoved",
      @fileupload-success="uploadFilesSuccess"
      @fileupload-error="uploadFilesError"
    )
</template>

<style lang="scss"></style>
