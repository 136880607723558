<script>
import diagnosisService from "@/services/diagnosis";
import debounce from "debounce";

export default {
  name: "DiagnosisSelect",
  props: {
    modelValue: { type: String, required: true },
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      diagnosis: "",
      diagnoses: [],
      diagnosesFilterValue: "",
      isLoading: false,
    };
  },

  created() {
    this.getDiagnoses({ featured: true, filter: this.diagnosesFilterValue });
  },

  computed: {
    findDiagnosisById: function () {
      const diagnosis = this.diagnoses.find(
        (elem) => elem._id === this.modelValue
      );
      if (diagnosis) {
        return diagnosis.description;
      } else {
        return "Sin datos";
      }
    },
  },

  watch: {
    diagnosesFilterValue(newFilter) {
      if (!newFilter.length) {
        this.debounceSelect({
          featured: true,
          filter: this.diagnosesFilterValue,
        });
      } else {
        this.debounceSelect({
          featured: false,
          filter: this.diagnosesFilterValue,
        });
      }
    },
  },

  methods: {
    async getDiagnoses(diagnosesFilter) {
      this.isLoading = true;
      this.diagnoses = await diagnosisService.getDiagnoses(diagnosesFilter);
      this.isLoading = false;
    },

    filterDiagnoses(filterValue) {
      this.diagnosesFilterValue = filterValue;
    },

    debounceSelect: debounce(function (diagnosesFilter) {
      this.getDiagnoses(diagnosesFilter, diagnosesFilter);
    }, 300),

    onInput(value) {
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<template lang="pug">

el-select(v-if="!readonly" :value="modelValue" @input="onInput" filterable :filter-method='filterDiagnoses' :loading='isLoading')
  el-option(
    v-for="item in diagnoses"
    :key="item._id"
    :label="item.description"
    :value="item._id"
    )
      span(style="float: left") {{ item.description }}
      span(v-if='item.featured' style="float: right; color: #8492a6; font-size: 13px") ★ 

el-input(v-else :value="findDiagnosisById" readonly)

</template>

<style></style>
