<script>
import AlertBadge from "@/components/ui/AlertBadge";
import { TASKS_TYPES } from "@/services/constants";
import { orderBy } from "lodash";

export default {
  name: "ActivityAlertBox",

  components: {
    AlertBadge,
  },

  data() {
    return {
      filteredAlerts: [],
      typeFilters: [],
      alertFilters: [
        { name: "Rojas", type: "red", checked: true },
        { name: "Amarillas", type: "yellow", checked: true },
        { name: "Verde", type: "green", checked: false },
      ],
      orderBy: "desc",
      filterModalShowed: false,
    };
  },

  created() {
    this.typeFilters = Object.values(TASKS_TYPES).map((type) => ({
      name: type.name,
      type: type.type,
      checked: true,
    }));
    this.filterData();
  },

  props: {
    alerts: { type: Array, required: true },
  },

  watch: {
    orderBy() {
      this.filterData();
    },
  },

  methods: {
    openModalFilter() {
      this.filterModalShowed = true;
    },

    closeFilterModal() {
      this.filterModalShowed = false;
    },

    showDetail(alert) {
      const elem =
        alert.type === "vitals"
          ? this.$parent.$refs[alert.name][0].$el
          : this.$parent.$refs[alert.type][0].$el;
      elem.scrollIntoView({ behavior: "smooth" });
    },

    isAllSelected(array) {
      return array.every((alert) => !!alert.checked);
    },

    toggleAll(array) {
      if (this.isAllSelected(array)) {
        array.forEach((elem) => (elem.checked = false));
      } else {
        array.forEach((elem) => (elem.checked = true));
      }
      this.filterData();
    },

    toggleProp(prop, value) {
      console.log("entra aca");
      prop.checked = value ? value : !prop.checked;
      this.filterData();
    },

    filterData() {
      this.filteredAlerts = orderBy(
        this.alerts
          .filter((alert) => {
            return this.alertFilters
              .filter((filter) => !!filter.checked)
              .some((filter) => filter.type === alert.completedMeasurement);
          })
          .filter((alert) => {
            return this.typeFilters
              .filter((filter) => !!filter.checked)
              .some((filter) => filter.type === alert.type);
          }),
        "completedAt",
        this.orderBy
      );
    },
  },
};
</script>

<template lang="pug">
  .box.alert-box(:class="")
    .header
      h1 Alertas sin verificar
      .buttons
        a(v-if="filteredAlerts.length" @click="openModalFilter()")
          micon(name="filter", v-tooltip="'Filtrar'")

    .content
      .no-data(v-if="!filteredAlerts.length") No hay alertas por verificar

      .box.clickable(
        v-for="(alert, index) in filteredAlerts",
        :key="alert._id"
        @click="showDetail(alert)"
      )
        .data
          h4 {{ alert.name }}
            micon(
                v-if="alert.files && alert.files.length",
                name="attach_file",
                height="20px",
                width="20px",
                v-tooltip="'Archivos Adjuntos'"
              )
          h4.date(v-if="alert.completedAt || alert.updatedAt")
            | Hace {{ alert.completedAt || alert.updatedAt | formatDistanceStrict }}
        .alert-type
          alert-badge(:alert="alert")
    
    el-dialog.filter-dialog(
      title='Filtros'
      custom-class="event-detail-modal",
      :visible.sync="filterModalShowed"
      :before-close="closeFilterModal"
    )
      .filters
        .order-by 
          h2 Ordenar fecha
          .description 
            el-radio(v-model="orderBy" :label='"asc"') Ascendente
          .description 
            el-radio(v-model="orderBy" :label='"desc"') Descendente
        
        .alert-filter
          h2 Filtrar por
          h4 Tipo de alerta
          .tags
            el-tag.filter-label(key='all' :class="isAllSelected(typeFilters) ? 'selected' : '' " @click="toggleAll(typeFilters)" effect="plain"
                size="medium") Todos
            el-tag.filter-label(
                v-for="typeFilter in typeFilters"
                v-if="typeFilter.type != 'orderByDate'"
                :key="typeFilter.name"
                :class="!!typeFilter.checked ? 'selected': ''"
                @click="toggleProp(typeFilter)",
                @close="toggleProp(typeFilter, false)",
                effect="plain"
                size="medium"
              ) {{ typeFilter.name }}

        .alert-filter
          h2 Filtrar por
          h4 Nivel de alerta
          .tags
            el-tag.filter-label(key='all' :class="isAllSelected(alertFilters) ? 'selected' : '' " @click="toggleAll(alertFilters)" effect="plain"
                size="medium") Todos
            el-tag.filter-label(
                v-for="alertFilter in alertFilters"
                v-if="alertFilter.type != 'orderByDate'"
                :key="alertFilter.name"
                :class="!!alertFilter.checked ? 'selected': ''"
                @click="toggleProp(alertFilter)",
                @close="toggleProp(alertFilter, false)",
                effect="plain"
                size="medium"
              ) {{ alertFilter.name }}

      span(slot="footer")
        button.button.button--blue(@click="closeFilterModal()") Salir
</template>

<style lang="scss" scoped>
.clickable {
  display: grid !important;
  grid-auto-flow: column;
  justify-content: space-between !important;
}

.filter-label {
  cursor: pointer;
}

.selected {
  border-color: $primary;
  color: $primary;
  background: $primary;
  color: #ffffffff;

  &::after {
    content: "╳";
    font-size: 8px;
    margin-left: 6px;
    color: #ffffffff;
    font-weight: bold;
  }
}

.filters {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 20px;
}
</style>
