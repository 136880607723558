<script>
import { enableChat, theme, isMassiveEnabled } from "@/services/config";
import { mapGetters } from "vuex";

export default {
  name: "SubNav",

  props: {
    nav: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      enableChat,
      theme,
      isMassiveEnabled,
      updateTitle: undefined,
    };
  },

  created() {
    this.$bus.$on("update-title", (title) => {
      this.updateTitle = title;
    });
  },

  watch: {
    "$route.name"() {
      this.updateTitle = undefined;
    },
  },

  methods: {
    isRoute(name) {
      return this.$route.name === name;
    },

    getSectionTitle() {
      switch (this.$route.name) {
        case "home":
          return "Seguimientos";

        case "assistant-evaluation":
          return "Requiere nuevo servicio";

        case "dashboard-conversations":
          return "Conversaciones";

        case "dashboard-conversations-unreplied":
          return "Auditoría Chat";

        case "patient":
          return "Pacientes";

        case "treatment":
          return "Protocolos";

        case "treatment-medication":
          return "Medicaciones";

        case "treatment-appointment":
          return "Recordatorios";

        case "treatment-recommendation":
          return "Recomendaciones";

        case "treatment-exercise":
          return "Ejercicios";

        case "update-vital":
          return "Registros";

        case "treatment-report":
          return "Reportes";

        case "treatment-checkin":
          return "Check-in";

        case "appointment":
          return "Citas";

        case "appointment-agendas":
          return "Agendas";

        case "appointment-relocation":
          return "Citas a reprogramar";

        case "settings":
          return "Configuración";

        case "users-list":
          return "Usuarios";

        case "profile":
          return "Perfil";

        case "schedule":
          return "Mi Día";

        case "patient-dashboard":
          return undefined;

        case "settings-massive":
          return "Carga Masiva";

        default:
          return "";
      }
    },
  },

  computed: {
    ...mapGetters(["isAdmin", "isAssistant", "isDoctor", "isPatient"]),

    isTreatmentSubNavActive() {
      return (
        this.$route.name === "treatment" ||
        this.$route.name === "treatment-tasks"
      );
    },

    isLabelSubNavActive() {
      return this.$route.name === "labels";
    },

    isConfigSubNavActive() {
      return (
        this.$route.name !== "treatment-tasks" &&
        (this.$route.name.includes("treatment-") ||
          this.$route.name === "create-vital" ||
          this.$route.name === "update-vital")
      );
    },
  },
};
</script>

<template lang="pug">
    .subnav
      .headline__title
        h1 {{ getSectionTitle() || updateTitle }}

      nav.subnav__list(v-if="nav === 'dashboard' && !isPatient")
        router-link.subnav__item(:to="{ name: 'home' }") Todos
        router-link.subnav__item(v-if="isAdmin || isAssistant" :to="{ name: 'assistant-evaluation' }") Derivaciones Asesores
        router-link.subnav__item(v-if="isDoctor && enableChat" :to="{ name: 'dashboard-conversations' }") Conversaciones
        router-link.subnav__item(v-if="(isAdmin || isAssistant) && enableChat" :to="{ name: 'dashboard-conversations-unreplied' }") Auditoría Chat
      
      nav.subnav__list(v-else-if="nav === 'treatment'")
        router-link.subnav__item(:to="{ name: 'treatment' }", :class="{ 'router-link-exact-active': isTreatmentSubNavActive }", exact) Protocolos
        el-dropdown(trigger="click")
          a.el-dropdown-link.subnav__item(:to="{ name: 'treatment-medication' }", :class="{ 'router-link-exact-active': isConfigSubNavActive }", exact)
            | Configuración
            i.el-icon-arrow-down.el-icon--right
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item(:disabled="isRoute('treatment-medication')" :class="{'active': isRoute('treatment-medication')}")
              router-link(:to="{ name: 'treatment-medication' }") Medicaciones
            el-dropdown-item(:disabled="isRoute('treatment-appointment')" :class="{'active': isRoute('treatment-appointment')}")
              router-link(:to="{ name: 'treatment-appointment' }") Recordatorios
            el-dropdown-item(:disabled="isRoute('treatment-recommendation')" :class="{'active': isRoute('treatment-recommendation')}")
              router-link(:to="{ name: 'treatment-recommendation' }") Recomendaciones
            el-dropdown-item(:disabled="isRoute('treatment-exercise')" :class="{'active': isRoute('treatment-exercise')}")
              router-link(:to="{ name: 'treatment-exercise' }") Ejercicios
            el-dropdown-item(:disabled="isRoute('treatment-vital')" :class="{'active': isRoute('treatment-vital') || isRoute('update-vital')}")
              router-link(:to="{ name: 'treatment-vital' }") Registros
            el-dropdown-item(:disabled="isRoute('treatment-report')" :class="{'active': isRoute('treatment-report')}")
              router-link(:to="{ name: 'treatment-report' }") Reportes
            el-dropdown-item(:disabled="isRoute('treatment-checkin')" :class="{'active': isRoute('treatment-checkin')}")
              router-link(:to="{ name: 'treatment-checkin' }") Check-in
            //- router-link.subnav__item(:to="{ name: 'label' }", :class="{ 'router-link-exact-active': isLabelSubNavActive }", exact) Etiquetas 

      nav.subnav__list(v-else-if="nav === 'settings'")
        router-link.subnav__item(:to="{ name: 'settings' }", :class="{'active': isRoute('settings')}") Administración
        router-link.subnav__item(v-if='isMassiveEnabled' :to="{ name: 'settings-massive' }", :class="{'active': isRoute('settings-massive')}") Carga Masiva

      nav.subnav__list(v-else-if="nav=== 'appointment' && !isPatient")
        router-link.subnav__item(v-if="isDoctor" :to="{ name: 'schedule' }", :class="{'active': isRoute('schedule')}") Mi día
        router-link.subnav__item(:to="{ name: 'appointment' }", :class="{'active': isRoute('appointment')}") Calendario
        router-link.subnav__item(v-if="isAdmin || isDoctor" :to="{ name: 'appointment-agendas' }", :class="{'active': isRoute('appointment-agendas')}") {{ isDoctor ? 'Mis agendas' : 'Agendas' }}
        router-link.subnav__item(v-if="!isPatient" :to="{ name: 'appointment-relocation' }", :class="{'active': isRoute('appointment-relocation')}") Citas a reprogramar
      nav.subnav__list(v-else-if="nav=== 'appointment' && isPatient")
        router-link.subnav__item(:to="{ name: 'appointment' }", :class="{'active': isRoute('appointment')}") Buscar citas
        router-link.subnav__item(v-if="isPatient" :to="{ name: 'appointment-patient' }", :class="{'active': isRoute('appointment-patient')}") Mis citas
</template>

<style lang="scss" scoped>
.subnav {
  .headline__title {
    position: absolute;
    left: 15px;
  }
}
</style>
