import api from "./api";

const formService = {};
const endpoint = "/forms";

formService.getForms = function () {
  return api.get(`${endpoint}/type/form`).then((res) => res.data);
};

formService.postPatientForm = function (answer) {
  return api.post(`${endpoint}/type/patient`, answer).then((res) => res.data);
};

formService.getPatientFormsByPatient = function (patientId) {
  return api
    .get(`${endpoint}/type/patient/patient/${patientId}`)
    .then((res) => res.data);
};

export default formService;
