<script>
import Spinner from "@/components/ui/Spinner";
import VitalBox from "@/components/patient/dashboard/VitalBox";
import AlertBox from "@/components/patient/dashboard/AlertBox";
import ActivityAlertBox from "@/components/patient/dashboard/ActivityAlertBox";
import FormBox from "@/components/patient/dashboard/FormBox";
import IndicationBox from "@/components/patient/dashboard/IndicationBox";
import NotesBox from "@/components/patient/dashboard/NotesBox";
import configService from "@/services/config";
import patientService from "@/services/patient";
import alertsService from "@/services/alerts";
import segmentService from "@/services/segment";
import ModalTaskGraph from "@/components/patient/dashboard/ModalTaskGraph";
import ModalPatientTasksList from "@/components/patient/dashboard/ModalPatientTasksList";
import ModalManualAddTask from "@/components/patient/dashboard/ModalManualAddTask";
import ModalPatientMap from "@/components/patient/dashboard/ModalPatientMap";
import ModalTaskDetail from "@/components/patient/dashboard/ModalTaskDetail";
import ModalOpenEpisode from "@/components/patient/dashboard/ModalOpenEpisode";
import ModalGroup from "@/components/patient/dashboard/ModalGroup";
import ModalPatientEcgTable from "@/components/patient/dashboard/ModalPatientEcgTable";
import ModalPatientAppointments from "@/components/patient/dashboard/ModalPatientAppointments";
import PatientLabels from "@/components/patient/dashboard/PatientLabels";
import episodeService from "@/services/episode";
import helperService from "@/services/helper";
import appointmentService from "@/services/appointment";
import { mapGetters } from "vuex";
import {
  enableEpisodeCreation,
  isIndicationsEnabled,
  isVideoRoomEnabled,
} from "@/services/config";
import { get } from "lodash";

export default {
  name: "PatientDashboard",

  components: {
    Spinner,
    VitalBox,
    AlertBox,
    ActivityAlertBox,
    ModalTaskGraph,
    ModalPatientTasksList,
    ModalManualAddTask,
    ModalPatientMap,
    ModalTaskDetail,
    ModalOpenEpisode,
    ModalGroup,
    ModalPatientEcgTable,
    PatientLabels,
    FormBox,
    IndicationBox,
    NotesBox,
    ModalPatientAppointments,
  },

  data() {
    return {
      modal: "",
      selectedType: "",
      selectedTask: null,
      alertsLimit: 6,
      patientId: this.$route.params.id || this.$store.state.user._id,
      patient: {},
      publicGroup: [],
      isLoading: false,
      alerts: [],
      vitals: [],
      activityAlerts: [],
      showDialogModal: false,
      enableEpisodeCreation,
      key: this.$route.path,
      isSalesEnabled: configService.theme === "albya",
      isIndicationsEnabled,
      isVideoRoomEnabled,
    };
  },

  created() {
    segmentService.track("Patient Dashboard");
    this.loadInitialData();
  },

  computed: {
    ...mapGetters(["isAdmin", "isAssistant", "isDoctor", "isPatient"]),

    patientGender() {
      switch (this.patient.gender) {
        case "m":
          return "Hombre";

        case "f":
          return "Mujer";

        default:
          return "Otro";
      }
    },

    get() {
      return get;
    },
  },

  methods: {
    loadInitialData() {
      this.isLoading = true;

      const query = {
        scheduledAt: { $lte: new Date() },
      };

      Promise.all([
        patientService.getById(this.patientId),
        alertsService.getAllAlerts({
          patientID: this.patientId,
          query,
        }),
      ])
        .then(([patient, alerts]) => {
          this.patient = patient;
          this.$bus.$emit("update-title", helperService.getFullName(patient));
          this.activityAlerts = alertsService.getActivityAlerts({ alerts });
          const { vitals, rest } = alertsService.separateAlerts({ alerts });
          this.vitals = alertsService.groupBy({
            alerts: vitals,
            limit: this.alertsLimit,
          });
          this.alerts = alertsService.groupBy({
            alerts: rest,
            limit: this.alertsLimit,
            prop: "type",
          });
        })
        .then(async () => {
          if (this.patient.patient?.publicGroupId) {
            this.publicGroup = await patientService.getPublicGroupById(
              this.patient.patient.publicGroupId
            );
          }
        })
        .finally(() => (this.isLoading = false));
    },

    showModal({ modal, task, type, appointments }) {
      this.modal = modal;
      this.selectedTask = task;
      this.selectedType = type;
      this.appointments = appointments;
      this.showDialogModal = true;
    },

    closeModal() {
      this.modal = "";
      this.selectedType = null;
      this.selectedTask = null;
      this.showDialogModal = false;
    },

    onSaveManualTask(task) {
      this.closeModal();
      this.vitals[task.name].unshift(task);
      if (this.vitals[task.name].length > this.alertsLimit) {
        this.vitals[task.name].pop();
      }
    },

    async openEpisode(doctor) {
      try {
        await episodeService.create(this.patientId, {
          doctor,
        });
        this.closeModal();
      } catch (error) {
        //
      }
    },

    async goToPatientVideoRoom() {
      const appointments = await appointmentService.getAppointmentsByPatient({
        patientId: this.patientId,
        params: {
          attentionType: "virtual",
          offsetStartsAt: { $lte: new Date() },
          offsetEndsAt: { $gte: new Date() },
        },
      });

      if (appointments.length) {
        this.showModal({
          modal: "patient-appointments",
          appointments,
        });
      } else {
        this.$router.push({
          name: "patient-videoroom",
        });
      }
    },

    getIMC(weight, height) {
      return (weight / (height / 100) ** 2).toFixed(1);
    },
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.patientId = this.$route.params.id || this.$store.state.user._id;
        this.loadInitialData();
      },
      immediate: true,
    },
  },
};
</script>

<template lang="pug">
section.patient-dashboard
  spinner(v-if="isLoading")
  .data(v-else)
    .headline
      .patient-header
        //- h2 {{ patient.firstName | capitalize }} {{ patient.lastName | capitalize }}
        el-avatar(:src="patient.avatar ? patient.avatar.key : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" shape="square" :size="150")
        .patient-details
          .headline__details(v-if="patient.patient")
            .detail-item
              micon(color="grey" :name="this.patient.gender === 'f' ? 'woman' : 'man'")
              | {{ patientGender }}
              pre(v-if="patient.bornAt")  de {{ patient.bornAt | age }}
            .detail-item(v-if="get(patient, 'patient.health.weight', false)")
              micon(color="grey" name="weight" padding="2px")
              | {{ patient.patient.health.weight }} kgs {{ patient.patient.health.height && `/ IMC: ${getIMC(patient.patient.health.weight,patient.patient.health.height)}`}}
            .detail-item(v-if="get(patient, 'patient.health.height', false)")
              micon(color="grey" name="human-male-height" padding="2px")
              | {{ patient.patient.health.height }} cms
            //- .detail-item(v-if="patient.doctors && patient.doctors.length")
              | {{ patient.doctors[0].firstName | capitalize }} {{ patient.doctors[0].lastName | capitalize }}
            .detail-item(
              v-if="get(patient, 'patient.medicalInsuranceNumber', false)"
            )
              micon(color="grey" name="id_card" padding="1px")
              | Nro. socio: {{ patient.patient.medicalInsuranceNumber }}
            .detail-item(
              v-if="get(patient, 'patient.governmentId.number', false) && get(patient, 'patient.governmentId.type', false)"
            )
              micon(color="grey" name="dni" padding="3px")
              | {{ patient.governmentId.type.toUpperCase() }}: {{ patient.governmentId.number }}
          .headline__details
            .detail-item(v-if="patient.email")
              micon(color="grey" name="email" padding="2px")
              a(:href="`mailto:${patient.email}`") {{ patient.email }}
            .detail-item(v-if="patient.phone")
              micon(color="grey" name="call" padding="2px")
              a(:href="`tel:${patient.phone}`") {{ patient.phone }}
            .detail-item(v-if="patient.secondaryPhone")
              micon(color="grey" name="smartphone" padding="2px")
              a(:href="`tel:${patient.secondaryPhone}`") {{ patient.secondaryPhone }}
            //- .detail-item(v-if="patient.lastActivityAt")
              micon(color="grey" name="phonelink_ring")
              | Última actividad hace {{ patient.lastActivityAt | formatDistanceStrict }}
            //- .detail-item(v-else)
              micon(color="grey" name="phonelink_erase")
              | No tiene actividad
            //- .detail-item(v-if="patient.device && patient.device.hasNotificationsToken")
              micon(color="grey" name="notifications_active")
              | Notificaciones Activadas
            //- .detail-item(v-else)
              micon(color="grey" name="notifications_off")
              | Notificaciones desactivadas
            //- .detail-item(v-if="patient.passwordSetAt")
              | Contraseña configurada hace {{ patient.passwordSetAt | formatDistanceStrict }}

      .headline__buttons
        //- button.button.button--blue(@click="modal = 'map'")
          micon(name="location_on").button__icon
          span.button__text Ver Mapa

        //- button.button.button--blue(v-if="isVideoRoomEnabled && isDoctor" @click="goToPatientVideoRoom()")
        //-   micon.button__icon(name="call")
        //-   span.button__text Videollamada

        button.button.button--blue(v-if="publicGroup.length > 1" @click="showModal({modal: 'group'})")
          span.button__text Grupo Familiar

        //- button.button.button--blue(v-if="(isAdmin || isAssistant) && enableEpisodeCreation" @click="showModal({modal: 'open-episode'})")
        //-   micon.button__icon(name="note_add")
        //-   span.button__text Abrir Episodio
        router-link.button.button--blue(:to="{ name: 'patient-tasks' }")
          micon.button__icon(name="calendar")
          span.button__text Ver tareas
        router-link.button.button--blue(
          v-if="!isPatient"
          :to="{ name: 'assign-treatment-patient', params: { id: this.patientId } }"
        )
          micon.button__icon(name="add_circle_outline")
          span.button__text Editar Protocolos
        router-link.button.button--blue(v-if='this.isSalesEnabled && this.isDoctor' :to="{name: 'sales-list', params: {patient : this.patient}}")
          //- micon.button__icon(name='tickets')
          span.button__text Ver Catálogo de ventas
    
    patient-labels(v-if="!isPatient" v-model="patient.labels" :patientId="patient._id")

    //- .no-alerts(v-if="!Object.keys(vitals).length && !Object.keys(alerts).length")
      p No hay alertas

    .columns
      .top.rest-container
        form-box(v-if="!isPatient" :patient="patient")
        indication-box(v-if='isIndicationsEnabled' :patient="patient")
        activity-alert-box(v-if="!isPatient" :alerts="activityAlerts")
      .bottom.rest-container
        //- notes-box(v-model="patient")
        alert-box(
          v-if="Object.keys(alerts).length",
          v-for="(alert, key) in alerts",
          @show-modal="showModal",
          :ref="key"
          :key="key",
          :type="key",
          :alerts="alert"
        )
        vital-box(
          v-for="(vital, key) in vitals",
          @show-modal="showModal",
          :ref="key"
          :key="key",
          :name="key",
          :vitals="vital"
          :patient="patient"
        )

    modal-task-graph(
      v-if="modal === 'task-graph'",
      :patientId="patientId",
      :name="selectedTask",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-patient-tasks-list(
      v-if="modal === 'tasks-table'",
      :patientId="patientId",
      :name="selectedTask",
      :type="selectedType",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-manual-add-task(
      v-if="modal === 'add-manual-task'",
      :newTask="selectedTask",
      :patientId="patientId",
      :showDialogModal="showDialogModal",
      @close="closeModal",
      @onSave="onSaveManualTask"
    )
    modal-patient-map(
      v-if="modal === 'map'",
      :newTask="selectedTask",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-task-detail(
      v-if="modal === 'task-detail'",
      :task="selectedTask",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-open-episode(
      v-if="modal === 'open-episode'",
      :patientId="patientId",
      :showDialogModal="showDialogModal",
      @close="closeModal"
      @openEpisode="openEpisode"
    )
    modal-group(
      v-if="modal === 'group'",
      :publicGroup="publicGroup"
      :patientId="patient._id"
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-patient-ecg-table(
      v-if="modal === 'ecg-table'",
      :selectedTask="selectedTask",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
    modal-patient-appointments(
      v-if="modal === 'patient-appointments'",
      :appointments="appointments",
      :showDialogModal="showDialogModal",
      @close="closeModal"
    )
</template>

<style lang="scss" scoped>
.headline {
  margin-bottom: 0;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .patient-header {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .patient-details {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: auto auto;
    }
  }

  .headline__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
  }
}

.columns > .box {
  margin-bottom: 0;
}
</style>
