import api from "./api";

const supportService = {};

supportService.sendSupportMessage = function (query) {
  return api
    .post("users/support", {
      subject: "Soporte Dashboard",
      query,
    })
    .then((res) => res.data);
};

export default supportService;
