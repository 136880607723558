<script>
import logo from "@/mixins/logo";
import authService from "@/services/auth";
import configService from "@/services/config";
import inviteService from "@/services/invite";
import segmentService from "@/services/segment";

import Spinner from "@/components/ui/Spinner";

export default {
  name: "InviteValidate",
  mixins: [logo],

  components: {
    Spinner,
  },

  data() {
    return {
      verificationToken: "",
      passwordResetToken: "",
      password: "",
      passwordRepeat: "",
      user: {},
      step: "validation",
      termsUrl: `/${configService.theme}/tyc.pdf`,

      isLoading: false,
      showSignup: false,
      showDownload: false,
      sentInvite: false,
      failedInvite: false,
      terms: false,
      role: "",
      supportEmail: configService.supportEmail,
      mobileAppName: configService.mobileAppName,
    };
  },

  created() {
    authService.clearToken();
    this.isLoading = true;
    this.verificationToken = this.$route.params.code;
    this.passwordResetToken = this.$route.query.passwordResetToken;
    this.role = this.$route.query.role;

    this.user = Object.assign(
      {},
      {
        email: this.$route.query.email,
        name: this.$route.query.name,
      }
    );

    this.validateInvite();
  },

  computed: {
    errorMessage() {
      if (this.password !== this.passwordRepeat) {
        return "Las contraseñas deben ser iguales.";
      }

      return "";
    },

    isPasswordButtonDisabled() {
      if (!this.terms) {
        return true;
      }

      if (!this.password || !this.passwordRepeat) {
        return true;
      }

      if (this.password !== this.passwordRepeat) {
        return true;
      }

      return false;
    },
  },

  methods: {
    redirectLogin() {
      if (this.role === "scores-manager" && configService.loginRedirectURL) {
        window.location.replace(configService.loginRedirectURL);
      } else {
        this.$router.push({ name: "login" });
      }
    },

    redirectIOSStore() {
      window.location.href = configService.iOSAppStoreUrl;
    },

    redirectAndroidStore() {
      window.location.href = configService.androidAppStoreUrl;
    },

    sendInvite() {
      this.isLoading = true;
      inviteService
        .sendInvite(this.user.email)
        .catch(() => (this.failedInvite = true))
        .finally(() => {
          this.sentInvite = true;
          this.isLoading = false;
        });
    },

    validateInvite() {
      setTimeout(() => {
        inviteService
          .verificateWithToken(this.verificationToken)
          .then(() => {
            segmentService.track({ name: "Invite Validated", user: this.user });
            this.step = "password";
          })
          .catch((error) => {
            segmentService.track({
              name: "Error Validating Invite",
              $error: error,
              user: this.user,
            });
            this.step = "error";
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 2000);
    },

    setUserPassword() {
      segmentService.track({ name: "Invite Set Password", user: this.user });

      this.isLoading = true;
      inviteService
        .setUserPasswordWithToken(this.passwordResetToken, this.password)
        .then(() => {
          this.step = "final";

          if (
            this.role === "patient" ||
            this.$route.query.isPatient === "true" // Fix for backward compatibility
          ) {
            this.showDownload = true;
          } else {
            this.showSignup = true;
          }
        })
        .finally(() => (this.isLoading = false));
    },

    submit() {
      if (!this.errorMessage) {
        this.setUserPassword();
      }
    },
  },
};
</script>

<template lang="pug">
  .validation
    img.validation__logo(:src="logoAltUrl")
    .validation__box.validation__process(v-if="step == 'validation'")
      h1 Hola {{ user.name | capitalize }},
      h1 estamos validando su invitación
      .spinner-container
        spinner(size="15")

    .validation__box.validation__error(v-else-if="step == 'error'")
      micon(name="highlight_off")
      h2
        | Tu invitación para acceder a
        b  {{ mobileAppName }}
        |  ha caducado.
      el-button(
        v-show="!sentInvite"
        type="primary"
        @click="sendInvite"
      ) Solicitar una nueva
      hr
      p(v-show="!sentInvite || failedInvite")
        | {{
        |   failedInvite
        |     ? 'No pudimos generar una nueva invitación'
        |     : 'Si tenés problemas para efectuar tu ingreso'
        | }}, escribinos a&nbsp;
        a(:href="`mailto:${supportEmail}?subject=Problemas con mi invitación`")
          | {{ supportEmail }}
      p(v-show="sentInvite && !failedInvite")
        | Te hemos enviado una nueva invitación a&nbsp;
        b {{ user.email }}.
      .spinner-container(v-show="isLoading")
        spinner(size="10")

    .validation__box.validation__password(v-else-if="step == 'password'")
      ValidationObserver(v-slot="{handleSubmit}")
        form(@submit.prevent="handleSubmit(submit)")
          h2 Configura tu contraseña
          div
            p.password-tip
              | La contraseña debe tener al menos 8 caracteres alfanuméricos,
              | una mayúscula y una minúscula.

            ValidationProvider(name="Contraseña" rules="required|min:8|alpha_number|includes_uppercase" v-slot="{ errors }")
              el-input.password#password(
                type="password"
                v-model="password"
                name="Contraseña"
                show-password
                :class="{'has-error': errors[0]}"
              )
              span.has-error {{ errors[0] }}

            el-input.repeat-password#repeat-password(
              type="password"
              v-model="passwordRepeat"
              show-password
            )
            span.has-error(v-show="errorMessage") {{ errorMessage }}

            .terms
              el-checkbox(v-model="terms" id="terms")
                | He leído y acepto
                a(
                  :href="termsUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                ) los términos y condiciones de uso
                | .

            el-button.login__item(
              type="primary"
              :disabled="isPasswordButtonDisabled"
              :loading="isLoading"
              native-type="submit"
            ) Establecer Contraseña

    .validation__box.validation__password__success(v-else-if="step === 'final'")
      micon(name="check_circle_outline")
      h2 Tu contraseña fue configurada
      el-button(
        v-if="showSignup"
        type="primary"
        @click="redirectLogin"
      ) Ir a Iniciar Sesión

      .apps(v-if="showDownload")
        p Para acceder a la plataforma y comenzar tu protocolo descargá la aplicación de {{ mobileAppName }}.
        .download-links
          a.link-badge(
            @click.prevent="redirectIOSStore"
            v-tooltip="'Descargar desde AppStore'"
          )
            img(src="@/assets/images/appstore-badge.svg")
          a.link-badge(
            @click.prevent="redirectAndroidStore"
            v-tooltip="'Descargar desde Google PlayStore'"
          )
            img(src="@/assets/images/googleplay-badge.svg")

</template>

<style lang="scss" scoped>
.apps {
  text-align: center;

  .download-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .link-badge {
      height: 44px;
      width: 44px;
      display: block;
      overflow: hidden;
      border: solid 1px $primary;
      margin-right: 10px;
      &:hover {
        transform: scale(1.05) translateZ(0);
        transition: transform 0.25s ease-out;
      }

      img {
        vertical-align: middle;
        max-width: 100%;
      }
    }
  }
}
</style>
