<script>
import commissionService from "@/services/commission";

export default {
  name: "ModalCommission",

  data() {
    return {
      isPosting: false,
      commission: {},
      isUpdate: false,
    };
  },

  props: {
    selectedCommission: {
      type: Object,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    selectedCommission() {
      if (this.selectedCommission?._id) {
        this.isUpdate = true;
        this.commission = this.selectedCommission;
      }
    },
  },

  methods: {
    close() {
      this.isPosting = false;

      this.$emit("close");
    },

    submit() {
      if (this.isUpdate) {
        this.updateCommission();
      } else {
        this.createInstitution();
      }
    },

    createInstitution() {
      this.isPosting = true;

      commissionService
        .createCommission(this.commission)
        .then((commission) => {
          this.$bus.$emit("commission-created", commission);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    updateCommission() {
      this.isPosting = true;

      commissionService
        .updateCommission(this.commission)
        .then((commission) => {
          this.$bus.$emit("commission-updated", commission);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-commission", :visible.sync="showDialogModal", :before-close="close", :title="isUpdate ? 'Editar comisión' : 'Crear comisión'")
    .el-dialog__body
      label.label * Nombre
        ValidationProvider(name="Nombre" rules="required" v-slot="{ errors }")
          el-input(v-model="commission.name")
          span.has-error {{errors[0]}}
      
      label.label * Porcentaje
        ValidationProvider(name="Porcentaje" rules="required" v-slot="{ errors }")
          el-input(v-model="commission.percentage" type="number")
            template(slot="append")
              | %
          span.has-error {{errors[0]}}

    .el-dialog__footer
      el-button(type="primary" :loading="isPosting" @click="submit") Guardar
</template>
