<script>
import logo from "@/mixins/logo";
import session from "@/services/session";
import segmentService from "@/services/segment";

export default {
  name: "PasswordForgot",

  mixins: [logo],

  data() {
    return {
      email: "",
      isLoading: false,
      expiredPassword: this.$route.params.expiredPassword,
    };
  },

  methods: {
    resetPassword() {
      segmentService.track({ name: "Password Forgot" });
      this.isLoading = true;

      session.sendPasswordResetByEmail(this.email).finally(() => {
        this.isLoading = false;
        this.$message.info(
          "En caso de que exista una cuenta asociada, le enviaremos un email"
        );
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<template lang="pug">
  .login
    .login__container
      ValidationObserver(v-slot="{handleSubmit}")
        form.login__card(@submit.prevent="handleSubmit(resetPassword)")
          .login__img
            img(:src="logoUrl")
          h3.login__expired(v-show="expiredPassword") Su contraseña ha expirado. Por favor ingrese su email y recibirás un correo para generar una nueva
          ValidationProvider(name="Email" rules="required|email" v-slot="{ errors }")
            el-input#email(type="email" placeholder="Email" required autofocus="true" v-model="email" autocapitalize="none")
          el-button(type="primary" native-type="submit" :disabled="!email" :loading="isLoading") Restablecer Contraseña
          router-link.login__link(:to="{ name: 'login' }") Iniciar Sesión
</template>
