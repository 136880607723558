import api from "./api";

const patientService = {};
const endpoint = () => `/patients`;

patientService.create = function (patient) {
  return api.post(endpoint(), patient).then(({ data }) => data);
};

patientService.get = function (query) {
  return api
    .get(endpoint(), { params: query, sort: { lastName: 1, firstName: 1 } })
    .then((res) => res.data);
};

patientService.getById = function (id) {
  return api.get(`${endpoint()}/${id}`).then((res) => {
    res.data.address = res.data.address || {};
    // res.data.medicalInsurance = res.data.medicalInsurance || {};
    // TODO DEFINE THIS
    const patientHealth = res.data.health || {};
    res.data.health = {
      ...patientHealth,
      allergies: patientHealth.allergies || [],
    };
    return res.data;
  });
};

patientService.getPoints = function (id) {
  return api.get(`${endpoint()}/${id}/points`).then((res) => res.data);
};

patientService.update = function (patient) {
  return api
    .put(`${endpoint()}/${patient._id}`, patient)
    .then(({ data }) => data);
};

// patientService.delete = function (id) {
//   return api.delete(`${endpoint()}/${id}`);
// };

patientService.getAggregates = function (params) {
  return api.get(`${endpoint()}/aggregates`, params).then((res) => res.data);
};

patientService.getPublicGroupById = function (publicGroupId) {
  return api
    .get(`${endpoint()}/public-group/${publicGroupId}`)
    .then((res) => res.data);
};

export default patientService;
