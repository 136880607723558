<script>
import { mapGetters } from "vuex";
import { theme } from "@/services/config";
import leave from "@/mixins/leave";
import { isEmpty } from "lodash";

import fileUploadConfigService from "@/services/fileupload-config";
import segmentService from "@/services/segment";
import indicationService from "@/services/indication";
import { s3Url } from "@/services/config";

import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import InputTag from "@/components/ui/InputTag";
import DiagnosisSelect from "@/components/ui/DiagnosisSelect";
import MedicationSelect from "@/components/ui/MedicationSelect";
import { INDICATIONS_TYPES, getIndicationForm } from "@/services/constants";

export default {
  name: "ModalIndication",
  mixins: [leave],

  components: {
    Spinner,
    FileUpload,
    InputTag,
    DiagnosisSelect,
    MedicationSelect,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentIndication: {
      type: Object,
    },
    isModal: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      theme,
      isLoading: false,
      isPosting: false,
      indicationsTypes: INDICATIONS_TYPES,
      indicationId: null,
      indication: {
        type: "",
        title: "",
        description: "",
        dosage: "",
        quantity: "",
        doctorId: "",
        patientId: this.currentIndication.patient._id,
        files: [],
        diagnosis: null,
      },
      hasFiles: false,
      isFetchingIndication: false,
      isNewIndication: this.currentIndication.modalType === "create-indication",
      isShowed: this.showDialogModal,
      usesExternalMedication: theme === "dash",
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor"]),

    uploadMediaEndpoint() {
      return fileUploadConfigService.getIndicationFilesEndpoint(
        this.indicationId
      );
    },

    indicationFiles() {
      const { files = [] } = this.indication;
      files.sort((a, b) => {
        a.mimeType < b.mimeType;
      });
      files.forEach(function (file) {
        file.url = s3Url + "/" + file.key;
      });
      return files;
    },

    indicationForm() {
      return getIndicationForm(this.indication.type)?.form;
    },
  },

  created() {
    if (!this.isNewIndication) {
      this.indication = this.currentIndication;
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      this.createIndication();
    },

    createIndication() {
      this.isPosting = true;
      this.showLeaveGuard = false;
      const eventName = "Indication Created";
      segmentService.track({ name: eventName });

      const formData = new FormData();
      for (const key in this.indication) {
        if (
          !isEmpty(this.indication[key]) ||
          this.indication[key] instanceof File
        ) {
          formData.append(key, this.indication[key]);
        }
      }

      indicationService
        .createIndication(formData)
        .then((indication) => {
          this.indicationId = indication._id;
          if (this.hasFiles) {
            this.handleUploadFiles();
          } else {
            this.close();
          }
          indication.doctor = this.$store.state.user;
          this.$bus.$emit("indication-created", indication);
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    handleUploadFiles() {
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    getFileName(key = "") {
      return key.split("/").pop();
    },

    async onSignatureChange(event) {
      const file = event.target.files[0];

      this.indication.privateKey = file;
    },

    getDropdownOptions() {
      return {
        method: "put",
        uploadMultiple: true,
        maxFiles: 8,
        maxFilesize: 5,
        acceptedFiles: "image/*,application/pdf",
      };
    },

    setDiagnosis(diagnosis) {
      this.indication.diagnosis = diagnosis;
    },

    uploadFilesSuccess() {
      // REVISAR
      this.$router.back();
    },

    uploadFilesError() {
      // REVISAR
      //this.isPostingPatient = false;
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
    },

    close() {
      this.isPosting = false;
      this.isShowed = false;
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
    ValidationObserver(v-slot="{handleSubmit}")
      form(@submit.prevent="handleSubmit(submit)" v-if='isNewIndication' :class="isModal && 'modal'")
        header.modal__header
          h2.modal__title Indicacion {{ indication.title }}
          .modal__actions
            el-button(type="info" @click="close()") Cancelar
            el-button.border(type="primary" native-type="submit" :loading="isPosting") Guardar
        .modal__content(v-if="isFetchingIndication")
          spinner
        .modal__content
          .modal__block
            .modal__section
              .modal__sign.sign(v-if="isModal")
                .sign__icon
                  micon(name="assignment")
                h3.sign__title Detalle de la indicación
              article(:class="isModal && 'modal__fields'")
                .modal__row
                  fieldset.modal__field
                    label.label * Tipo
                    ValidationProvider(name="Tipo" rules="required" v-slot="{ errors }")
                      el-select(v-model="indication.type")
                        el-option(
                          v-for="(type, typeKey) in indicationsTypes"
                          v-if="type.type !== 'diagnosis'"
                          :value="type.type"
                          :label="type.name"
                          :key="typeKey"
                          )
                      span.has-error {{errors[0]}}
                .modal__row(v-if="indication.type !== 'medications'")
                  fieldset.modal__field
                    label.label Título
                      el-input(v-model="indication.title")
                .modal__row
                  fieldset.modal__field
                    label.label Descripción (opcional)
                      el-input(v-model="indication.description")
                .modal__row(v-for="(data,index) in indicationForm" :key="index" )
                    medication-select(
                      :usesExternalMedication="usesExternalMedication"
                      v-if="data.label === 'drugName'"
                      @update:modelValue="selected => {usesExternalMedication ? indication.publicMedication = JSON.stringify(selected) : indication.drugName = selected}"
                    )
                    fieldset.modal__field(v-else)
                      label.label {{ data.friendlyName }}
                      el-input(:type='data.inputType' v-model='indication[data.label]' :value="data.defaultValue")
                .modal__row(v-if='indication.type === "diagnosis" || indication.type === "medications"' )
                    fieldset.modal__field
                      label.label Diagnóstico
                        DiagnosisSelect(
                          :modelValue="indication.diagnosis"
                          @update:modelValue="diagnosis => indication.diagnosis = diagnosis"
                        )
                .modal__row(v-if="theme === 'dash' && indication.type === 'medications'")
                  fieldset.modal__field
                    label.label Firmar:
                    input(type="file" ref="signatureFileUpload" v-on:change="onSignatureChange")

                .modal__row
                  fieldset.modal__field
                    label.label Archivos adjuntos (8 máx):
                    file-upload(
                      ref="fileUpload",
                      :url="uploadMediaEndpoint",
                      :dropzone-options="getDropdownOptions()"
                      @file-added="onFileAdded",
                      @files-removed="onFileRemoved",
                      @queue-complete="close"
                    )
    
      el-dialog(
          v-else
          title='Indicación'
          custom-class="event-detail-modal",
          :visible.sync="isShowed"
          :before-close="close"
        )
          .view-task
            .metadata

              .form-item
                .description Tipo
                .response {{ currentIndication.name }}

              .form-item(v-if='indication.title && indication.type === "medications"')
                .description Título
                .response {{ indication.title }}

              .form-item(v-if='indication.description')
                .description Descripción
                .response {{ indication.description }}  

              div(v-if='indicationForm')
                .form-item(v-if='indication.drugName')
                  .description Nombre (Fármaco)
                  .response {{ indication.drugName.name }}, {{indication.drugName.presentation}}
                .form-item(v-if='indication.publicMedication')
                  .description Nombre (Fármaco)
                  .response {{ indication.publicMedication.name }}
                .form-item(v-if='indication.dosage')
                  .description Dosis (Cantidad)
                  .response {{ indication.dosage }}  
                .form-item(v-if='indication.frequency')
                  .description Frecuencia (hs)
                  .response {{ indication.frequency }}
                .form-item(v-if='indication.duration')
                  .description Plazo (días)
                  .response {{ indication.duration }}
                .form-item(v-if='indication.observation')
                  .description Observaciones
                  .response {{ indication.observation }}

              .form-item(v-if='indication.diagnosis')
                .description Diagnóstico
                .response {{ indication.diagnosis.description }}     

              .form-item(v-if="indication.files.length")
                .description Archivos adjuntos:
                  .attached-files
                    .file(
                      v-for="file in indicationFiles"
                      :class="{ image: file.mimeType.includes('image') }"
                    )
                      a(:href="file.url" target="_blank" rel="noopener noreferrer")
                        img(
                          v-if="file.mimeType.includes('image')"
                          :src="file.url"
                          alt="Archivo adjunto"
                        )
                        span(v-else) {{ getFileName(file.key) }}

          span(slot="footer")
            button.button.button--blue(@click="close") Salir
</template>

<style lang="scss" scoped>
article {
  width: 100%;
}

.location {
  .micon {
    margin-right: 10px;
  }
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }

    .attached-files {
      display: flex;
      flex-wrap: wrap;
      .file {
        width: 100%;
        margin: 0 15px 15px 0;

        &:not(.image) a::before {
          content: "→ ";
        }
      }
      .file.image {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        box-shadow: 5px 4px 8px -3px rgba(0, 0, 0, 0.47);
        img {
          object-fit: cover;
          height: -webkit-fill-available;
        }
      }
    }
  }

  .view-task {
    padding: 1rem;
  }
}
</style>
