<script>
import supportService from "@/services/support";

export default {
  name: "SupportForm",

  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      query: "",
    };
  },

  methods: {
    closeModal() {
      this.query = "";
      this.$emit("close");
    },

    submitForm() {
      supportService.sendSupportMessage(this.query);
      this.closeModal();
    },
  },
};
</script>

<template lang="pug">
el-dialog(
  title="Formulario de Soporte"
  :beforeClose="closeModal"
  :visible.sync="dialogFormVisible"
)
  fieldset.modal__field
    label.label
      | En caso de tener algún problema técnico, escribinos tu consulta aquí:
    el-input(
      placeholder="Envianos tu consulta"
      type="textarea"
      :rows="5"
      v-model="query"
    )
  span.dialog-footer(slot="footer")
    el-button.button(@click="closeModal") Cancelar
    el-button.button(type="primary" @click="submitForm") Enviar
</template>
