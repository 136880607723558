import Pagination from "@/components/ui/Pagination";

export default {
  components: { Pagination },

  data() {
    return {
      pagination: {
        currentPage: 1,
        offset: 0,
        total: 0,
        limit: 50,
      },
    };
  },

  watch: {
    "pagination.currentPage"() {
      this.onPageChange && this.onPageChange();
    },
  },

  methods: {
    setPagination(pagination) {
      this.pagination.currentPage = pagination.currentPage;
      this.pagination.offset = pagination.offset;
    },
  },
};
