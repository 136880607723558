import app from "@/main";
import axios from "axios";
import authService from "./auth";
import configService from "./config";
import router from "@/router/index";
import qs from "qs";

const api = axios.create({
  baseURL: configService.apiUrl,
  // timeout: 30 * 1000,
  paramsSerializer: function (params) {
    return qs.stringify(params);
  },
});

const getErrorMessage = (err) => {
  const statusCode = err.response && err.response.status;
  const data = err.response && err.response.data;

  if (
    data &&
    typeof data === "string" &&
    data.includes("E11000 duplicate key error")
  ) {
    return "Registro duplicado, intentelo nuevamente";
  }

  if (err.response?.data?.error) {
    switch (err.response.data.error) {
      case "IncompatibleAgendaConfiguration":
        return "Configuración de agenda incompatible";
      default:
        return "Se ha producido un error, intentelo nuevamente";
    }
  }

  switch (statusCode) {
    case 404:
      return "No se han encontrado recursos";
    case 403:
      return "Este usuario no tiene permisos para acceder al recurso solicitado";
    case 400:
      return "Alguno de los campos introducidos es incorrecto";
    case 409:
      return "El recurso que desea crear ya existe";
    case 419:
      return "Contraseña Expirada";
    default:
      return "Se ha producido un error, intentelo nuevamente";
  }
};

const handleError = (err, options, route = "/") => {
  const statusCode = err.response && err.response.status;
  const errorField = err?.response?.data?.errors?.[0]?.field;
  const errorKind = err?.response?.data?.errors?.[0]?.kind;

  // Let the service handle cancellation token errors
  if (axios.isCancel(err)) {
    console.log("Request canceled. Cancel message: ", err.message);
    return Promise.reject(err);
  }

  if (route.includes("/auth") && statusCode === 401) {
    const message = "Contraseña incorrecta, vuelve a intentar";
    app.$message.error(message);
    return Promise.reject(err);
  }

  //TODO: to handle more fields and kinds, separate in a GetValidationMessage method
  if (
    statusCode === 400 &&
    errorField === "password" &&
    errorKind === "already-used"
  ) {
    const message =
      "Tu contraseña no pudo ser actualizada, recordá que debe ser diferente a las últimas 3 que utilizaste.";
    app.$message.error(message);
    return Promise.reject(err);
  }

  if (statusCode === 401) {
    authService.clearToken();
    if (router.app._route.name !== "login") {
      router.push({ name: "login" });
    }
  }

  if (statusCode === 419) {
    const expiredPassword = true;
    router.push({ name: "password-forgot", params: { expiredPassword } });
  }

  const message = getErrorMessage(err);
  console.log(message);
  app.$message.error(message);
  return Promise.reject(err);
};

const handlers = {
  get: (...args) => {
    const options = args[args.length - 1];
    return api.get(...args).catch((err) => {
      return handleError(err, options);
    });
  },

  post: (...args) => {
    const options = args[args.length - 1];
    const route = args[0];
    return api.post(...args).catch((err) => {
      return handleError(err, options, route);
    });
  },

  put: (...args) => {
    const options = args[args.length - 1];
    const route = args[0];
    return api.put(...args).catch((err) => {
      return handleError(err, options, route);
    });
  },

  delete: (...args) => {
    const options = args[args.length - 1];
    return api.delete(...args).catch((err) => {
      return handleError(err, options);
    });
  },
};

api.interceptors.request.use(
  function (config) {
    const token = authService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default handlers;
