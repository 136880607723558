<script>
import helperService from "@/services/helper";
import patientService from "@/services/patient";
import vadiguAppointmentService from "@/services/vadiguAppointments";
import { APPOINTMENTS_TYPES_VADIGU } from "@/services/constants";

export default {
  name: "VadiguModalAppointment",

  props: {
    showDialogModal: {
      type: Boolean,
      default: false,
    },
    isNewAppointment: {
      type: Boolean,
      required: true,
    },
    doctorId: {
      type: String,
      required: true,
    },
    appointment: {
      type: Object,
      required: true,
    },
    selectedDate: {
      type: Date,
      required: true,
    },
  },

  data() {
    return {
      appointmentsTypes: APPOINTMENTS_TYPES_VADIGU,
      patients: [],
      selectedPatient: "",
      isLoading: false,
      newAppointment: {
        appointmentStatus: "proposed",
        displayAppointmentType: "virtual",
        codeAppointmentType: "",
        start: this.appointment.start,
        minutesDuration: this.appointment.timeDuration,
        medicalSpecialtyId: this.appointment.medicalSpecialties,
        patientId: "",
        professionalId: this.doctorId,
        institutionId: this.appointment.institutionId,
      },
      isPosting: false,
    };
  },

  created() {
    if (this.isNewAppointment) {
      this.getPatients();
    }
  },

  methods: {
    getPatients() {
      this.isLoading = true;

      patientService
        .get()
        .then((res) => {
          this.patients = res.docs;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    //ToDo: see what returns this method
    async createAppointment() {
      try {
        this.isPosting = true;
        await vadiguAppointmentService.createAppointment(this.newAppointment);
      } finally {
        this.isPosting = false;
        this.close();
      }
    },

    submit() {
      this.createAppointment();
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
    el-dialog(v-if='isNewAppointment' name="modal-new-appointment", :visible.sync="showDialogModal", :before-close="close")
      ValidationObserver(v-slot="{handleSubmit}")
        form.modal(@submit.prevent="handleSubmit(submit)")
          header.modal__header
            h2.modal__title Asignar nueva cita
            .modal__actions
              el-button(type="info" @click="close()" :disabled='isPosting') Cancelar
              el-button.border(type="primary" native-type="submit" :loading="isPosting") Guardar
          .modal__content
            .modal__block
              .modal__section
                .modal__sign.sign
                  .sign__icon
                    micon(name="calendar")
                  h3.sign__title Datos de la cita
                article.modal__fields
                  .modal__row
                    fieldset.modal__field
                      label.label Fecha
                      el-date-picker.date-picker(:value='selectedDate' disabled format='dd-MM-yyyy') 
                    fieldset.modal__field
                      label.label Horario
                      el-input(:value='appointment.rangeHour' disabled)
                    fieldset.modal__field
                      label.label Duración (minutos)
                      el-input(:value='appointment.timeDuration' disabled)
                  .modal__row
                    fieldset.modal__field
                        label.label * Paciente
                          ValidationProvider(name="Paciente", rules="required", v-slot="{ errors }")
                            el-select.patient-assigned(
                              v-model="newAppointment.patientId"
                              placeholder="Seleccione un paciente"
                              filterable
                              clearable
                              required
                            )   
                              el-option(
                                v-for="patient in patients"
                                :key="patient._id"
                                :label="getFullName(patient)"
                                :value="patient._id"
                              )
                            span.has-error {{ errors[0] }}  
                  .modal__row
                    fieldset.modal__field
                        label.label * Modalidad
                        ValidationProvider(name="Modalidad", rules="required", v-slot="{ errors }")
                          el-select(
                            v-model="newAppointment.codeAppointmentType",
                            placeholder='Seleccione el tipo de cita'
                            required
                          )
                            el-option(
                              v-for="type in appointmentsTypes",
                              :key="type.code",
                              :label="type.name",
                              :value="type.code"
                            )  
                          span.has-error {{ errors[0] }} 
</template>

<style scoped>
.date-picker {
  width: 100% !important;
}
</style>
