<script>
import Spinner from "@/components/ui/Spinner";
import ModalMedicalInsurance from "@/components/settings/medicalInsurance/ModalMedicalInsurance";
import medicalInsuranceService from "@/services/medical-insurance";

export default {
  name: "MedicalInsuranceList",

  components: {
    Spinner,
    ModalMedicalInsurance,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      medicalInsurances: [],
      isMedicalInsuranceModalVisible: false,
      selectedMedicalInsurance: {},
    };
  },

  created() {
    this.getMedicalInsurances();

    this.$bus.$on("medical-insurance-created", (medicalInsurance) => {
      this.medicalInsurances.unshift(medicalInsurance);
    });
  },

  methods: {
    async getMedicalInsurances() {
      this.isLoading = true;

      medicalInsuranceService
        .get()
        .then((res) => {
          this.medicalInsurances = res.docs;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showMedicalInsuranceModal(medicalInsurance) {
      if (medicalInsurance) {
        this.selectedMedicalInsurance = { ...medicalInsurance };
      }
      this.isMedicalInsuranceModalVisible = true;
    },

    closeMedicalInsuranceModal() {
      this.isMedicalInsuranceModalVisible = false;
      this.selectedMedicalInsurance = null;
    },

    deleteMedicalInsurance(medicalInsurance) {
      this.$confirm(
        `¿Desea eliminar el seguro médico: ${medicalInsurance.name}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          medicalInsuranceService.deleteMedicalInsurance(medicalInsurance._id);

          this.medicalInsurances = this.medicalInsurances.filter(
            (i) => i._id !== medicalInsurance._id
          );
        })
        .catch(() => {});
    },

    onPageChange() {
      this.getMedicalInsurances();
    },
  },
};
</script>

<template lang="pug">
section.medical-insurance-list
  header.headline
    .headline__actions
      span
      .new_medicalInsurance.button.button--blue.box__icon(@click='showMedicalInsuranceModal()' v-auth="['doctor.create', 'manager.create', 'assistan.create', 'admin.create']")
        micon(name="add_circle_outline").button__icon
        span.button__text Nuevo Seguro Médico
  .box
    .box__content--stretch
      table
        thead
          tr
            th Nombre
        tbody
          spinner(v-if="isLoading")
          tr(v-else v-for="(medicalInsurance, index) in medicalInsurances" :key="medicalInsurance._id" @click='showMedicalInsuranceModal(medicalInsurance)')
            td {{ medicalInsurance.name }}

    ModalMedicalInsurance(
      v-if='isMedicalInsuranceModalVisible'
      :currentMedicalInsurance='selectedMedicalInsurance'
      :show-dialog-modal='isMedicalInsuranceModalVisible' 
      @close='closeMedicalInsuranceModal'
    )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.box {
  height: 100%;
}

.medical-insurance-list {
  height: 65vh;
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }
    }
  }
}
</style>
