<script>
import TopNav from "./layout/TopNav.vue";

export default {
  name: "Main",

  components: {
    TopNav,
  },
};
</script>

<template lang="pug">
  div
    top-nav(v-show="$route.path!=='/print'")
    main
      router-view
    footer
</template>
