<script>
import pagination from "@/mixins/pagination";
import Spinner from "@/components/ui/Spinner";
import conversationService from "@/services/conversation";
import userService from "@/services/user";
import { USER_ROLES } from "@/services/constants";

export default {
  name: "DashboardUnrepliedConversations",

  mixins: [pagination],

  components: {
    Spinner,
  },

  data() {
    return {
      isLoading: true,
      isLoadingDoctors: true,
      isLoadingManagers: true,
      unrepliedConversations: [],
      doctors: {},
      managers: {},
      searchInput: "",
      selectedDoctor: null,
      selectedManager: null,
    };
  },

  created() {
    this.getDoctors();
    this.getManagers();
    this.getUnrepliedConversations();
  },

  computed: {
    filteredConversations() {
      return this.unrepliedConversations.filter(({ doctor, patient }) => {
        const matchesUserName = this.searchInput
          ? this.matchesName(doctor, this.searchInput) ||
            this.matchesName(patient, this.searchInput)
          : true;

        const matchesDoctor = this.selectedDoctor
          ? doctor?._id === this.selectedDoctor
          : true;

        const matchesManager = this.selectedManager
          ? doctor?.managers?.includes(this.selectedManager)
          : true;

        return matchesUserName && matchesDoctor && matchesManager;
      });
    },
  },

  methods: {
    matchesName(user, query) {
      const _query = query.toLocaleLowerCase();
      return (
        user?.firstName.toLocaleLowerCase().includes(_query) ||
        user?.lastName.toLocaleLowerCase().includes(_query)
      );
    },

    async getDoctors() {
      this.isLoadingDoctors = true;
      try {
        const users = await userService.get({
          roles: [USER_ROLES.DOCTOR.id],
          select: ["firstName", "lastName"],
        });

        this.doctors = users.docs.reduce((acc, doctor) => {
          acc[doctor._id] = doctor;
          return acc;
        }, {});
      } finally {
        this.isLoadingDoctors = false;
      }
    },

    async getManagers() {
      this.isLoadingManagers = true;
      try {
        const users = await userService.get({
          roles: [USER_ROLES.MANAGER.id],
          select: ["firstName", "lastName"],
        });

        this.managers = users.docs.reduce((acc, manager) => {
          acc[manager._id] = manager;
          return acc;
        }, {});
      } finally {
        this.isLoadingManagers = false;
      }
    },

    async getUnrepliedConversations() {
      this.isLoading = true;
      this.unrepliedConversations = [];

      let defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
      };

      try {
        const { data: unrepliedConversations } =
          await conversationService.getUnrepliedConversations(defaultQuery);

        this.pagination.total = unrepliedConversations.total;
        this.unrepliedConversations = unrepliedConversations.docs;
      } finally {
        this.isLoading = false;
      }
    },

    onPageChange() {
      this.getUnrepliedConversations();
    },

    hasManager(doctor) {
      return doctor?.managers?.length;
    },

    getManagersFullName(managers) {
      const managersNames = managers
        .map((managerId) => {
          const manager = this.managers[managerId];

          if (!manager) {
            return;
          }

          return `${manager.firstName} ${manager.lastName}`;
        })
        .filter(Boolean);

      if (!managersNames.length) {
        return "Sin médico coordinador";
      }

      if (managersNames.length === 1) {
        return managersNames[0];
      } else {
        return managersNames.split(", ");
      }
    },
  },
};
</script>

<template lang="pug">
  section.unreplied-conversations
    header.headline
      //- .headline__title
        h1
          | Auditoría Chat
          small(v-if="filteredConversations.length")  ({{ filteredConversations.length }})
        hr
      .headline__actions
        el-input.search__input(
          prefix-icon="el-icon-search",
          v-model="searchInput",
          placeholder="Buscar",
          :clearable="true"
        )

        el-select(
          v-model="selectedDoctor"
          :loading="isLoadingDoctors"
          clearable
          default-first-option
          filterable
          placeholder="Médico"
          loading-text="Cargando Médicos..."
        )
          el-option(
            v-for="doctor in doctors"
            :key="doctor._id"
            :label="`${doctor.lastName}, ${doctor.firstName}`"
            :value="doctor._id"
          )
        el-select(
          v-model="selectedManager"
          :loading="isLoadingManagers"
          clearable
          default-first-option
          filterable
          placeholder="Médico Coordinador"
          loading-text="Cargando Médicos coordinadores..."
        )
          el-option(
            v-for="manager in managers"
            :key="manager._id"
            :label="`${manager.lastName}, ${manager.firstName}`"
            :value="manager._id"
          )

    .box.box--with-subnav
      .box__content--stretch
        spinner(v-if="isLoading && !unrepliedConversations.length")

        h2.no-conversations(v-if="!isLoading && !filteredConversations.length") No hay resultados
        table(v-if="filteredConversations.length")
          thead
            tr
              th Paciente
              th Médico
              th Médico coordinador
              th Último Msj
          tbody
            tr(v-for="conversation in filteredConversations" :key="conversation._id")
              td(v-if="conversation.patient") {{ conversation.patient.lastName | capitalize }}, {{ conversation.patient.firstName | capitalize }}
              td(v-else) Sin paciente

              td(v-if="conversation.doctor") {{ conversation.doctor.lastName | capitalize }}, {{ conversation.doctor.firstName | capitalize }}
              td(v-else) Sin médico

              td(v-if="hasManager(conversation.doctor)") {{ getManagersFullName(conversation.doctor.managers) }}
              td(v-else) Sin médico coordinador

              td Hace {{ conversation.lastPatientMessage.sentAt | formatDistanceStrict }}

    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      @pagination="setPagination"
    )
</template>

<style lang="scss" scoped>
.unreplied-conversations {
  .box--with-subnav {
    min-height: 100px;
  }

  .no-conversations {
    text-align: center;
    padding: 50px;
    font-size: 2em;
    width: 100%;
  }

  tbody td:hover {
    cursor: pointer;
  }
}
</style>
