<script>
import authService from "@/services/auth";
import logo from "@/mixins/logo";
import SubNav from "./SubNav.vue";
import SupportForm from "@/components/profile/SupportForm.vue";
import { mapGetters } from "vuex";
import { isAppointmentsEnabled, theme } from "@/services/config";

const USERNAME_LENGTH = 23;

export default {
  name: "TopNav",
  mixins: [logo],

  components: {
    SubNav,
    SupportForm,
  },

  data() {
    return {
      nav: "",
      defaultNav: "dashboard",
      showProfileMenu: false,
      isSupportModalOpen: false,
      isAppointmentsEnabled,
      isPaymentEnabled: theme === "dash",
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isAssistant", "isDoctor", "isPatient", "role"]),

    userName() {
      const name = this.$store.state.user.firstName;
      const lastName = this.$store.state.user.lastName;
      const username = `${name} ${lastName}`;

      return username.length > USERNAME_LENGTH
        ? `${username.slice(0, USERNAME_LENGTH)}...`
        : username;
    },

    isProfileSection() {
      return this.$route.name === "profile";
    },
  },

  watch: {
    "$route.path": {
      handler: function (path) {
        this.nav = path.split("/")[1] || "dashboard";
      },
      immediate: true,
    },
  },

  methods: {
    signOut() {
      authService.clearToken();
      this.$router.push({ name: "login" });
    },
    showSupportModal() {
      this.hideProfile();
      this.isSupportModalOpen = true;
    },
    showSettings() {
      this.hideProfile();
      this.$router.push({ name: "settings" });
    },
    showUsers() {
      this.hideProfile();
      this.$router.push({ name: "users-list" });
    },
    showPayments() {
      this.hideProfile();
      this.$router.push({ name: "payments-list" });
    },
    closeSupportModal() {
      this.isSupportModalOpen = false;
    },
    profile() {
      this.$router.push({ name: "profile" });
      this.hideProfile();
    },
    hideProfile() {
      this.showProfileMenu = false;
    },
    isActive(section) {
      if (section) {
        return this.$route.name === section;
      }
      return (
        this.$route.name === "profile" ||
        this.$route.name === "settings" ||
        this.$route.name === "users-list"
      );
    },
  },
};
</script>

<template lang="pug">
header.header
  .topnav
    router-link.topnav__logo(:to="{ name: 'home' }")
      img(:src="logoAltUrl")
    nav.topnav__list
      router-link.topnav__link.has-subnav(v-if="!isPatient" :to="{ name: 'home' }", :class="{ 'is-not-active': nav !== 'dashboard' }")
        micon(name="pending_actions")
        strong Seguimientos
      router-link.topnav__link(v-if="!isPatient" :to="{ name: 'patient' }" v-auth="'patient.view'")
        micon(name="people")
        strong Pacientes
      router-link.topnav__link.has-subnav(v-if="!isPatient" :to="{ name: 'treatment' }" v-auth="'treatment.view'")
        micon(name="assignment")
        strong Protocolos
      router-link.topnav__link(v-if='isAppointmentsEnabled && !isPatient' :to="{ name: 'appointment' }")
        micon(name="calendar")
        strong Agenda
      router-link.topnav__link(v-if='isAppointmentsEnabled && isDoctor' :to="{ name: 'schedule' }")
        micon(name="list")
        strong Mi día
      //- router-link.topnav__link(:to="{ name: 'users-list' }" v-auth="['doctor.query', 'manager.query', 'assistan.query', 'admin.query']")
        micon(name="admin_panel_settings")
        strong Usuarios
      //- router-link.topnav__link.has-subnav(:to="{ name: 'settings' }" v-auth="['doctor.query', 'manager.query', 'assistan.query', 'admin.query']")
        micon(name="settings")
        strong Configuraciones
      router-link.topnav__link(v-if="isPatient" :to="{ name: 'home' }" :class="{ 'is-not-active': nav !== 'dashboard' }")
        micon(name="person")
        strong Mi Panel
      //- router-link.topnav__link(v-if="isPatient" :to="{ name: 'appointment' }")
      //-   micon(name="calendar")
      //-   strong Buscar Citas
      router-link.topnav__link(v-if='isPaymentEnabled && (isDoctor || isPatient)' :to="{ name: 'payments-list' }")
        micon(name="history")
        strong {{ isPatient ? "Atenciones" : "Pagos" }}

    nav.user-dropdown(:class="{active: showProfileMenu, 'is-route-active': isActive()}", v-click-outside="hideProfile")
      a.user-dropdown__trigger(@click="showProfileMenu = !showProfileMenu", href="#")
        micon.user-dropdown__profile-icon(name="account_circle")
        .user-dropdown__content
          strong.user-dropdown__name {{ userName }}
          span.user-dropdown__role {{ role }}
        micon.user-dropdown__icon(name="expand_more" :class="{active: showProfileMenu}")
      a.user-dropdown__item(href="#" @click="profile" :class="{active: showProfileMenu, 'is-route-active': isActive('profile')}")
        strong Perfil
      a.user-dropdown__item(@click="showSettings" :class="{active: showProfileMenu, 'is-route-active': isActive('settings')}" v-if="isAdmin")
        strong Configuración
      a.user-dropdown__item(@click="showUsers" :class="{active: showProfileMenu, 'is-route-active': isActive('users-list')}" v-if="isAdmin")
        strong Usuarios
      a.user-dropdown__item(v-if="isPaymentEnabled && isAdmin" @click="showPayments" :class="{active: showProfileMenu, 'is-route-active': isActive('payments-list')}")
        strong Pagos
      a.user-dropdown__item(href="#" @click="showSupportModal" :class="{active: showProfileMenu}")
        strong Soporte
      a.user-dropdown__item(href="#" @click="signOut" :class="{active: showProfileMenu}")
        strong Cerrar sesión
  sub-nav(:nav="nav")
  support-form(:dialogFormVisible="isSupportModalOpen" @close="closeSupportModal")
</template>
