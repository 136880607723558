<script>
import Spinner from "@/components/ui/Spinner";
import pagination from "@/mixins/pagination";
import commisionService from "@/services/commission";
import helperService from "@/services/helper";
import ModalCommission from "./ModalCommission";

export default {
  name: "CommissionList",

  mixins: [pagination],

  components: {
    Spinner,
    ModalCommission,
  },

  data() {
    return {
      isLoading: false,
      commissions: [],
      isModalVisible: false,
      selectedCommission: {},
    };
  },

  created() {
    this.getCommissions();

    this.$bus.$on("commission-created", (commission) => {
      this.commissions.unshift(commission);
    });
  },

  methods: {
    async getCommissions(query = {}) {
      this.isLoading = true;

      let defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        populate: "createdBy",
        ...query,
      };

      commisionService
        .getCommissions(defaultQuery)
        .then((res) => {
          this.commissions = res.docs;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showCommissionModal(commission) {
      if (commission) {
        this.selectedCommission = commission;
      }
      this.isModalVisible = true;
    },

    closeCommissionModal() {
      this.isModalVisible = false;
      this.selectedCommission = null;
    },

    onPageChange() {
      this.getCommissions();
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },
  },
};
</script>

<template lang="pug">
  section.institution-list
    header.headline
      //- .headline__title
        h1
          | Instituciones
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        .new_institution.button.button--blue.box__icon(@click='showCommissionModal()')
          micon(name="add_circle_outline").button__icon
          span.button__text Nueva Comisión
    .box
      .box__content--stretch
        table
          thead
            tr
              th Comisión
              th Porcentaje
              th Creado por
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(commission, index) in commissions" :key="commission._id" @click='showCommissionModal(commission)')
              td {{ commission.name }}
              td {{ commission.percentage }}%
              td {{ getFullName(commission.createdBy) }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                          div(@click="showCommissionModal(commission)")
                            micon(name="edit")
                            | Editar comisión

      modal-commission(
        :selected-commission='selectedCommission'
        :show-dialog-modal='isModalVisible' 
        @close='closeCommissionModal'
      )

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )

</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.box {
  height: 100%;
}

.institution-list {
  height: 65vh;
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }
    }
  }
}
</style>
