<script>
import { mapGetters } from "vuex";

import leave from "@/mixins/leave";
import Spinner from "@/components/ui/Spinner";
import InputTag from "@/components/ui/InputTag";
import medicalInsuranceService from "@/services/medical-insurance";

export default {
  name: "ModalMedicalInsurance",
  mixins: [leave],

  components: {
    Spinner,
    InputTag,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentMedicalInsurance: {
      type: Object,
    },
  },

  data() {
    return {
      isLoading: false,
      isPosting: false,
      medicalInsuranceId: null,
      medicalInsurance: {
        name: "",
      },
      isFetchingMedicalInsurance: false,
      isUpdate: !!this.currentMedicalInsurance?._id,
      isShowed: this.showDialogModal,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor"]),
  },

  created() {
    if (this.isUpdate) {
      this.medicalInsurance = this.currentMedicalInsurance;
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    submit() {
      if (this.isUpdate) {
        this.updateMedicalInsurance();
      } else {
        this.createMedicalInsurance();
      }
    },

    createMedicalInsurance() {
      this.isPosting = true;

      medicalInsuranceService
        .createMedicalInsurance(this.medicalInsurance)
        .then((medicalInsurance) => {
          this.$bus.$emit("medical-insurance-created", medicalInsurance);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    updateMedicalInsurance() {
      this.isPosting = true;

      medicalInsuranceService
        .updateMedicalInsurance(this.medicalInsurance)
        .then((medicalInsurance) => {
          this.medicalInsuranceId = medicalInsurance._id;
          this.$bus.$emit("medical-insurance-updated", medicalInsurance);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },

    close() {
      this.isPosting = false;
      this.isShowed = false;
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
el-dialog(name="modal-medical-insurance", :visible.sync="showDialogModal", :before-close="close", :title="isUpdate ? 'Editar seguro médico' : 'Crear seguro médico'")
  .el-dialog__body
    label.label * Nombre
    ValidationProvider(name="Nombre" rules="required" v-slot="{ errors }")
      el-input(v-model="medicalInsurance.name")
      span.has-error {{errors[0]}}

    span(v-if="isUpdate")
      label.label Id
      el-input(v-model="medicalInsurance._id" readonly)

  .el-dialog__footer
    el-button(type="primary" :loading="isPosting" @click="submit") Guardar
</template>

<style lang="scss" scoped>
.location {
  .micon {
    margin-right: 10px;
  }
}

.modal {
  z-index: 9999;
}

.event-detail-modal {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0 0 20px;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .description {
      word-break: break-word;
      font-weight: bold;
      color: $primary;
      margin-bottom: 10px;
    }

    .response {
      margin-left: 20px;
      display: list-item;
      list-style-type: "→ ";
    }
  }

  .view-task {
    padding: 1rem;
  }
}

@media (min-width: 446px) {
  .container {
    flex-direction: row !important;
    justify-content: space-between;
  }

  .betweenStreet {
    width: 48.5% !important;

    &:last-child {
      margin-top: 0 !important;
    }
  }
}
</style>
