<script>
import {
  massiveActionsTypes,
  massiveActionsStatuses,
} from "@/services/constants";

export default {
  name: "MassiveActionsHistoryModal",

  data() {
    return {
      massiveActionsTypes,
      massiveActionsStatuses,
    };
  },

  props: {
    massiveAction: {
      type: Object,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getLabel(type) {
      const massiveActionType = this.massiveActionsTypes.find(
        (elem) => elem.value === type
      );
      return massiveActionType.label;
    },

    getStatus(status) {
      const massiveActionStatus = this.massiveActionsStatuses.find(
        (elem) => elem.value === status
      );
      return massiveActionStatus.label;
    },

    downloadTxt(text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", "errors.txt");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<template lang="pug">
  el-dialog(:title="getLabel(massiveAction.type)" :visible.sync="showDialogModal" :before-close="close")
    .el-dialog__body
      .history-body
        .left
          h2 Fecha
          p {{ massiveAction.createdAt | formatDateAndTime }}
          h2 Estado
          p {{ getStatus(massiveAction.status) }}
          h2 Registros exitosos:
          p {{ massiveAction.successList.length }} registro/s
          h2 Registros con errores:
          p {{ massiveAction.errorList.length }} registro/s
          el-button(v-if="massiveAction.errorList.length" type='primary' @click='downloadTxt(massiveAction.errorList)') Descargar reporte errores

        .right
          .treatment-assignation(v-if='massiveAction.type === "treatment-assignation"')
            h2 Filtros
            p(v-if="massiveAction.company") Empresa: {{ massiveAction.company }}
            p(v-if="massiveAction.userCreationDate") Fecha de creación: {{ massiveAction.userCreationDate }}
            p(v-if="massiveAction.assignatedTreatment") Protocolo: {{ massiveAction.assignatedTreatment }}
            p(v-if="massiveAction.assignatedTreatmentDate") Fecha asignación de protocolo: {{ massiveAction.assignatedTreatmentDate }}
            h2 Asignación
            p Protocolo: {{ massiveAction.assignationTreatment.name }}
            p Fecha: {{ massiveAction.assignationDate | formatDate }}

          

</template>

<style lang="scss">
.history-body {
  display: grid;
  grid-template-columns: auto auto;

  .left {
    .errors {
      cursor: pointer;
    }
  }
}
</style>
