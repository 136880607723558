<script>
export default {
  name: "InputTag",

  props: {
    value: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Separar por coma",
    },
  },

  data() {
    return {
      newTag: "",
    };
  },

  methods: {
    // delete tag
    handleNewTag(tag) {
      this.$emit("input", [...this.value.filter((t) => t !== tag)]);
    },

    // add tag
    handleKeyUp() {
      if (this.newTag && !this.value.includes(this.newTag)) {
        this.$emit("input", [...this.value, this.newTag]);
      }
      this.newTag = "";
    },

    handleKeyDown() {},
  },
};
</script>

<template lang="pug">
  .input-tag-wrapper
    el-input(
      v-model="newTag"
      :placeholder="placeholder"
      @keydown.enter.188.stop.prevent.native="handleKeyDown"
      @keyup.enter.188.native="handleKeyUp"
    )
    .tags
      el-tag(
        :key="tag"
        v-for="tag in value"
        v-if="tag"
        closable
        :disable-transitions="true"
        @close="handleNewTag(tag)"
        effect="plain"
        size="small"
      ) {{tag}}
</template>

<style lang="scss" scoped>
.el-input {
  margin-bottom: 10px;
}
</style>
