import api from "./api";
import { startOfDay, endOfDay } from "date-fns";

const massiveActionsService = {};
const endpoint = () => `/massive`;

massiveActionsService.getPatientsCount = function (query) {
  if (query.createdAt) {
    query.createdAt = {
      $gte: startOfDay(new Date(query.createdAt)),
      $lte: endOfDay(new Date(query.createdAt)),
    };
  }

  return api
    .get(`${endpoint()}/patients/count`, { params: query })
    .then((res) => res.data);
};

massiveActionsService.createMassiveProtocols = function (payload) {
  return api
    .post(`${endpoint()}/patients/treatments`, payload)
    .then((res) => res.data);
};

massiveActionsService.getMassiveActions = function () {
  return api.get(endpoint()).then((res) => res.data);
};

export default massiveActionsService;
