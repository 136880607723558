const paymentRoutes = [
  {
    path: "/payment",
    component: () =>
      import(/* webpackChunkName: "patient" */ "../layouts/PaymentLayout.vue"),
    children: [
      {
        path: "/",
        name: "payments-list",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../components/payment/PaymentList.vue"
          ),
      },
    ],
  },
];

export default paymentRoutes;
