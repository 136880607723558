<script>
export default {
  name: "AlertBadge",

  props: {
    alert: {
      type: Object,
      required: true,
    },

    name: {
      type: String,
      default: "Alerta",
    },
  },

  computed: {
    showBadge() {
      return (
        this.alert.shouldAlert &&
        this.alert.completedMeasurement &&
        this.alert.completedMeasurement !== "green"
      );
    },

    alertName() {
      return this.alert.shouldBeCompleted ? "Incompleta" : this.name;
    },
  },
};
</script>

<template lang="pug">
  .tag.alert-badge.card__tag(v-if="showBadge" :class="alert.completedMeasurement") {{ alertName }}
</template>
