import store from "@/store";
import Vue from "vue";
import VTooltip from "v-tooltip";
import vModal from "vue-js-modal";
import vClickOutside from "v-click-outside";

// TODO: Move permission logic to it's own file
function hasAlertPermission(requestedPermission) {
  if (requestedPermission !== "alert.view") {
    return false;
  }
  return store.getters.isDoctor || store.getters.isManager;
}

function hasViewPermissions(requestedPermission, permissions) {
  const [resource, action] = requestedPermission.split(".");
  return action === "view" && permissions.find((p) => p.startsWith(resource));
}

function hasPermission(requestedPermission, user) {
  return (
    user.permissions.includes(requestedPermission) ||
    hasViewPermissions(requestedPermission, user.permissions) ||
    hasAlertPermission(requestedPermission)
  );
}

function bind(el, binding) {
  const requestedPermission = binding.value;
  const user = store.state.user;
  if (!requestedPermission) {
    return;
  }

  if (typeof requestedPermission === "string") {
    if (!hasPermission(requestedPermission, user)) {
      el.style.display = "none";
    }
    return;
  }

  if (Array.isArray(requestedPermission)) {
    for (let i = 0; i < requestedPermission.length; i += 1) {
      if (hasPermission(requestedPermission[i], user)) {
        return;
      }
    }

    el.style.display = "none";
  }
}

const authorization = { bind };

Vue.directive("auth", authorization);
Vue.use(vClickOutside);
Vue.use(vModal, { dialog: true });
Vue.use(VTooltip);
