<script>
import patientTaskService from "@/services/patient-task";

export default {
  name: "ManualTaskAssign",

  data() {
    return {
      isLoading: false,
      answers: this.newTask.answerValues,
    };
  },

  props: {
    newTask: {
      type: Object,
      required: true,
    },
    patientId: {
      type: String,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    createTask() {
      this.isLoading = true;

      const task = Object.assign({}, this.newTask);
      delete task._id;
      delete task.id;
      delete task.createdAt;
      delete task.updatedAt;
      delete task.icon;
      delete task.verifiedAt;
      delete task.verifiedBy;
      delete task.verifiedByEvent;
      delete task.completedMeasurement;
      task.completedAt = new Date();
      task.scheduledAt = new Date();
      task.answerValues = this.answers;
      patientTaskService
        .create({
          patientID: this.patientId,
          taskType: "vitals",
          task,
        })
        .then((newTask) => {
          this.$emit("onSave", newTask.data);
        })
        .finally(() => (this.isLoading = false));
    },
  },

  computed: {
    isEmpty() {
      return this.answers.some((answer) => !answer.value);
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-manual-task-assign", :visible.sync="showDialogModal", title="Carga Manual" , :before-close="close")
    .el-dialog__body(v-for="answer in answers")
      h4 {{answer.name}}
      el-input(v-model="answer.value")

    .el-dialog__footer
      el-button(type="primary" :loading="isLoading" @click="createTask" :disabled="isEmpty") Guardar
</template>
