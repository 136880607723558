const patientRoutes = [
  {
    path: "/patient",
    component: () =>
      import(/* webpackChunkName: "patient" */ "../layouts/PatientLayout.vue"),
    children: [
      {
        path: "/patient",
        name: "patient",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientList.vue"
          ),
      },
      {
        path: "/patient/create",
        name: "create-patient",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientDetail.vue"
          ),
      },
      {
        path: "/patient/:id",
        name: "update-patient",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientDetail.vue"
          ),
      },
      {
        path: "/patient/:id/treatment",
        name: "assign-treatment-patient",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientTreatmentDetail.vue"
          ),
      },
      {
        path: "/patient/:id/dashboard",
        name: "patient-dashboard",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/dashboard/PatientDashboard.vue"
          ),
      },
      {
        path: "/patient/:id/video-room/:appointmentId?",
        name: "patient-videoroom",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientVideoRoomView.vue"
          ),
      },
      {
        path: "/patient/:id/tasks",
        name: "patient-tasks",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/PatientTasks.vue"
          ),
        children: [
          {
            path: ":taskType/create",
            name: "create-patient-task",
            component: () =>
              import(
                /* webpackChunkName: "patient" */ "../components/task/TaskDetail.vue"
              ),
          },

          {
            path: ":taskType/:taskID",
            name: "update-patient-task",
            component: () =>
              import(
                /* webpackChunkName: "patient" */ "../components/task/TaskDetail.vue"
              ),
          },
        ],
      },
      {
        path: "/patient/:id/sales",
        name: "sales-list",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/dashboard/sales/SalesList.vue"
          ),
      },
      {
        path: "/print",
        name: "event-print",
        component: () =>
          import(
            /* webpackChunkName: "patient" */ "../components/patient/printing/FormPrint.vue"
          ),
      },
    ],
  },
];

export default patientRoutes;
