import Vue from "vue";
import * as SvgIcon from "vue-svgicon";
import "regenerator-runtime";

import App from "./App.vue";
import store from "./store";
import bus from "./services/bus";
import router from "./router/index";

import MIcon from "@/components/ui/MIcon";

import "./filters";
import "./validator";
import "./directives";
import "./registerServiceWorker";
import sentryService from "@/services/sentry";
import segmentService from "@/services/segment";

import "./plugins/element.js";

// imported here to override pluggins styles
import "@/styles/index.scss";

sentryService.init();
segmentService.setUser();

Vue.config.errorHandler = function (err, vm, info) {
  console.log({ err, vm, info });
};

Vue.prototype.$bus = bus;
Vue.config.productionTip = false;
Vue.use(SvgIcon, { isOriginalDefault: true });
Vue.component("micon", MIcon);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default app;
