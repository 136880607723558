<script>
export default {
  name: "ModalPatientMap",

  props: {
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-patient-map", :visible.sync="showDialogModal", :before-close="close")

    img.map(src="/img/map.png")

    .footer
      fieldset(style="margin-left: auto")
        button.button.button--blue(@click="close") Salir
</template>

<style lang="scss" scoped>
.map {
  width: 100%;
}
.disabled {
  cursor: none;
  pointer-events: none;
}
.head__actions {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
}
.head__search > * {
  margin: 0 0 0 15px;
}
.head__search {
  display: flex;
  align-items: center;
}
.box {
  margin: 10px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.no-treatments {
  text-align: center;
  padding: 50px;
}
</style>
