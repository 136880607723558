<script>
export default {
  name: "EcgChart",

  props: {
    data: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      gridRef: undefined,
      chartRef: undefined,
      ecgList: [],
      yScale: 0,
      xs: 0,
      speedX: undefined,
      pages: undefined,
      page: 0,
    };
  },

  watch: {
    page() {
      this.dealData();
    },
  },

  mounted() {
    this.gridRef = this.$refs.gridRef;
    this.chartRef = this.$refs.chartRef;
    this.speedX = this.getSpeed();

    this.ecgList = [];
    this.ecgList = this.data.map((point) => {
      return this.getY(point);
    });

    this.drawGrid();
    this.dealData();

    window.addEventListener("resize", () => {
      this.drawGrid();
      this.dealData();
    });
  },

  unmounted() {
    window.removeEventListener("resize", () => {
      this.drawGrid();
      this.dealData();
    });
  },

  methods: {
    drawGrid() {
      const gridCanvas = this.gridRef;
      const chartCanvas = this.chartRef;
      if (gridCanvas.getContext && chartCanvas.getContext) {
        const gridCtx = gridCanvas.getContext("2d");

        const width = this.$refs.container.offsetWidth;

        chartCanvas.width = width;
        chartCanvas.height = this.height;
        gridCanvas.width = width;
        gridCanvas.height = this.height;

        this.drawSmallGrid(gridCtx, width, this.height);
        this.drawBigGrid(gridCtx, width, this.height);
      }
    },

    drawSmallGrid(ctx, width, height) {
      ctx.strokeStyle = "#828282";
      ctx.lineWidth = 1;
      ctx.beginPath();
      for (let x = 0; x <= width; x += 5) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
      }
      for (let y = 0; y <= height; y += 5) {
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
      }

      ctx.closePath();
    },

    drawBigGrid(ctx, width, height) {
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.strokeStyle = "#2c3e50";
      for (let x = 0; x <= width; x += 25) {
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
      }
      for (let y = 0; y <= height; y += 25) {
        ctx.moveTo(0, y);
        ctx.lineTo(width, y);
        ctx.stroke();
      }
      ctx.closePath();
    },

    dealData() {
      const pointsLength = this.chartRef.width / this.speedX;

      this.pages = [];
      for (let i = 0; i < this.ecgList.length; i += pointsLength) {
        this.pages.push(this.ecgList.slice(i, i + pointsLength));
      }

      this.requestECGWaveLayout({ points: this.pages[this.page] });
    },

    requestECGWaveLayout({ points }) {
      if (this.chartRef.getContext) {
        const chartCtx = this.chartRef.getContext("2d");

        chartCtx.clearRect(0, 0, this.chartRef.width, this.chartRef.height);
        chartCtx.strokeStyle = "#000000"; // TODO DEFINE COLOR
        chartCtx.beginPath();

        const path = new Path2D(this.chartRef);

        points.forEach((point, index) => {
          path.lineTo(this.speedX * index, point);
          if (index % 500 == 0) {
            chartCtx.stroke(path);
          }
        });

        chartCtx.stroke(path);
      }
    },

    getSpeed() {
      const DATA_PER_SEC = 512;
      const scale = this.getXScale(1);
      const dataPerLattice = DATA_PER_SEC / (25 * scale);
      return this.getxS() / dataPerLattice;
    },

    getXScale(type) {
      let scale = 0;
      switch (type) {
        case 1:
          scale = 1; //25mm/s
          break;
        case 2:
          scale = 2; //50mm/s
          break;
      }
      return scale;
    },

    getxS() {
      const spac1 = 5;
      return spac1;
    },

    getY(data) {
      const height = this.chartRef.height;
      if (this.xs === 0) {
        this.xs = this.getxS();
      }
      if (this.yScale === 0) {
        this.yScale = this.getYScale(2);
      }

      return (
        height / 2 - ((((data * 18.3) / 128) * this.xs) / 100) * this.yScale
      );
    },

    getYScale(type) {
      let scale = 0;
      switch (type) {
        case 1:
          scale = 0.5; //5mm/mV
          break;
        case 2:
          scale = 1; //10mm/mV
          break;
        case 3:
          scale = 2; //20mm/mV
          break;
      }
      return scale;
    },

    changePage(direction) {
      switch (direction) {
        case "left":
          this.page > 0 && this.page--;
          break;

        case "right":
          this.page < this.pages.length - 1 && this.page++;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<template lang="pug">
  .container(ref="container")
    canvas(ref="gridRef" class="grid")
    canvas(ref="chartRef" class="chart")
    .controls
      .left(@click="changePage('left')")
        micon(name="chevron_left" width="50" height="50")
      .right(@click="changePage('right')")
        micon(name="chevron_right" width="50" height="50")
</template>

<style>
.grid {
  position: absolute;
}
.chart {
  position: relative;
  z-index: 999;
}
.controls {
  display: flex;
  justify-content: space-between;
}
</style>
