import SettingsDashboard from "@/components/settings/SettingsDashboard.vue";
import MassiveSettingsDashboard from "@/components/settings/massive/MassiveSettingsDashboard.vue";

const settingsRoutes = [
  { path: "/settings", name: "settings", component: SettingsDashboard },
  {
    path: "/settings/massive",
    name: "settings-massive",
    component: MassiveSettingsDashboard,
  },
];

export default settingsRoutes;
