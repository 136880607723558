const treatmentRoutes = [
  {
    path: "/treatment",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../layouts/TreatmentLayout.vue"
      ),
    children: [
      {
        path: "/treatment",
        name: "treatment",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/treatment/TreatmentList.vue"
          ),
      },

      {
        path: "/treatment/create",
        name: "create-treatment",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/treatment/TreatmentDetail.vue"
          ),
      },

      {
        path: "/treatment/:id([0-9a-f]{24})",
        name: "update-treatment",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/treatment/TreatmentDetail.vue"
          ),
      },
    ],
  },
];

const tasksRoutes = [
  {
    path: "/treatment/appointment",
    name: "treatment-appointment",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/AppointmentDefaultList.vue"
      ),
  },

  {
    path: "/treatment/exercises",
    name: "treatment-exercise",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/ExercisesDefaultList.vue"
      ),
  },

  {
    path: "/treatment/medication",
    name: "treatment-medication",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/MedicationDefaultList.vue"
      ),
  },

  {
    path: "/treatment/recommendation",
    name: "treatment-recommendation",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/RecommendationDefaultList.vue"
      ),
  },

  {
    path: "/treatment/reports",
    name: "treatment-report",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/ReportsDefaultList.vue"
      ),
  },

  {
    path: "/treatment/checkins",
    name: "treatment-checkin",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/CheckinsDefaultList.vue"
      ),
  },

  {
    path: "/treatment/config/:taskType/create",
    name: "create-task",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/task/TaskDetail.vue"
      ),
  },
  {
    path: "/treatment/config/:taskType/:taskID",
    name: "update-task",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/task/TaskDetail.vue"
      ),
  },

  {
    path: "/treatment/vital",
    name: "treatment-vital",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/config/VitalDefaultList.vue"
      ),
    children: [
      {
        path: "create",
        name: "create-vital",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/treatment/config/VitalDefaultDetail.vue"
          ),
      },
      {
        path: ":id",
        name: "update-vital",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/treatment/config/VitalDefaultDetail.vue"
          ),
      },
    ],
  },
];

const treatmentTasksRoutes = [
  {
    path: "/treatment/:id/task",
    name: "treatment-tasks",
    component: () =>
      import(
        /* webpackChunkName: "treatment" */ "../components/treatment/TreatmentTasks.vue"
      ),
    children: [
      {
        path: ":taskType/create",
        name: "create-treatment-task",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/task/TaskDetail.vue"
          ),
      },
      {
        path: ":taskType/:taskID",
        name: "update-treatment-task",
        component: () =>
          import(
            /* webpackChunkName: "treatment" */ "../components/task/TaskDetail.vue"
          ),
      },
    ],
  },
];

const routes = [...treatmentRoutes, ...tasksRoutes, ...treatmentTasksRoutes];

export default routes;
