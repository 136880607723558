<script>
import Spinner from "@/components/ui/Spinner";
import patientService from "@/services/patient";
import conversationService from "@/services/conversation";
import helperService from "@/services/helper";
import { format } from "date-fns";
import loadAllServerMessages from "./loadAllServerMessages";

export default {
  name: "DashboardConversation",

  components: {
    Spinner,
  },

  props: {
    clear: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      patient: null,
      channel: null,
      newMessage: "",
      messages: [],
    };
  },

  watch: {
    "$route.params.id": {
      handler: function () {
        this.init();
      },
      immediate: true,
    },
  },

  destroyed() {
    if (this.channel) {
      this.channel.removeAllListeners();
    }
  },

  computed: {
    doctorId() {
      return this.$store.state.user._id;
    },

    patientName() {
      const name = this.patient
        ? helperService.getFullName(this.patient)
        : "Paciente";
      return name;
    },
  },

  methods: {
    async init() {
      try {
        if (this.channel) {
          this.channel.removeAllListeners();
        }
        this.isLoading = true;
        this.channel = null;
        this.messages = [];
        await this.getPatient(this.$route.params.id);
        await this.tryToLoadChannel();
      } finally {
        this.isLoading = false;
      }
    },

    tryToLoadChannel() {
      return conversationService
        .getClientPromise()
        .then((twilioClient) => {
          const uniqueName = `${this.doctorId}-${this.patient._id}`;
          return twilioClient.getChannelByUniqueName(uniqueName);
        })
        .then((channel) => {
          if (channel) {
            this.channel = channel;
            this.readAllMessages();
            this.channel.on("messageAdded", this.onNewMessage);
            return loadAllServerMessages(channel);
          }
        })
        .then((messages) => {
          this.messages = messages;
        })
        .finally(() => {
          this.messages.length && this.scrollMessagesToBottom();
        });
    },

    getPatient(id) {
      return patientService.getById(id).then((res) => (this.patient = res));
    },

    scrollMessagesToBottom() {
      setTimeout(() => {
        const container = this.$el.querySelector(".detail__content.chat");
        container.scrollTop = container.scrollHeight;
      }, 0);
    },

    processNewMessage() {
      const message = this.newMessage;
      this.newMessage = "";
      if (this.channel) {
        this.sendNewMessage(message);
      } else {
        const { doctorId } = this;
        const patientId = this.patient._id;

        conversationService
          .generateChannel({ doctorId, patientId })
          .catch((e) => console.log("error creating channel", e))
          .then(() => this.tryToLoadChannel())
          .then(() => this.sendNewMessage(message));
      }
    },

    sendNewMessage(message) {
      this.channel.sendMessage(message);
    },

    onNewMessage(message) {
      this.readAllMessages();
      this.messages.push(message);
      this.scrollMessagesToBottom();
    },

    getMessageClass(message) {
      if (message.author === this.doctorId) {
        return "right";
      }

      return "left";
    },

    formatTime(timestamp) {
      return format(timestamp, "dd/MM/yyyy HH:mm");
    },

    readAllMessages() {
      // Update twilio
      this.channel.setAllMessagesConsumed();

      // Update our collection
      conversationService.update({
        id: this.channel.sid,
        conversation: {
          lastPatientMessage: {
            readAt: new Date(),
          },
        },
      });
    },
  },
};
</script>

<template lang="pug">
  section.detail(v-if="isLoading")
    spinner

  section.detail(v-else)
    header.detail__header.detail__header--chat
      h3.detail__headline {{ patientName }}
    article.detail__content.chat
      .chat__item(v-for="message in messages", :class="getMessageClass(message)")
        img.chat__avatar(src="/img/avatar.png")
        .chat__content
          p.chat__message {{ message.body }}
          small.chat__datetime {{ formatTime(message.dateCreated) }}
    footer.chat__footer
      el-input.chat__input(placeholder="Escribe un mensaje aquí...", v-model="newMessage", @keyup.enter.native="processNewMessage")
      nav.chat__actions
        button.button.button--blue.chat__button(@click="processNewMessage", :disabled="!newMessage.length")
          micon(name="reply" width="19" height="19" color="white").chat__icon.button__icon
          span.button__text Enviar
</template>

<style lang="scss" scoped>
.detail__header--chat {
  padding: 5px;
  height: 40px;
}
</style>
