<script>
import Spinner from "@/components/ui/Spinner";
import MonitoringCard from "@/components/dashboard/MonitoringCard";
import patientService from "@/services/patient";
import userService from "@/services/user";
import helperService from "@/services/helper";
import episodeService from "@/services/episode";
import pagination from "@/mixins/pagination";
import { EVENTS_TYPES } from "@/services/constants";
import { USER_ROLES } from "@/services/constants";
import { mapGetters } from "vuex";
import debounce from "debounce";
import { differenceInHours, parseISO } from "date-fns";
import moment from "moment";
import { orderBy } from "lodash";

const DEBOUNCE_TIMEOUT_MS = 500;

export default {
  name: "DashboardAlerts",

  mixins: [pagination],

  components: {
    MonitoringCard,
    Spinner,
  },

  data() {
    return {
      EVENTS_TYPES,
      patients: [],
      doctors: {},

      isLoading: false,
      isLoadingDoctors: false,
      filterByActiveTreatment: true,

      searchInput: "",
      searchQuery: {},

      query: {
        status: "",
        measurement: "",
        doctor: "",
      },

      measurementOptions: [
        {
          text: "Rojas",
          value: "red",
        },
        {
          text: "Amarillas",
          value: "yellow",
        },
        {
          text: "Verdes",
          value: "green",
        },
        {
          text: "Sin Revisar",
          value: "requiresVerification",
        },
        {
          text: "Incompletas",
          value: "incomplete",
        },
      ],

      // Overwrite pagination mixin
      pagination: {
        limit: 100,
      },
    };
  },

  created() {
    this.getPatients();

    if (!this.isDoctor) {
      this.getDoctors();
    }
  },

  computed: {
    ...mapGetters(["isDoctor"]),

    statusOptions() {
      const filteredObject = {};

      Object.keys(this.EVENTS_TYPES).forEach((key) => {
        if (key !== "closed") {
          filteredObject[key] = this.EVENTS_TYPES[key];
        }
      });

      return {
        ...filteredObject,
        newPatient: {
          friendlyName: "Paciente nuevo",
          status: "new-patient",
        },
      };
    },
  },

  watch: {
    filterByActiveTreatment() {
      this.filteredPatients = this.filterByActiveTreatment
        ? this.patients.filter((patient) => patient.assignedTreatments.length)
        : this.patients;
      this.pagination.total = this.filteredPatients.length;
    },
  },

  methods: {
    getPatients() {
      this.isLoading = true;

      const query = {};
      this.query.status && (query.status = this.query.status);
      this.query.measurement && (query.measurement = this.query.measurement);
      this.query.doctor && (query.doctors = this.query.doctor);

      const aggregationQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        // Query status, measurement and doctor
        ...query,
      };

      // Query by name or using Mongo $text search index
      if (this.searchInput) {
        Object.assign(aggregationQuery, this.searchQuery);
      }

      patientService
        .getAggregates({
          params: aggregationQuery,
        })
        .then((data) => {
          if (!data) {
            return;
          }

          const docs = data.docs;
          if (!docs) {
            return;
          }

          return docs;
        })
        .then(async (res) => {
          if (res.length) {
            this.patients = await Promise.all(
              res.map(async (patient) => {
                const episodeRes = await episodeService.get(patient._id);
                const episodes = episodeRes.docs;
                const activeEpisode = episodes.find(
                  ({ status }) => status === "active"
                );

                patient.assignedTreatments = orderBy(
                  activeEpisode.treatments
                    .filter((treatment) => !treatment.unnasignedAt)
                    .map((treatment) => ({
                      ...treatment,
                      length: moment(treatment.lastTaskScheduledAt).diff(
                        moment(treatment.firstTaskScheduledAt)
                      ),
                    })),
                  "length",
                  ["desc"]
                );

                return patient;
              })
            );

            this.filteredPatients = this.filterByActiveTreatment
              ? this.patients.filter(
                  (patient) => patient.assignedTreatments.length
                )
              : this.patients;
            this.pagination.total = this.filteredPatients.length;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },

    async getDoctors() {
      this.isLoadingDoctors = true;
      try {
        const users = await userService.get({
          roles: [USER_ROLES.DOCTOR.id],
          select: ["firstName", "lastName"],
        });

        this.doctors = users.docs.reduce((acc, doctor) => {
          acc[doctor._id] = doctor;
          return acc;
        }, {});
      } finally {
        this.isLoadingDoctors = false;
      }
    },

    onSearch: debounce(function () {
      if (!this.searchInput) {
        this.searchQuery = {};
        this.getPatients();
        return;
      }

      const input = this.searchInput.split(" ");
      const useSearchIndex = input && (input.length === 1 || input.length > 3);

      // Search up to 3 words (Double names or double last names)
      const useNameIndex = input && input.length > 1 && input.length <= 3;

      if (useSearchIndex) {
        this.searchQuery = {
          $text: {
            $search: this.searchInput,
          },
        };
        this.getPatients();
        return;
      } else if (useNameIndex) {
        Object.assign(
          this.searchQuery,
          helperService.createNameQuery(this.searchInput)
        );
      }

      this.getPatients();
    }, DEBOUNCE_TIMEOUT_MS),

    // Mixin method
    onPageChange() {
      this.getPatients();
    },

    doesPatientRequiresVerification(user) {
      const { lastEvent } = user.patient;
      const { lastEventCreatedAt } = user.patient;
      if (!lastEvent || !lastEventCreatedAt) {
        return true;
      }

      return differenceInHours(new Date(), parseISO(lastEventCreatedAt)) >= 24;
    },

    getPatientStatus(user) {
      const { patient } = user;

      if (patient.status === "closed") {
        return "Cerrado";
      }

      const lastEvent = user.patient.lastEvent;
      if (!lastEvent) {
        return "Paciente nuevo";
      }

      const lastEventWasClosedStatus = lastEvent.status === "closed";
      const isActive = patient.status === "active";

      // This happens when the patient has an active episode but the last event
      // was a closed event from another episode.
      if (isActive && lastEventWasClosedStatus) {
        return "Paciente nuevo";
      }

      const name = lastEvent && lastEvent.form && lastEvent.form.name;
      return name;
    },

    getPatientDoctorsNames(doctors) {
      return helperService.getPatientDoctorsNames(doctors);
    },
  },
};
</script>

<template lang="pug">
  section.dashboard-alerts
    header.headline

      //- .headline__title
        h1
          | Seguimiento
          small(v-if="!isLoading && pagination.total")  ({{ pagination.total }})

        hr

      .headline__actions
        .left
          el-input.search__input(
            prefix-icon="el-icon-search"
            v-on:input="onSearch"
            v-model="searchInput"
            placeholder="Buscar"
            :clearable="true"
          )

          el-select(
            v-model="query.measurement"
            clearable placeholder="Tipo"
            default-first-option
            filterable
            v-on:change="getPatients"
            :disabled="isLoading"
            )
            el-option(
              v-for="option in measurementOptions"
              :key="option.value"
              :label="option.text"
              :value="option.value"
            )

          //- TODO this filter should be updated when we support multi episode
          //- el-select(
          //-   v-model="query.status"
          //-   clearable
          //-   placeholder="Estado"
          //-   default-first-option
          //-   filterable
          //-   v-on:change="getPatients"
          //-   :disabled="isLoading"
          //- )
          //-   el-option(
          //-     v-for="option in statusOptions"
          //-     :key="option.status"
          //-     :label="option.friendlyName"
          //-     :value="option.status"
          //-   )

          el-select(
            v-if="!isDoctor"
            v-model="query.doctor"
            :loading="isLoadingDoctors"
            v-on:change="getPatients"
            clearable
            default-first-option
            filterable
            placeholder="Médico"
            loading-text="Cargando Médicos..."
            )
            el-option(
              v-for="doctor in doctors"
              :key="doctor._id"
              :label="`${doctor.lastName}, ${doctor.firstName}`"
              :value="doctor._id"
            )

        .right
          el-switch(
            v-model="filterByActiveTreatment"
            active-text="Sólo pacientes activos"
            inactive-text="Todos los pacientes"
          )  

    spinner(v-if="isLoading")

    h2.no-alerts(v-if="!isLoading && !patients.length") No hay resultados

    .content(v-if="!isLoading && patients.length")
      monitoring-card(v-for="user in filteredPatients" :key="user._id" :user="user" :filterByActiveTreatment="filterByActiveTreatment" )



      //- table(v-if="!isLoading && patients.length")
        thead
          tr
            th Paciente
            th(v-if="!isDoctor") Médico
            th Estado
            th Última Actualización
            th Alertas
        tbody
          tr(v-for="user in patients", :key="user.id" @click="goToPatientDashboard(user._id)")
            td {{ user.lastName }}, {{ user.firstName }}
            td(v-if="!isDoctor") {{ user.doctors.length ? getPatientDoctorsNames(user.doctors) : 'Sin Médico' }}

            td {{ getPatientStatus(user) }}

            td(v-if="user.patient.lastEventCreatedAt") Hace {{ user.patient.lastEventCreatedAt | formatDistanceStrict }}
            td(v-else) Sin actualización
            td.actions
              .actions-container
                monitoring-tag(type="red" name="Rojas" :count="user.patient.aggregates.red")
                monitoring-tag(type="yellow" name="Amarillas" :count="user.patient.aggregates.yellow")
                monitoring-tag(type="green" name="Verdes" :count="user.patient.aggregates.green")
                monitoring-tag(type="incomplete" name="Incompletas" :count="user.patient.aggregates.incomplete")
                monitoring-tag(v-if="doesPatientRequiresVerification(user)" type="without-checking" name="Sin Revisar")

    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      @pagination="setPagination"
    )
</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
}

.dashboard-alerts {
  .headline {
    @include media(tablet-up) {
      .headline__actions {
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: flex-start;

        & > * {
          // margin: 0 15px 15px 0;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    @include media(desktop-up) {
      .headline__title {
        margin: 0 15px 15px 0;
      }
      .headline__actions {
        width: 100%;
        // justify-content: center;
        & > .el-input,
        & > .el-select {
          margin: 0 15px 0 0;
          &:first-child {
            // margin: 0;
          }
        }
      }
    }
    @include media(laptop-up) {
      .headline__title {
        margin: 0 15px 0 0;
      }
      .headline__actions {
        flex: 1;
      }
    }
  }

  .box--with-subnav {
    @include media(mobile-up) {
      height: calc(100vh - 302px);
    }
    @include media(tablet-up) {
      height: calc(100vh - 332px);
    }
    @include media(desktop-up) {
      height: calc(100vh - 272px);
    }
    @include media(laptop-up) {
      height: calc(100vh - 232px);
    }
  }
}
</style>
