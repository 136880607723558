import api from "./api";

const medicalSpecialtyService = {};
const medicalSpecialtyEndpoint = "/medical-specialty";

medicalSpecialtyService.createMedicalSpecialty = function (medicalSpecialty) {
  return api
    .post(medicalSpecialtyEndpoint, medicalSpecialty)
    .then(({ data }) => data);
};

medicalSpecialtyService.updateMedicalSpecialty = function (medicalSpecialty) {
  return api
    .put(
      `${medicalSpecialtyEndpoint}/${medicalSpecialty._id}`,
      medicalSpecialty
    )
    .then(({ data }) => data);
};

medicalSpecialtyService.getMedicalSpecialties = function (queryParams) {
  return api
    .get(medicalSpecialtyEndpoint, { params: queryParams })
    .then((res) => res.data);
};

medicalSpecialtyService.deleteInstitution = function (medicalSpecialtyId) {
  return api.delete(`${medicalSpecialtyEndpoint}/${medicalSpecialtyId}`);
};

export default medicalSpecialtyService;
