import jwtDecode from "jwt-decode";

import store from "@/store";
import api from "@/services/api";
import config from "@/services/config";
import sentryService from "@/services/sentry";
import segmentService from "@/services/segment";
import conversationService from "@/services/conversation";

const authService = {};
const endpoint = "/auth/token";

authService.authenticate = function (email, password) {
  return api
    .post(endpoint, { email, password })
    .then((res) => {
      store.commit("setToken", res.data.token);
      store.commit("setUser", res.data.user);
      sentryService.setUser();
      segmentService.setUser();
      return res.data;
    })
    .then(({ token, user }) => {
      const payload = jwtDecode(token);
      if (!payload.aud.includes(config.audience)) {
        this.clearToken();
        window.location.href = config.marketingWebsiteUrl;
        return Promise.reject();
      }

      return { user };
    });
};

// Note: I think this does not make sense, if we are going to use vuex, we should
//       retrieve the data from vuex directly.
authService.getToken = function () {
  return store.state.token;
};

// Note: Rename clearToken to clear session or something like that, we are not managing
//       just tokens now.
authService.clearToken = function () {
  store.commit("setToken", "");
  store.commit("setUser", {});

  const twilioClient = conversationService.getClient();

  if (twilioClient) {
    twilioClient.shutdown();
  }

  conversationService.setClient(null);
  conversationService.setClientPromise(null);
};

export default authService;
