import jwtDecode from "jwt-decode";
import authService from "@/services/auth";
import configService from "@/services/config";

export function verifyToken(to, from, next) {
  if (to.name === "login") {
    authService.clearToken();
    return next();
  }

  if (to.meta.isPublic) {
    return next();
  }

  const token = authService.getToken();
  if (!token) {
    authService.clearToken();
    const query = to.path === "/" ? {} : { redirectUrl: to.path };
    return next({ name: "login", query });
  }

  const decodedToken = jwtDecode(token);
  const isExpiredToken = Math.floor(Date.now() / 1000) > decodedToken.exp;

  if (isExpiredToken || !decodedToken.aud.includes(configService.audience)) {
    authService.clearToken();
    return next({ name: "login" });
  }

  if (
    configService.organizationId &&
    decodedToken.organization !== configService.organizationId
  ) {
    authService.clearToken();
    return next({
      name: "error",
      query: {
        code: 401,
        message: "Tu usuario no pertenece a esta organización.",
      },
    });
  }

  next();
}

export function trackPage(to, from, next) {
  if (window.analytics) {
    // TODO: Change window.title dynamically
    const { fullPath, params, query } = to;
    window.analytics.page({
      path: fullPath,
      params,
      query,
      tenant: configService.theme,
      url: `${window.location.protocol}://${window.location.host}${fullPath}`,
    });
  }

  next();
}
