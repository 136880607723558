import api from "./api";

const treatmentService = {};
const endpoint = () => `/treatments`;

treatmentService.create = function (treatment) {
  return api.post(endpoint(), treatment).then((res) => res.data);
};

treatmentService.get = function (query) {
  return api.get(endpoint(), { params: query }).then((res) => res.data);
};

treatmentService.getById = function (id, opts = {}) {
  const options = {
    toast: { 404: opts.displayToast === undefined ? true : opts.displayToast },
  };
  return api.get(`${endpoint()}/${id}`, options).then((res) => res.data);
};

treatmentService.update = function (treatment) {
  return api
    .put(`${endpoint()}/${treatment._id}`, treatment)
    .then((res) => res.data);
};

treatmentService.delete = function (id) {
  return api.delete(`${endpoint()}/${id}`).then((res) => res.data);
};

export default treatmentService;
