import api from "./api";
import Twilio from "twilio-chat";

const conversationService = {};

let clientPromise;
let client;

conversationService.getClientPromise = function () {
  if (clientPromise) {
    return clientPromise;
  }

  clientPromise = connect();
  return clientPromise;
};

conversationService.getClient = function () {
  return client;
};

conversationService.setClientPromise = function (value) {
  clientPromise = value;
};

conversationService.setClient = function (value) {
  client = value;
};

conversationService.generateChannel = function ({ patientId, doctorId }) {
  return api.post("/conversations", { patientId, doctorId });
};

function connect() {
  return getToken().then((token) => {
    const isLocalhost = document.location.host.includes("localhost");
    const logLevel = isLocalhost ? "info" : "silent";

    return Twilio.create(token, { logLevel }).then((_client) => {
      _client.on("tokenAboutToExpire", function () {
        getToken().then((newToken) => {
          _client.updateToken(newToken);
        });
      });

      client = _client;

      return _client;
    });
  });
}

function getToken() {
  return api.get("/conversations/token").then((res) => {
    return res.data.token;
  });
}

conversationService.getUnrepliedConversations = function (query) {
  return api.get("/conversations/unreplied", {
    params: { ...query, sort: { lastName: 1, firstName: 1 } },
  });
};

conversationService.update = function ({ id, conversation }) {
  return api.put(`/conversations/${id}`, conversation).then((res) => res.data);
};

export default conversationService;
