<script>
import logo from "@/mixins/logo";
import authService from "@/services/auth";
import session from "@/services/session";
import segmentService from "@/services/segment";
import configService from "@/services/config";

export default {
  name: "PasswordReset",
  mixins: [logo],

  data() {
    return {
      password: "",
      passwordRepeat: "",
      isLoading: false,
      isPasswordReady: false,

      passwordResetToken: this.$route.params.token,
      isPatient: this.$route.query.isPatient === "true",
      mobileAppName: configService.mobileAppName,
    };
  },

  computed: {
    errorMessage() {
      if (this.password !== this.passwordRepeat) {
        return "Las contraseñas deben ser iguales.";
      }

      return "";
    },

    isPasswordButtonDisabled() {
      if (
        !this.password ||
        !this.passwordRepeat ||
        this.password !== this.passwordRepeat
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    setUserPassword() {
      segmentService.track({ name: "Password Reset" });

      this.isLoading = true;
      session
        .setUserPasswordWithToken(this.passwordResetToken, this.password)
        .then(() => {
          this.isLoading = false;
          this.isPasswordReady = true;
          authService.clearToken();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.setUserPassword();
    },

    redirectLogin() {
      this.$router.push({ name: "login" });
    },

    redirectIOSStore() {
      window.location.href = configService.iOSAppStoreUrl;
    },

    redirectAndroidStore() {
      window.location.href = configService.androidAppStoreUrl;
    },
  },
};
</script>

<template lang="pug">
.login
    .login__container(v-if="!isPasswordReady")
      ValidationObserver(v-slot="{handleSubmit}")
        form.login__card(@submit.prevent="handleSubmit(submit)")
          .login__img
            img(:src="logoUrl")
          p.password-tip Debe tener al menos 8 caracteres alfanuméricos, una mayúscula y una minúscula.
          ValidationProvider(name="Contraseña" rules="required|min:8|alpha_number|includes_uppercase" v-slot="{ errors }")
            el-input#password1(type="password" placeholder="Contraseña" v-model="password" show-password)
            span.has-error {{ errors[0] }}
          ValidationProvider(name="Nueva Contraseña" rules="is:@Contraseña" v-slot="{ errors }")
            el-input#password2(type="password" placeholder="Repetir contraseña" v-model="passwordRepeat" show-password)
            span.has-error {{ errors[0] }}

          el-button.login__item(
            v-if="!isPasswordReady"
            native-type="submit"
            type="primary"
            :disabled="isPasswordButtonDisabled"
            :loading="isLoading"
          ) Cambiar Contraseña

    .login__container(v-if="isPasswordReady")
      .login__card
        micon(name="check_circle_outline")
        h2.success-message Tu contraseña ha sido actualizada
        p(v-if="isPatient") Desde ahora, deberás usar tu nueva contraseña para iniciar sesión en la aplicación.
        el-button(v-else type="primary" @click="redirectLogin") Ir a Iniciar Sesión

        p Para acceder a la plataforma y comenzar tu protocolo descargá la aplicación de {{ mobileAppName }}.
        .download-links
          a.link-badge(
            @click.prevent="redirectIOSStore"
            v-tooltip="'Descargar desde AppStore'"
          )
            img(src="@/assets/images/appstore-badge.svg")
          a.link-badge(
            @click.prevent="redirectAndroidStore"
            v-tooltip="'Descargar desde Google PlayStore'"
          )
            img(src="@/assets/images/googleplay-badge.svg")
</template>

<style lang="scss" scoped>
.password-tip {
  text-align: center;
  margin: 30px 0;
}

.login__card {
  .micon {
    height: 60px;
    width: 60px;
    fill: $turquoise;
  }

  .download-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .link-badge {
      height: 44px;
      width: 44px;
      display: block;
      overflow: hidden;
      border: solid 1px $primary;
      margin-right: 10px;
      &:hover {
        transform: scale(1.05) translateZ(0);
        transition: transform 0.25s ease-out;
      }

      img {
        vertical-align: middle;
        max-width: 100%;
      }
    }
  }
}

.success-message {
  margin: 20px 0;
}

span.has-error {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 10px;
}
</style>
