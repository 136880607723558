<script>
import logo from "@/mixins/logo";
import configService from "@/services/config";

export default {
  name: "Error",
  mixins: [logo],

  data() {
    return {
      supportEmail: configService.supportEmail,
      message: this.$route.query.message || "",
      code: this.$route.query.code || "404",
    };
  },

  computed: {
    innerMessage() {
      if (this.code === "404") {
        return "El recurso que estás buscando no existe.";
      }

      if (this.code === "401" || this.code === "403") {
        return "No tenés permisos para acceder a este recurso.";
      }

      return "Ha ocurrido un error :(";
    },
  },

  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<template lang="pug">
  .validation
    img.validation__logo(:src="logoAltUrl")
    .validation__box.validation__error
      micon(name="highlight_off")
      h1 Error {{ code }}
      h2 {{ message || innerMessage }}

      el-button(type="primary" @click="goToLogin") Volver al Dashboard

      p
        | ¿Querés reportar un problema? Escribinos un email a &nbsp;
        a(href="`mailto:${supportEmail}?subject=Problemas con mi invitación`") {{ supportEmail }}
</template>

<style lang="scss" scoped>
.validation__box {
  align-items: center;
  text-align: center;
  .micon {
    margin-bottom: 20px;
  }
}
</style>
