import { version } from "../../package.json";
import store from "@/store";

const segmentService = {};

const isProduction = process.env.NODE_ENV === "production";
const isMixPanelEnabled = window.mixpanel && isProduction;
const isSegmentEnabled = window.analytics && isProduction;

segmentService.setUser = function () {
  const user = store.getters.user;

  if (user && user._id) {
    if (isMixPanelEnabled) {
      window.mixpanel.identify(user._id);

      window.mixpanel.people.set({
        $email: user.email,
        $userId: user._id,
        $name: `${user.firstName} ${user.lastName}`,
        $organization: user.organization,
        $version: version,
        $url: window.location.href,
      });
    }

    if (isSegmentEnabled) {
      window.analytics.identify(user._id, {
        id: user._id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        organization: user.organization,
        version,
      });
    }
  }
};

segmentService.track = function ({ name, title, ...rest }) {
  if (isMixPanelEnabled) {
    window.mixpanel.track(name, {
      title,
      ...rest,
      $url: window.location.href,
    });
  }

  if (isSegmentEnabled) {
    window.analytics.track(name, {
      title,
      ...rest,
    });
  }
};

export default segmentService;
