<script>
import PatientDashboard from "@/components/patient/dashboard/PatientDashboard.vue";
import DashboardAlerts from "@/components/dashboard/DashboardAlerts.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    PatientDashboard,
    DashboardAlerts,
  },

  computed: {
    ...mapGetters(["isPatient"]),
  },
};
</script>

<template lang="pug">
    PatientDashboard(v-if="isPatient")
    DashboardAlerts(v-else)
</template>
