import api from "./api";

const doctorService = {};
const endpoint = "/doctors";

doctorService.getDoctorDetails = function (id) {
  return api.get(`${endpoint}/${id}`).then((res) => res.data);
};

export default doctorService;
