export default {
  data() {
    return {
      showLeaveGuard: true,
    };
  },

  async beforeRouteLeave(to, from, next) {
    if (this.showLeaveGuard) {
      let shouldLeave = true;
      await this.$confirm(
        "Al cerrar la ventana se perderan los cambios no guardados, desea continuar?",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      ).catch(() => (shouldLeave = false));
      return shouldLeave ? next() : next(false);
    }

    return next();
  },
};
