import api from "./api";
import { format } from "date-fns";

const vadiguAppointmentService = {};
const endpoint = "/appointments";

vadiguAppointmentService.createAppointment = function (appointment) {
  return api.post(endpoint, appointment).then(({ data }) => data);
};

vadiguAppointmentService.getAppointments = function (params) {
  return api.get(endpoint, { params }).then((res) => res.data);
};

vadiguAppointmentService.formatDateSelected = function (date) {
  const dayBegin = new Date(date);
  const dayEnd = new Date(date);

  dayBegin.setHours(0, 0, 0);
  dayEnd.setHours(23, 59, 59);

  const parseDayBegin = format(dayBegin, "yyyy-MM-dd'T'HH:mm:ss.SSS");
  const parseDayEnd = format(dayEnd, "yyyy-MM-dd'T'HH:mm:ss.SSS");

  return { parseDayBegin, parseDayEnd };
};

vadiguAppointmentService.getWaitingRoomUrl = function () {
  return api.get(`${endpoint}/waiting-room`).then(({ data }) => data);
};

export default vadiguAppointmentService;
