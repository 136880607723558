<script>
import Chart from "vue-apexcharts";
import { format, parseISO } from "date-fns";
import Spinner from "@/components/ui/Spinner";
import patientTaskService from "@/services/patient-task";

export default {
  name: "TaskGraph",

  components: {
    Chart,
    Spinner,
  },

  props: {
    patientId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: {
        noData: {
          text: "Cargando...",
        },
        chart: {
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          brush: {
            enabled: false,
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
      },

      series: [
        {
          name: this.name,
          data: [],
        },
      ],
    };
  },

  created() {
    this.getTasks();
  },

  methods: {
    close() {
      this.$emit("close");
    },

    getTasks() {
      return patientTaskService
        .get({
          patientID: this.patientId,
          taskType: "vitals",
          query: {
            sort: { completedAt: 1 },
            completedAt: { $lte: new Date() },
          },
        })
        .then((res) => {
          const x = [];
          const y = [];

          res.tasks
            .filter((vital) => vital.name === this.name)
            .forEach((v) => {
              x.push(format(parseISO(v.completedAt), "dd/MM/yyyy-HH:mm"));
              v.answerValues.forEach((answer) => {
                const index = y.findIndex(
                  (vital) => vital.name === answer.name
                );
                if (index >= 0) {
                  y[index].data.push(answer.value);
                } else {
                  y.push({ name: answer.name, data: [answer.value] });
                }
              });
            });

          this.options = {
            xaxis: {
              categories: [...x],
            },
          };

          this.series = [...y];
        });
    },
  },
};
</script>

<template lang="pug">
  el-dialog(name="modal-task-graph", :visible.sync="showDialogModal", :before-close="close")
    h2.sign__title {{ name | capitalize }}

    chart(width="100%", type="line", :options="options", :series="series")

    .el-dialog__footer
      button.button.button--blue(@click="close") Salir
</template>
