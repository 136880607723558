import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { version } from "../../package.json";
import Vue from "vue";
import store from "@/store";

const sentryService = {};
const isEnabled = !!process.env.VUE_APP_SENTRY_DSN;

sentryService.init = function () {
  if (isEnabled) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      release: version,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    });

    this.setUser();
  }
};

sentryService.setUser = function () {
  if (isEnabled) {
    const user = store.getters.user;
    if (user) {
      Sentry.configureScope(function (scope) {
        scope.setUser({
          id: user._id,
          email: user.email,
          username: `${user.firstName} ${user.lastName}`,
        });
      });
    }
  }
};

export default sentryService;
