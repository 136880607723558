import api from "./api";
import helperService from "./helper";

const patientTaskService = {};

const endpoint = (taskType) => `/tasks/${taskType}/type/patient`;

patientTaskService.create = function ({ patientID, taskType, task }) {
  return api.post(endpoint(taskType), {
    ...task,
    patient: patientID,
  });
};

patientTaskService.get = function ({
  patientID,
  taskType,
  query,
  config = { filterMongoProps: false },
}) {
  return api
    .get(endpoint(taskType), {
      params: {
        ...query,
        ...(patientID ? { patient: patientID } : {}),
      },
    })
    .then((res) => ({
      taskType,
      tasks: config.filterMongoProps
        ? helperService.filterMongoProps(res.data.docs)
        : res.data.docs,
      total: res.data.total,
    }));
};

patientTaskService.getById = function ({ taskType, taskID }) {
  return api.get(`${endpoint(taskType)}/${taskID}`).then((res) => res.data);
};

patientTaskService.update = function ({ taskType, task, taskID }) {
  return api
    .put(`${endpoint(taskType)}/${taskID}`, task)
    .then((res) => res.data);
};

patientTaskService.delete = function ({ taskType, taskID }) {
  return api.delete(`${endpoint(taskType)}/${taskID}`).then((res) => res.data);
};

export default patientTaskService;
