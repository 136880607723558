<script>
import medicationService from "@/services/medication";
import debounce from "debounce";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "MedicationSelect",
  props: {
    usesExternalMedication: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoadingMedications: false,
      isLoadingPresentations: false,
      medications: undefined,
      selectedMedication: undefined,
      presentations: undefined,
      selectedPresentation: undefined,
    };
  },

  watch: {
    selectedMedication() {
      this.selectedMedication && this.getPresentations();
      this.$emit("update:modelValue", "");
      this.selectedPresentation = undefined;
    },

    selectedPresentation(value) {
      value &&
        this.$emit(
          "update:modelValue",
          this.usesExternalMedication
            ? this.medications.find((medication) => medication._id === value)
            : value
        );
    },
  },

  methods: {
    getMedications: debounce(async function (medicationName) {
      this.isLoadingMedications = true;

      try {
        this.medications = await medicationService.getMedications({
          name: { $regex: medicationName, $options: "i" },
        });
      } catch (error) {
        console.log(error); // TODO DEFINE THIS
      }
      this.isLoadingMedications = false;
    }, DEBOUNCE_MS_TIMEOUT),

    async getPresentations() {
      this.isLoadingPresentations = true;

      try {
        this.presentations = await medicationService.getMedications({
          name: this.medications.find(
            (medication) => medication._id === this.selectedMedication
          ).name,
        });
      } catch (error) {
        console.log(error); // TODO DEFINE THIS
      }
      this.isLoadingPresentations = false;
    },
  },
};
</script>

<template lang="pug">
.medications(v-if="!usesExternalMedication")
  fieldset.modal__field
    label.label Medicamento
    el-select(
      v-if="!readonly"
      v-model="selectedMedication"
      filterable
      remote
      clearable
      placeholder="Ingrese un medicamento"
      :remote-method="getMedications"
      :loading='isLoadingMedications'
      )
      el-option(
        v-for="medication in medications"
        :key="medication._id"
        :label="medication.name"
        :value="medication._id"
        )

  fieldset.modal__field
    label.label Presentación
    el-select(
      :loading='isLoadingPresentations'
      :placeholder="selectedMedication ? 'Seleccione una presentación' : 'Seleccione un medicamento'"
      :no-data-text="selectedMedication ? 'No hay presentaciones disponibles' : 'Seleccione un medicamento'"
      v-model="selectedPresentation"
      clearable
      )
      el-option(
        v-for="presentation in presentations"
        :key="presentation._id"
        :label="presentation.presentation"
        :value="presentation._id"
        )
.medications(v-else)
  fieldset.modal__field
    label.label Medicamento
    el-select(
      v-if="!readonly"
      v-model="selectedPresentation"
      filterable
      remote
      clearable
      placeholder="Ingrese un medicamento"
      :remote-method="getMedications"
      :loading='isLoadingMedications'
      )
      el-option(
        v-for="medication in medications"
        :key="medication._id"
        :label="medication.name"
        :value="medication._id"
        )

</template>

<style scoped>
.medications {
  width: 100%;
}
</style>
