import configService from "@/services/config";

const { theme } = configService;
const logoUrl = require(`@/assets/logos/${theme}.svg`);
const logoAltUrl = require(`@/assets/logos/${theme}-alt.svg`);

export default {
  data() {
    return {
      logoUrl,
      logoAltUrl,
    };
  },
};
