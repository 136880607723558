<script>
import Spinner from "@/components/ui/Spinner";
import appointmentService from "@/services/appointment";
import helperService from "@/services/helper";
import ModalAppointment from "@/components/appointment/ModalAppointment";

export default {
  name: "PatientAppointmentsView",

  components: {
    Spinner,
    ModalAppointment,
  },

  data() {
    return {
      isLoading: false,
      appointments: [],
      selectedSlot: undefined,
      selectedAppointment: undefined,
      isModalVisible: false,
    };
  },

  created() {
    this.getPatientAppointments();
  },

  methods: {
    onPageChange() {
      this.getPatientAppointments();
    },

    async getPatientAppointments() {
      this.isLoading = true;
      const appointments = await appointmentService.getAppointmentsByPatient({
        patientId: this.$store.state.user._id,
        params: {
          status: { $ne: "cancelled" },
          startsAt: { $gte: new Date() },
        },
      });

      this.appointments = appointments;
      this.isLoading = false;
    },

    getAttentionType(appointment) {
      const attentionTypeLabels = {
        virtual: "Virtual",
        "in-person": "Presencial",
      };

      return attentionTypeLabels[appointment.attentionType] || "N/A";
    },

    showModal(appointment) {
      this.isModalVisible = true;
      this.selectedSlot = appointment.slot;
      this.selectedAppointment = appointment;
    },

    closeModal() {
      this.isModalVisible = false;
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getMedicalSpecialty(appointment) {
      return appointment?.medicalSpecialty?.name || "N/A";
    },
  },
};
</script>

<template lang="pug">
  section
      header.headline
        //- .headline__title
          h1
            | Mis citas
      .box.box--with-subnav
        spinner(v-if="isLoading")
        h2.no-appointments(v-if="!isLoading && !appointments.length") No hay citas
        table(v-if="!isLoading && appointments.length")
          thead
            tr
              th Doctor
              th Modalidad
              th Especialidad
              th Institución
              th Desde
              th Hasta
          tbody
            tr.slot(
              v-for='appointment in appointments' :key="appointment._id" @click="showModal(appointment)")
              td {{ getFullName(appointment.doctor) }}
              td.labels
                .label {{ getAttentionType(appointment) }}
              td {{ getMedicalSpecialty(appointment) }}
              td {{ appointment.institution.name }}
              td {{ appointment.startsAt| formatDateAndTime }}
              td {{ appointment.endsAt | formatDateAndTime }}
                
      modal-appointment(
        v-if='this.isModalVisible'
        :show-dialog-modal='this.isModalVisible'
        :selected-slot='selectedSlot'
        :selected-appointment="selectedAppointment"
        @close="closeModal"
      )
</template>

<style lang="scss" scoped>
.no-appointments {
  text-align: center;
  padding: 50px;
  font-size: 2em;
  width: 100%;
}

.slot {
  cursor: pointer;

  .button {
    width: 8vw;
  }

  .labels {
    display: flex;
    align-items: center;
    column-gap: 5px;

    .label {
      border: 1px solid $primary;
      border-radius: 15px;
      padding: 5px 10px;
    }
  }
}
</style>
