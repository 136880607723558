import api from "./api";

const eventsService = {};
const endpoint = "/events";

eventsService.all = function (query = {}) {
  return api
    .get(endpoint, {
      params: query,
    })
    .then((res) => res.data);
};

eventsService.get = function (patientID) {
  return api
    .get(endpoint, {
      params: {
        populate: {
          path: "createdBy",
          select: ["firstName", "lastName"],
        },
        sort: { createdAt: 1 },
        patient: patientID,
      },
    })
    .then((res) => res.data.docs);
};

eventsService.getRequiresNewServiceEvents = function (query = {}) {
  return api
    .get(endpoint, {
      params: {
        status: "requires-new-service",
        completedAt: { $exists: false },
        populate: {
          path: "patient",
          select: "firstName lastName updatedAt",
          populate: {
            path: "doctors",
            select: "firstName lastName",
          },
        },
        ...query,
      },
    })
    .then((res) => res.data);
};

eventsService.create = function (event) {
  return api.post(endpoint, event).then((res) => res.data);
};

eventsService.update = function (event) {
  return api.put(`${endpoint}/${event._id}`, event).then((res) => res.data);
};

eventsService.removeFiles = function ({ files = [] }) {
  return api
    .delete(`${endpoint}/${event._id}/files`, { files })
    .then((res) => res.data);
};

export default eventsService;
