let messages = [];

export default async function loadAllServerMessages(channel) {
  messages = [];
  const response = await channel.getMessages(150);
  await onResponse(response);
  return messages.sort((a, b) => a.index - b.index);
}

async function onResponse(response) {
  messages.push(...response.items);

  if (response.hasPrevPage) {
    const newResponse = await response.prevPage();
    await onResponse(newResponse);
  }
}
