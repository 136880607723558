<script>
import Spinner from "@/components/ui/Spinner";
import pagination from "@/mixins/pagination";
import slotService from "@/services/slot";
import helperService from "@/services/helper";
import ModalAppointment from "@/components/appointment/ModalAppointment";
import moment from "moment";

export default {
  name: "ScheduleView",

  mixins: [pagination],

  components: {
    Spinner,
    ModalAppointment,
  },

  data() {
    return {
      isLoading: false,
      slots: [],
      filterByOccupied: true,
      selectedSlot: undefined,
      isModalVisible: false,
    };
  },

  watch: {
    filterByOccupied() {
      this.getTodaySlots();
    },
  },

  created() {
    this.getTodaySlots();
  },

  methods: {
    onPageChange() {
      this.getTodaySlots();
    },

    async getTodaySlots() {
      this.isLoading = true;
      const startOfDay = moment().startOf("day").toISOString();
      const endOfDay = moment().endOf("day").toISOString();

      const slots = await slotService.getSlotsByDoctor({
        doctorId: this.doctorId,
        params: {
          startsAt: { $gte: startOfDay },
          endsAt: { $lte: endOfDay },
          ...(this.filterByOccupied && { appointment: { $exists: true } }),
          populate: [
            {
              path: "appointment",
              populate: "patient",
            },
            { path: "medicalSpecialties institution doctor" },
          ],
          offset: this.pagination.offset,
          limit: this.pagination.limit,
        },
      });

      this.slots = slots.docs;
      this.pagination.total = slots.total || 0;
      this.isLoading = false;
    },

    getAttentionType(slot) {
      const attentionTypeLabels = {
        virtual: "Virtual",
        "in-person": "Presencial",
      };

      return slot.attentionType.map((type) => attentionTypeLabels[type]);
    },

    handleSlot(slot) {
      if (slot.appointment) {
        if (slot.appointment.attentionType === "virtual") {
          this.$router.push({
            name: "patient-videoroom",
            params: {
              id: slot.appointment.patient._id,
              appointmentId: slot.appointment._id,
            },
          });
        } else {
          this.$router.push({
            name: "patient-dashboard",
            params: { id: slot.appointment.patient._id },
          });
        }
      } else {
        if (slot.available) {
          this.showModal(slot);
        }
      }
    },

    showModal(slot) {
      this.isModalVisible = true;
      this.selectedSlot = slot;
    },

    closeModal() {
      this.isModalVisible = false;
    },

    isRoomStarted(slot) {
      return slot.appointment && slot.appointment.status === "started";
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    doctorId() {
      return this.$store.state.user._id;
    },

    getMedicalSpecialtiesNames(slot) {
      return slot.medicalSpecialties?.map((m) => m?.name).join(", ") || "N/A";
    },
  },
};
</script>

<template lang="pug">
section
    header.headline
      //- .headline__title
        h1
          | Mi día
      .headline__actions
        el-switch(
          v-model="filterByOccupied"
          active-text="Sólo citas ocupadas"
          inactive-text="Todas las citas"
        )

    .box.box--with-subnav
      spinner(v-if="isLoading")
      h2.no-slots(v-if="!isLoading && !slots.length") No hay citas
      table(v-if="!isLoading && slots.length")
        thead
          tr
            th
            th
            th Modalidad
            th Especialidad
            th Institución
            th Desde
            th Hasta
        tbody
          tr.slot(
            v-for='slot in slots' :key="slot._id" @click="handleSlot(slot)")
            td.actions
              el-button.button(v-if="slot.available") 
                | Reservar
              span(v-else)
                el-button.button(v-if="slot.appointment" :type="isRoomStarted(slot) ? 'success' : 'danger'" plain :icon="isRoomStarted(slot) ? 'el-icon-user-solid' : ''")
                  | Atender
            td  {{ slot.available ? "Libre" : slot.appointment ? getFullName(slot.appointment.patient) : 'No disponible' }}
            td.labels
              .label(v-for="label in getAttentionType(slot)") {{label}}
            td  {{ getMedicalSpecialtiesNames(slot) }}
            td  {{ slot.institution.name }}
            td {{ slot.startsAt| formatDateAndTime }}
            td {{ slot.endsAt | formatDateAndTime }}
              
    pagination(
      :isLoading="isLoading"
      :limit="pagination.limit"
      :total="pagination.total"
      @pagination="setPagination"
    )

    modal-appointment(
      v-if='this.isModalVisible'
      :show-dialog-modal='this.isModalVisible'
      :selected-slot='selectedSlot'
      @close="closeModal"
    )
</template>

<style lang="scss" scoped>
.no-slots {
  text-align: center;
  padding: 50px;
  font-size: 2em;
  width: 100%;
}

.slot {
  cursor: pointer;

  .button {
    width: 8vw;
  }

  .labels {
    display: flex;
    align-items: center;
    column-gap: 5px;

    .label {
      border: 1px solid $primary;
      border-radius: 15px;
      padding: 5px 10px;
    }
  }
}
</style>
