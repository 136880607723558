import api from "./api";

const slotService = {};
const endpoint = "/slots";

slotService.getSlotsByDate = function (params) {
  return api.get(endpoint, { params }).then((res) => res.data);
};

slotService.getSlotsAvailability = function (params) {
  return api
    .get(`${endpoint}/availability`, { params })
    .then((res) => res.data);
};

slotService.getSlotsByDoctor = function ({ doctorId, params }) {
  return api
    .get(`${endpoint}/doctor/${doctorId}`, { params })
    .then((res) => res.data);
};

slotService.disableSlotById = function ({ slotId, params }) {
  return api
    .delete(`${endpoint}/${slotId}/disable`, { params })
    .then((res) => res.data);
};

slotService.enableSlotById = function ({ slotId }) {
  return api.put(`${endpoint}/${slotId}/enable`).then((res) => res.data);
};

export default slotService;
