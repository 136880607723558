<script>
export default {
  name: "Spinner",

  props: {
    size: {
      type: String,
      default: "20",
    },
  },

  methods: {
    getDotsStyle() {
      const size = `${this.size}px`;
      return {
        margin: "0 2px",
        width: size,
        height: size,
      };
    },
  },
};
</script>

<template lang="pug">
  .spinner
    .spinner__dot.spinner__dot--bounce1(:style="getDotsStyle()")
    .spinner__dot.spinner__dot--bounce2(:style="getDotsStyle()")
    .spinner__dot.spinner__dot--bounce3(:style="getDotsStyle()")
</template>
