// Mixin to create a 2 way data binding in your model prop.
// More info: https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components

// Parent.vue
//  child(v-model="task")

// Child.vue
//  this.model = newTask <-- this will update `task` in Parent.vue

export default {
  data() {
    return {
      model: {},
    };
  },

  props: {
    value: {
      required: true,
    },
  },

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      },
    },
    model() {
      this.$emit("input", this.model);
    },
  },
};
