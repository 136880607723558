import api from "./api";

const indicationService = {};
const endpoint = "/indications";

indicationService.createIndication = function (indication) {
  return api.post(endpoint, indication).then(({ data }) => data);
};

indicationService.get = function (patientId, indicationsFilter) {
  const filters = getIndicationTypes(indicationsFilter);
  return api
    .get(endpoint, {
      params: { patientId, indicationTypeFilter: filters },
    })
    .then((res) => res.data);
};

indicationService.getById = function (indicationId) {
  return api.get(`${endpoint}/${indicationId}`).then(({ data }) => data);
};

const getIndicationTypes = (indicationsFilter) => {
  const indicationTypes = indicationsFilter.map(
    (indication) => indication.type
  );
  return indicationTypes;
};

export default indicationService;
