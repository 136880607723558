import api from "./api";

const companyService = {};
const endpoint = "/companies";

companyService.get = function () {
  return api.get(endpoint).then((res) => res.data);
};

export default companyService;
