<script>
import debounce from "debounce";
import Spinner from "@/components/ui/Spinner";
import pagination from "@/mixins/pagination";
import ModalInstitution from "@/components/settings/institution/ModalInstitution.vue";
import institutionService from "@/services/institution";
import helperService from "@/services/helper";

const DEBOUNCE_MS_TIMEOUT = 500;

export default {
  name: "InstitutionList",

  mixins: [pagination],

  components: {
    Spinner,
    ModalInstitution,
  },

  data() {
    return {
      isLoading: false,
      searchInput: "",
      institutions: [],
      isInstitutionModalVisible: false,
      selectedInstitution: {},
    };
  },

  created() {
    this.getInstitutions();

    this.$bus.$on("institution-created", (institution) => {
      this.institutions.unshift(institution);
    });
  },

  methods: {
    async getInstitutions(query = {}) {
      this.isLoading = true;

      let defaultQuery = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
        ...query,
      };

      institutionService
        .getInstitutions(defaultQuery)
        .then((res) => {
          this.institutions = res;
          this.pagination.total = res.total;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    debounceInput: debounce(function () {
      this.getInstitutions(
        helperService.buildQuery({
          searchInstitution: this.searchInput,
          queryProperties: ["name"],
        })
      );
    }, DEBOUNCE_MS_TIMEOUT),

    showInstitutionModal(institution) {
      if (institution) {
        this.selectedInstitution = institution;
      }
      this.isInstitutionModalVisible = true;
    },

    closeInstitutionModal() {
      this.isInstitutionModalVisible = false;
      this.selectedInstitution = null;
    },

    deleteInstitution(institution) {
      this.$confirm(
        `¿Desea eliminar la institución: ${institution.name}?`,
        "Bloquear",
        {
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          institutionService.deleteInstitution(institution._id);

          this.institutions = this.institutions.filter(
            (i) => i._id !== institution._id
          );
        })
        .catch(() => {});
    },

    onPageChange() {
      this.getInstitutions();
    },
  },
};
</script>

<template lang="pug">
  section.institution-list
    header.headline
      //- .headline__title
        h1
          | Instituciones
          small(v-if="pagination.total")  ({{ pagination.total }})
        hr
      .headline__actions
        el-input.search__input(
          prefix-icon="el-icon-search"
          @input="debounceInput"
          v-model="searchInput"
          placeholder="Buscar"
          :clearable="true"
        )
        .new_institution.button.button--blue.box__icon(@click='showInstitutionModal()' v-auth="['doctor.create', 'manager.create', 'assistan.create', 'admin.create']")
          micon(name="add_circle_outline").button__icon
          span.button__text Nueva Institución
    .box
      .box__content--stretch
        table
          thead
            tr
              th Nombre
              th Abreviación
              th Dirección
              th Acciones
          tbody
            spinner(v-if="isLoading")
            tr(v-else v-for="(institution, index) in institutions" :key="institution._id" @click='showInstitutionModal(institution)')
              td {{ institution.name }}
              td {{ institution.abbreviation }}
              td {{ institution.address.street }} {{institution.address.buildingNumber}}, {{ institution.address.district }} {{institution.address.postalCode }}, {{ institution.address.district }}, {{ institution.address.city }} {{ institution.address.apartmentFloor ? `, Piso ${institution.address.apartmentFloor}` : "" }} {{ institution.address.apartmentNumber ? `, Número ${institution.address.apartmentNumber}` : "" }}
              td.actions
                .actions-container
                  el-dropdown(trigger="click")
                    .button.button--action.el-dropdown-link(@click.stop)
                      micon(name="more_horiz")
                    el-dropdown-menu(slot="dropdown")
                      el-dropdown-item
                          div(@click="showInstitutionModal(institution)")
                            micon(name="edit")
                            | Editar institución
                      //- el-dropdown-item(v-if="!institution.lockedAt")
                      //-     div(@click="deleteInstitution(institution, index)")
                      //-       micon(name="lock")
                      //-       | Eliminar institución


      ModalInstitution(
        v-if='isInstitutionModalVisible'
        :current-institution='selectedInstitution'
        :show-dialog-modal='isInstitutionModalVisible' 
        @close='closeInstitutionModal'
      )

      pagination(
        :isLoading="isLoading"
        :limit="pagination.limit"
        :total="pagination.total"
        @pagination="setPagination"
      )

</template>

<style lang="scss" scoped>
@import "@/styles/settings/index.scss";
@import "@/styles/tools/index.scss";

tbody td:hover {
  cursor: pointer;
}

.box {
  height: 100%;
}

.institution-list {
  height: 65vh;
  .headline {
    .headline__title {
      @include media(desktop-up) {
        margin: 0 15px 15px 0;
      }
      @include media(web-up) {
        margin: 0 15px 0 0;
      }
    }
    .headline__actions {
      @include media(mobile-up) {
        width: 100%;
      }
      @include media(web-up) {
        width: auto;
      }
    }
  }
}
</style>
