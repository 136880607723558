<script>
/*
This whole file has a tricky workaround to handle UTC and GMT differences.
The main reason is that the calendar element ui is based in UTC, but when you get a value from it, it turns it into GMT.

For example: If you select a random date such as 15/07, the calendar element guess it's `2022-07-15T00:00:00.000Z` but when
you save the value in a prop it saves as `2022-07-14T21:00:00.000 GMT-0300`.

It's a reported bug, you can find the demos in their webpage bugging https://element.eleme.io/#/es/component/calendar
(Click on a date in Basic Calendar demo and see what happens)

I've marked the 'tricky' parts with a comment to identify faster those parts. Find the word 'workaround'.
*/
import moment from "moment";
import {
  format,
  addMilliseconds,
  startOfDay,
  isBefore,
  parseISO,
} from "date-fns";
import Spinner from "@/components/ui/Spinner";
import { mapGetters } from "vuex";
import userService from "@/services/user";
import helperService from "@/services/helper";
import ModalAppointment from "@/components/appointment/ModalAppointment";
import SlotsCalendar from "@/components/appointment/SlotsCalendar";
import institutionService from "@/services/institution";
import slotService from "@/services/slot";
import doctorService from "@/services/doctor";
import appointmentService from "@/services/appointment";
import pagination from "@/mixins/pagination";

import {
  SLOT_ATTENTION_TYPES,
  APPOINTMENTS_STATUS,
  VIDEOROOM_MM_OFFSET_PRE,
  VIDEOROOM_MM_OFFSET_POST,
} from "@/services/constants";
import { isAppointmentBookingEnabled } from "@/services/config";

export default {
  name: "AppointmentsView",

  mixins: [pagination],

  components: {
    Spinner,
    ModalAppointment,
    SlotsCalendar,
  },

  data() {
    return {
      doctors: [],
      institutions: [],
      medicalSpecialties: [],
      selectedDate: "",
      selectedDateSlots: [],
      selectedDoctorId: "",
      selectedInstitutionId: "",
      selectedMedicalSpecialtyId: "",
      selectedStatus: "",
      selectedSlot: {},
      isModalVisible: false,
      isLoadingSlots: false,
      isLoadingInstitutions: false,
      isLoadingDoctors: false,
      isLoadingDateSlots: false,
      appointmentsStatus: APPOINTMENTS_STATUS,
      isAppointmentBookingEnabled,
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isDoctor", "isPatient", "user"]),

    filteredSlots() {
      if (this.selectedStatus === "free")
        return this.selectedDateSlots.filter((slot) => slot.available);
      else if (this.selectedStatus === "busy")
        return this.selectedDateSlots.filter((slot) => !slot.available);
      return this.selectedDateSlots;
    },

    getSelectedMedicalSpecialty() {
      return this.medicalSpecialties?.find(
        (medicalSpecialty) =>
          medicalSpecialty._id === this.selectedMedicalSpecialtyId
      );
    },
  },

  watch: {
    selectedInstitutionId() {
      this.selectedDateSlots = [];
      if (this.selectedInstitutionId) {
        this.getDoctorsByInstitution(this.selectedInstitutionId);
      } else {
        this.doctors = [];
        this.medicalSpecialties = [];
        this.selectedMedicalSpecialtyId = "";

        if (!this.isDoctor) {
          this.selectedDoctorId = "";
        }
      }
    },

    selectedDoctorId() {
      this.selectedDateSlots = [];
      if (this.selectedDoctorId) {
        this.getMedicalSpecialtiesByDoctor(this.selectedDoctorId);
      } else {
        this.medicalSpecialties = [];
        this.selectedMedicalSpecialtyId = "";
      }
    },

    selectedMedicalSpecialtyId() {
      this.selectedDateSlots = [];
      this.getSlotsByDate();
    },

    selectedDate() {
      if (this.selectedDate) {
        this.getSlotsByDate();
      }
    },
  },

  created() {
    this.$bus.$on("selected-date", (selectedDate) => {
      if (!isBefore(parseISO(selectedDate), startOfDay(new Date()))) {
        this.selectedDate = selectedDate;
      } else {
        this.selectedDateSlots = [];
        this.selectedDate = "";
      }
    });

    this.$bus.$on("appointment-created", (slot, appointment) => {
      const index = this.selectedDateSlots.findIndex((elem) => {
        return elem._id === slot._id;
      });

      const createdAppointment = {
        ...this.selectedDateSlots[index],
        available: false,
        appointment,
      };

      this.$set(this.selectedDateSlots, index, createdAppointment);
    });

    this.$bus.$on("appointment-cancelled", (cancelledAppointment) => {
      const index = this.selectedDateSlots.findIndex(
        (elem) => elem.appointment?._id === cancelledAppointment._id
      );

      const updatedSlot = {
        ...this.selectedDateSlots[index],
        available: true,
        appointment: undefined,
      };

      this.$set(this.selectedDateSlots, index, updatedSlot);
    });

    if (this.isDoctor) {
      this.getDoctorDetail();
    } else {
      this.getInstitutions();
    }
  },

  methods: {
    startInstantAppointment() {
      const newSlot = {
        attentionType: ["in-person"], //TODO CHECK THIS
        available: true,
        startsAt: moment().utc().format(),
        endsAt: moment()
          .add(15, "minutes")
          .subtract(1, "milliseconds")
          .utc()
          .format(),
        isVisibleByPatient: true,
        medicalSpecialties: this.medicalSpecialties.filter(
          (elem) => elem._id === this.selectedMedicalSpecialtyId
        ),
        doctor: this.doctors.find((elem) => elem._id === this.selectedDoctorId),
        institution: this.institutions.find(
          (elem) => elem._id === this.selectedInstitutionId
        ),
      };

      this.selectedSlot = newSlot;
      this.isModalVisible = true;
    },

    async startAppointment(appointmentId) {
      await appointmentService.startAppointment(appointmentId);
    },

    async getDoctorDetail(doctorId) {
      if (this.isDoctor) {
        doctorId = this.$store.state.user._id;
      }

      const doctorDetails = await doctorService.getDoctorDetails(doctorId);
      const { doctor } = doctorDetails;

      this.doctors = [doctorDetails];
      this.selectedDoctorId = doctorDetails._id;
      this.institutions = doctor.institutions;
      this.medicalSpecialties = doctor.medicalSpecialties;
    },

    async getInstitutions() {
      this.isLoadingInstitutions = true;
      try {
        const institutions = await institutionService.getInstitutions();
        this.institutions = institutions;
      } finally {
        this.isLoadingInstitutions = false;
      }
    },

    async getDoctorsByInstitution(institutionId) {
      this.isLoadingDoctors = true;
      try {
        const doctors = await userService.getDoctorsByInstitution(
          institutionId
        );
        this.doctors = doctors;
      } finally {
        this.isLoadingDoctors = false;
      }
    },

    getMedicalSpecialtiesByDoctor(doctorId) {
      if (this.doctors) {
        const [doctor] = this.doctors.filter(
          (doctor) => doctor._id === doctorId
        );
        const medicalSpecialties = doctor.doctor.medicalSpecialties;
        this.medicalSpecialties = medicalSpecialties;
      }
    },

    async getSlotsByDate() {
      this.selectedDateSlots = [];

      if (
        this.selectedDate &&
        this.selectedDoctorId &&
        this.selectedInstitutionId &&
        this.selectedMedicalSpecialtyId
      ) {
        this.isLoadingSlots = true;
        try {
          const query = {
            startsAt: { $gte: this.getDateFirstHourOfDay(this.selectedDate) },
            endsAt: { $lte: this.getDateLastHourOfDay(this.selectedDate) },
            doctor: this.selectedDoctorId,
            medicalSpecialties: this.selectedMedicalSpecialtyId,
            institution: this.selectedInstitutionId,
            populate: [
              {
                path: "appointment",
                populate: [
                  {
                    path: "videoRoom",
                  },
                  {
                    path: "patient",
                  },
                  {
                    path: "medicalSpecialty",
                  },
                ],
              },
              { path: "doctor" },
              { path: "medicalSpecialties" },
            ],
          };

          const res = await slotService.getSlotsByDate({
            ...query,
            offset: this.pagination.offset,
            limit: this.pagination.limit,
          });

          this.selectedDateSlots = res.docs;
          this.pagination.total = res.total;
        } finally {
          this.isLoadingSlots = false;
        }
      }
    },

    getAttentionTypeName(type) {
      const attentionType = SLOT_ATTENTION_TYPES.find(
        (elem) => elem.value === type
      );
      return attentionType.name;
    },

    hasActiveRoom(slot) {
      if (slot.appointment?.videoRoom?.status === "in-progress") {
        return true;
      } else {
        return false;
      }
    },

    isVideoRoomEnabled(slot) {
      if (slot.appointment) {
        const now = new Date();

        const offsetStartsAt = new Date(slot.appointment.startsAt);
        const offsetEndsAt = new Date(slot.appointment.endsAt);

        offsetStartsAt.setMinutes(
          offsetStartsAt.getMinutes() - VIDEOROOM_MM_OFFSET_PRE
        );
        offsetEndsAt.setMinutes(
          offsetEndsAt.getMinutes() + VIDEOROOM_MM_OFFSET_POST
        );

        return (
          slot.appointment.attentionType === "virtual" &&
          offsetStartsAt <= now &&
          offsetEndsAt >= now
        );
      } else {
        return false;
      }
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    getDateFirstHourOfDay(date) {
      // workaround
      const offset = moment().utcOffset() * -1;
      const firstHour = moment(date)
        .add(offset, "minutes")
        .startOf("day")
        .utc()
        .format();

      return firstHour;
      // workaround
    },

    getDateLastHourOfDay(date) {
      // workaround
      const offset = moment().utcOffset() * -1;
      const lastHour = moment(date)
        .add(offset, "minutes")
        .endOf("day")
        .utc()
        .format();

      return lastHour;
      // workaround
    },

    getStartAndEndHours(startsAt, endsAt) {
      const parsedStartHour = format(new Date(startsAt), "HH:mm");

      let parsedEndHour = format(addMilliseconds(new Date(endsAt), 1), "HH:mm");

      // workaround
      if (parsedEndHour === "00:00") {
        parsedEndHour = "24:00";
      }
      // workaround

      return `${parsedStartHour} - ${parsedEndHour}`;
    },

    goToPatientVideoroom(patient) {
      this.$router.push({
        name: "patient-videoroom",
        params: { id: patient },
      });
    },

    getAppointmentLabel(slot) {
      if (slot.available) {
        return "Disponible";
      }
      if (slot.appointment) {
        if (this.isPatient && slot.appointment.patient?._id !== this.user._id) {
          return "Ocupado";
        }
        return this.getFullName(slot.appointment.patient);
      }
      return "No disponible";
    },

    showModal(slot) {
      if (slot.available || slot.appointment) {
        this.isModalVisible = true;
        this.selectedSlot = slot;
      }
    },

    closeModal() {
      this.isModalVisible = false;
    },

    getMedicalSpecialtiesNames(slot) {
      return slot.appointment
        ? slot.appointment.medicalSpecialty.name
        : slot.medicalSpecialties?.map((m) => m?.name).join(", ") || "N/A";
    },

    attentionType(slot) {
      return slot.appointment
        ? [slot.appointment.attentionType]
        : slot.attentionType;
    },

    onPageChange() {
      this.getSlotsByDate();
    },

    cancelAppointment(appointmentId) {
      this.$confirm(`¿Desea cancelar la cita?`, "Cancelar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }).then(() => {
        appointmentService
          .cancelAppointment(appointmentId)
          .then((cancelledAppointment) => {
            const index = this.selectedDateSlots.findIndex(
              (elem) => elem.appointment._id === cancelledAppointment._id
            );

            const updatedSlot = {
              ...this.selectedDateSlots[index],
              available: true,
              appointment: undefined,
            };

            this.$set(this.selectedDateSlots, index, updatedSlot);
          })
          .then(() => this.$message.success("Se canceló la cita con éxito"));
      });
    },

    disableSlot(slotId) {
      this.$confirm(`¿Desea deshabilitar el horario?`, "Deshabilitar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }).then(() => {
        slotService
          .disableSlotById({
            slotId,
            params: { relocate: false, remove: false },
          })
          .then(() => {
            const index = this.selectedDateSlots.findIndex(
              (elem) => elem._id === slotId
            );

            const updatedSlot = {
              ...this.selectedDateSlots[index],
              available: false,
              appointment: undefined,
            };

            this.$set(this.selectedDateSlots, index, updatedSlot);
          })
          .then(() =>
            this.$message.success("Se deshabilitó el horario con éxito")
          );
      });
    },

    enableSlot(slotId) {
      slotService
        .enableSlotById({
          slotId,
        })
        .then(() => {
          const index = this.selectedDateSlots.findIndex(
            (elem) => elem._id === slotId
          );

          const updatedSlot = {
            ...this.selectedDateSlots[index],
            available: true,
          };

          this.$set(this.selectedDateSlots, index, updatedSlot);
        })
        .then(() => this.$message.success("Se habilitó el horario con éxito"));
    },
  },
};
</script>

<template lang="pug">
  section.appointment
    header.headline
        //- .headline__title
          h1
            | Citas
          hr

        .headline__actions
          .left          
            el-select(
              v-model="selectedInstitutionId"
              placeholder="Institución"
              filterable
              clearable
              required
            )   
              el-option(
                v-for="institution in institutions"
                :key="institution._id"
                :label="institution.name"
                :value="institution._id"
              )

            el-select(
              v-if="!this.isDoctor"
              v-model="selectedDoctorId"
              placeholder="Médico"
              filterable
              clearable
              :no-data-text="selectedInstitutionId ? 'No hay doctores para el hospital seleccionado' : 'Seleccione un hospital'"
              default-first-option
            )
              el-option(
                v-for="doctor in doctors"
                :key="doctor._id"
                :label="`${getFullName(doctor)}`"
                :value="doctor._id"
              )
            
            el-select(
              v-model="selectedMedicalSpecialtyId"
              placeholder="Especialidad"
              filterable
              clearable
              required
              :no-data-text="selectedDoctorId ? 'El doctor no tiene especialidades' : 'Seleccione un doctor'"
            )   
              el-option(
                v-for="medicalSpecialty in medicalSpecialties"
                :key="medicalSpecialty._id"
                :label="medicalSpecialty.name"
                :value="medicalSpecialty._id"
              )

            el-select(
              v-model="selectedStatus"
              placeholder="Todas las citas"
              filterable
              clearable
              default-first-option
            )
              el-option(
                v-for="(item,key,index) in appointmentsStatus"
                :key="index"
                :label="item"
                :value="key"
              )

          .right
            .button.button--blue(v-if="selectedDoctorId && selectedInstitutionId && selectedMedicalSpecialtyId && !isPatient" @click='startInstantAppointment' type="primary") Crear cita instantáneo

            .button.button--blue(@click='getSlotsByDate')
              micon(name="refresh")
    .box
      section
        article.row
          nav.sidebar
            slots-calendar(
              :selectedDoctorId="selectedDoctorId" 
              :selectedInstitutionId="selectedInstitutionId",
              :selectedMedicalSpecialtyId="selectedMedicalSpecialtyId"
              )
          .box__content--stretch
            spinner(v-if="isLoadingSlots")
            h2.noAlerts(v-if="!selectedDate") Seleccionar una fecha
            div(v-if="selectedDate")
              div(v-if="selectedInstitutionId")
                div(v-if="selectedDoctorId")
                  h2.noAlerts(v-if='!selectedMedicalSpecialtyId') Seleccionar una especialidad médica
                div(v-else)
                  h2.noAlerts Seleccionar un doctor
              div(v-else)
                h2.noAlerts Seleccionar una institución
              h2.no-alerts(v-if="selectedDate && selectedMedicalSpecialtyId && selectedInstitutionId && !isLoadingSlots && !selectedDateSlots.length") No se encontraron citas
            .box.box--with-subnav(v-if="selectedDate && !isLoadingSlots && selectedDateSlots.length")
              .box__content--stretch
                table
                  thead
                    tr
                      th Doctor
                      th Especialidad
                      th Tipo de cita
                      th Fecha
                      th Horario
                      th
                      th
                  tbody
                    tr(
                      v-for='slot in filteredSlots' :key="slot._id" @click="showModal(slot)" :class="slot.available ? 'free' : 'busy'")
                      td {{ getFullName(slot.doctor) }}
                      td {{ getMedicalSpecialtiesNames(slot) }}
                      td
                        .badge.attention(
                          v-for="type in attentionType(slot)",
                          :key="type"
                        ) {{ getAttentionTypeName(type) }}
                      td {{ slot.startsAt | formatDate}}
                      td {{ getStartAndEndHours(slot.startsAt, slot.endsAt)}}
                      td
                        .badge.active-room(v-if='hasActiveRoom(slot)' @click="goToPatientVideoroom(slot.appointment.patient)") Paciente conectado
                        .badge.available-room(v-else-if='isVideoRoomEnabled(slot)') Sala habilitada
                        .badge(v-else :class="slot.available ? 'free' : slot.appointment ? 'available-room' : 'busy'")
                          | {{getAppointmentLabel(slot)}}

                      td.actions
                        .actions-container
                          el-dropdown(trigger="click")
                            .button.button--action.el-dropdown-link(@click.stop)
                              micon(name="more_horiz")
                            el-dropdown-menu(slot="dropdown")
                              el-dropdown-item(v-if="slot.appointment && slot.appointment.attentionType === 'in-person'")
                                div(@click="startAppointment(slot.appointment._id)")
                                  micon(name="person")
                                  | Admisionar paciente
                              el-dropdown-item(v-if="slot.appointment")
                                div(@click="cancelAppointment(slot.appointment._id)")
                                  micon(name="lock")
                                  | Cancelar cita
                              el-dropdown-item(v-if="slot.available || slot.appointment")
                                div(@click="disableSlot(slot._id)")
                                  micon(name="lock")
                                  | Deshabilitar horario
                              el-dropdown-item(v-if="!slot.available && !slot.appointment")
                                div(@click="enableSlot(slot._id)")
                                  micon(name="lock_open")
                                  | Habilitar horario

            pagination(
              :isLoading="isLoadingSlots"
              :limit="pagination.limit"
              :total="pagination.total"
              @pagination="setPagination"
            )

        modal-appointment(
          v-if='this.isModalVisible && this.isAppointmentBookingEnabled'
          :show-dialog-modal='this.isModalVisible'
          :selected-slot='selectedSlot'
          :selectedMedicalSpecialty="getSelectedMedicalSpecialty"
          @close="closeModal"
        )
</template>

<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box--with-subnav {
  box-shadow: none;
}

.box__content--stretch {
  position: relative;
}

h2 {
  margin-top: 20px;
  text-align: center;
}

td {
  .badge {
    margin-right: 5px;
    padding: 5px;
    border-radius: 3px;
    display: inline-block;
  }

  .busy {
    background-color: #fef0f0;
    border: 1px solid #fde2e2;
    color: #f56c6c;
  }

  .free {
    background-color: #f0f9eb;
    border: 1px solid #e1f3d8;
    color: #67c23a;
  }
}

.attention {
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
}

.empty-row {
  td {
    border: none;
  }
}

.active-room {
  background-color: green;
  color: $white;
  cursor: pointer;
}

.available-room {
  background-color: $primary;
  color: $white;
}

.row {
  display: grid;
  grid-template-columns: 40% 60%;
}
</style>
