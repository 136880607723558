import api from "./api";

const diagnosisService = {};
const endpoint = "/diagnosis";

diagnosisService.getDiagnoses = function (filter) {
  return api.get(endpoint, { params: filter }).then(({ data }) => data);
};

export default diagnosisService;
