<script>
import appointmentService from "@/services/appointment";

export default {
  name: "AppointmentCancel",

  data() {
    return {
      appointmentId: this.$route.params.appointmentId,
      isLoading: false,
      isCancelled: false,
    };
  },

  methods: {
    submit() {
      this.$confirm(`¿Desea cancelar la cita?`, "Cancelar", {
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        type: "warning",
      }).then(() => {
        appointmentService.cancelAppointment(this.appointmentId).then(() => {
          this.isCancelled = true;
        });
      });
    },
  },
};
</script>

<template lang="pug">
section.detail
    ValidationObserver(v-slot="{handleSubmit}")
        form.detail__content.layout(@submit.prevent="handleSubmit(submit)")
            h2.detail__headline.title {{isCancelled ? "Cita cancelada" : "Cancelar cita"}}
            el-button.button.button--blue(
                native-type="submit"
                :loading="isLoading"
                :disabled="isCancelled"
            ) Cancelar
</template>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
</style>
