import api from "./api";

const institutionService = {};
const institutionEndpoint = "/institutions";

institutionService.createInstitution = function (institution) {
  return api.post(institutionEndpoint, institution).then(({ data }) => data);
};

institutionService.updateInstitution = function (institution) {
  return api
    .put(`${institutionEndpoint}/${institution._id}`, institution)
    .then(({ data }) => data);
};

institutionService.getInstitutions = function (queryParams) {
  return api
    .get(institutionEndpoint, { params: queryParams })
    .then((res) => res.data);
};

institutionService.deleteInstitution = function (institutionId) {
  return api.delete(`${institutionEndpoint}/${institutionId}`);
};

institutionService.getInstitutionById = function (institutionId) {
  return api
    .get(`${institutionEndpoint}/${institutionId}`)
    .then((res) => res.data);
};

export default institutionService;
