import Login from "@/components/public/Login.vue";
import PasswordReset from "@/components/public/PasswordReset.vue";
import PasswordForgot from "@/components/public/PasswordForgot.vue";
import InviteValidate from "@/components/public/InviteValidate.vue";
import Error from "@/components/public/Error.vue";

const publicRoutes = [
  {
    path: "/login",
    name: "login",
    meta: { isPublic: true },
    component: Login,
  },
  {
    path: "/password-forgot",
    name: "password-forgot",
    meta: { isPublic: true },
    component: PasswordForgot,
  },
  {
    path: "/password-reset/:token",
    name: "password-reset",
    meta: { isPublic: true },
    component: PasswordReset,
  },
  {
    path: "/invite/:code",
    name: "invite-validate",
    meta: { isPublic: true },
    component: InviteValidate,
  },
  {
    path: "*",
    name: "error",
    meta: { isPublic: true },
    component: Error,
  },
];

export default publicRoutes;
