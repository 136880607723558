import api from "./api";

const labelsService = {};
const endpoint = "/labels";

labelsService.getLabels = function () {
  return api.get(endpoint, { sort: { name: -1 } }).then((res) => res.data.docs);
};

labelsService.createLabel = function (dataLabel) {
  return api.post(endpoint, dataLabel).then(({ data }) => data);
};

labelsService.updateLabel = function (label) {
  console.log(label);
  return api.put(`${endpoint}/${label.id}`, label).then(({ data }) => data);
};

labelsService.delete = function (id) {
  return api.delete(`${endpoint}/${id}`).then(({ data }) => data);
};

export default labelsService;
