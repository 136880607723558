<script>
import model from "@/mixins/model";
import patientService from "@/services/patient";

export default {
  name: "NotesBox",

  mixins: [model],

  props: {
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      notesValue: "",
      showForm: false,
      isPosting: false,
      isContentHidden: this.isCollapsable ? true : false,
    };
  },

  created() {
    this.notesValue = this.model.patient.notes || "";
  },

  methods: {
    showNotesForm() {
      this.showForm = true;
    },
    hideNotesForm() {
      this.showForm = false;
    },
    updateNotes() {
      this.isPosting = true;
      this.notesValue = this.notesValue.trim();

      const patient = {
        ...this.model,
        patient: { ...this.model.patient, notes: this.notesValue },
      };
      patientService
        .update(patient)
        .then(() => {
          this.$set(this.model.patient, "notes", this.notesValue);
        })
        .finally(() => {
          this.isPosting = false;
          this.showForm = false;
        });
    },
    toggleHideContent() {
      this.isContentHidden = !this.isContentHidden;
    },
  },
};
</script>

<template lang="pug">
  .box.notes-box
    .header
      .expand(v-if="isCollapsable" @click='toggleHideContent()' :class="isContentHidden ? 'rotate' : ''")
          micon(name="expand_more")
      h1 Notas
      .buttons
        a(v-show="showForm" @click="hideNotesForm()")
          micon(name="clear" v-tooltip="'Cancelar'")
        a(v-show="!showForm" @click="showNotesForm()")
          micon(v-show="!isContentHidden" name="edit" v-tooltip="'Editar Notas'")
    .content(:class="(isCollapsable && isContentHidden) ? 'hide-content' : '' ")
      .data(v-if="!showForm && model.patient.notes") {{ model.patient.notes }}
      .no-data(v-else-if="!showForm && !model.patient.notes") Sin Notas
      form(v-else)
        el-input(v-model="notesValue", type="textarea", resize="none")
        el-button(
          type="primary",
          @click.prevent="updateNotes",
          :disabled="isPosting"
        ) Guardar
</template>

<style lang="scss" scoped>
.box.notes-box {
  .header .buttons > a {
    margin-right: 0;
  }
  .content form {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .expand {
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    transform: rotate(180deg);
  }

  .rotate {
    transform: rotate(0deg);
  }
  .content {
    max-height: 190px;
    visibility: visible;
    overflow: scroll;
    transition: all 0.4s ease-in-out;
  }

  .hide-content {
    visibility: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: all 0.4s ease-in-out;
  }
}
</style>
<style lang="scss">
.notes-box {
  .data {
    width: 100%;
    color: $cuttysark;
    white-space: pre-wrap;
  }
  .el-textarea {
    margin-bottom: 15px;
    flex: 1;
    textarea {
      height: 100%;
    }
  }
}
</style>
