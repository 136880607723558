const userRoutes = [
  {
    path: "/user",
    component: () =>
      import(/* webpackChunkName: "user" */ "../layouts/UserLayout.vue"),
    children: [
      {
        path: "/user",
        name: "users-list",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../components/user/UserList.vue"
          ),
      },
      {
        path: "/user/create",
        name: "create-user",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../components/user/UserDetail.vue"
          ),
      },
      {
        path: "/user/:id",
        name: "update-user",
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../components/user/UserDetail.vue"
          ),
      },
    ],
  },
];

export default userRoutes;
