import api from "./api";

const medicationService = {};
const medicationEndpoint = "/medications";

medicationService.getMedications = function (query = {}) {
  return api
    .get(medicationEndpoint, { params: query })
    .then(({ data }) => data);
};

export default medicationService;
