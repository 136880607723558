import { render, staticRenderFns } from "./ModalMedicalInsurance.vue?vue&type=template&id=830c6cfc&scoped=true&lang=pug&"
import script from "./ModalMedicalInsurance.vue?vue&type=script&lang=js&"
export * from "./ModalMedicalInsurance.vue?vue&type=script&lang=js&"
import style0 from "./ModalMedicalInsurance.vue?vue&type=style&index=0&id=830c6cfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "830c6cfc",
  null
  
)

export default component.exports