import api from "./api";

const medicalInsuranceService = {};
const endpoint = "/medical-insurance";

medicalInsuranceService.get = function () {
  return api.get(endpoint).then((res) => res.data);
};

medicalInsuranceService.createMedicalInsurance = function (medicalInsurance) {
  return api.post(endpoint, medicalInsurance).then((res) => res.data);
};

export default medicalInsuranceService;
