<template>
  <svgicon
    :style="style"
    class="micon"
    :name="name"
    :width="width"
    :height="height"
  ></svgicon>
</template>

<script>
export default {
  name: "MIcon",

  props: {
    name: { type: String, required: true },
    width: { type: String },
    height: { type: String },
    preload: { type: Array, default: () => [] },
    color: { type: String },
    padding: { type: String },
  },

  computed: {
    style() {
      if (!this.color) {
        return;
      }

      return { fill: this.color, padding: this.padding };
    },
  },

  created() {
    this.preload
      .concat(this.name)
      .forEach((name) =>
        import(/* webpackChunkName: "icon-[request]" */ "../icons/" + name)
      ); // eslint-disable-line
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  display: inline-block;
  width: 23px;
  height: 23px;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: $primary;
}

.svg-fill {
  fill: $primary;
  stroke: none;
}

.svg-right {
  transform: rotate(90deg);
}

.svg-down {
  transform: rotate(180deg);
}

.svg-left {
  transform: rotate(-90deg);
}
</style>
