<script>
export default {
  name: "ModalPatientAppointments",

  components: {},

  data() {
    return {
      isLoading: false,
      patientId: this.$route.params.id,
    };
  },

  props: {
    appointments: {
      type: Array,
      required: true,
    },
    showDialogModal: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    goToPatientVideoRoom({ appointmentId }) {
      this.$router.push({
        name: "patient-videoroom",
        params: { id: this.patientId, ...(appointmentId && { appointmentId }) },
      });
    },
  },
};
</script>

<template lang="pug">
  el-dialog.modal-container(name="modal-patient-apppointments", :visible.sync="showDialogModal", :before-close="close", width="70%")
    h2 Citas disponibles

    table
        thead
        tr
            th Modalidad
            th Tipo de Ejercicio
            th Duración
        tbody
        tr.clickable(v-for="appointment in appointments", :key="appointment._id", @click="goToPatientVideoRoom({appointmentId: appointment._id})")
            td {{ appointment.attentionType }}
            td {{ appointment.startsAt | formatDateAndTime }}
            td {{ appointment.endsAt | formatDateAndTime }}
    .footer
      fieldset(style="margin-left: auto")
        button.button.button--blue(@click="goToPatientVideoRoom") Ir a la videollamada
        button.button.button--blue(@click="close") Salir
</template>

<style lang="scss" scoped>
.clickable:hover {
  cursor: pointer;
}
.disabled {
  cursor: none;
  pointer-events: none;
}
.head__actions {
  display: flex;
  justify-content: space-evenly;
  margin: 15px 0;
}
.head__search > * {
  margin: 0 0 0 15px;
}
.head__search {
  display: flex;
  align-items: center;
  word-break: normal;
}
.box {
  margin: 10px;
}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.box__footer {
  position: inherit;
  width: 100%;
}
.data {
  justify-content: space-evenly;
  display: grid;
  grid-template-columns: auto auto;
}
.value {
  margin: 5px;
}
</style>
