import api from "./api";

const appointmentService = {};
const endpoint = "/appointments";

appointmentService.createAppointment = function (appointment) {
  return api.post(endpoint, appointment).then(({ data }) => data);
};

appointmentService.getAppointmentById = function ({ id, params }) {
  return api.get(`${endpoint}/${id}`, { params }).then(({ data }) => data);
};

appointmentService.getAppointmentsByPatient = function ({ patientId, params }) {
  return api
    .get(`${endpoint}/patient/${patientId}`, { params })
    .then(({ data }) => data);
};

appointmentService.getAppointments = function ({ params }) {
  return api.get(endpoint, { params }).then(({ data }) => data);
};

appointmentService.cancelAppointment = function (id) {
  return api.delete(`${endpoint}/${id}`).then(({ data }) => data);
};

appointmentService.startAppointment = function (id) {
  return api.put(`${endpoint}/${id}`).then(({ data }) => data);
};

export default appointmentService;
