<script>
import Spinner from "@/components/ui/Spinner";
import MonitoringTag from "@/components/dashboard/MonitoringTag";
import helperService from "@/services/helper";
import { differenceInHours, parseISO } from "date-fns";

export default {
  name: "MonitoringCard",

  components: {
    Spinner,
    MonitoringTag,
  },

  props: {
    filterByActiveTreatment: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    getTooltip(treatments) {
      return treatments.reduce(
        (acc, treatment) =>
          (acc += `${
            treatment?.treatment?.name
              ? treatment.treatment.name
              : "Tratamiento sin nombre"
          } <br>`),
        ""
      );
    },

    getTreatmentName(treatments) {
      return treatments[0]?.treatment?.name || "Tratamiento sin nombre";
    },

    goToPatientDashboard(id) {
      helperService.callIfNoSelectedText(() =>
        this.$router.push({
          name: "patient-dashboard",
          params: { id },
        })
      );
    },

    getFullName(user) {
      return helperService.getFullName(user);
    },

    doesPatientRequiresVerification(user) {
      const { lastEvent } = user.patient;
      const { lastEventCreatedAt } = user.patient;
      if (!lastEvent || !lastEventCreatedAt) {
        return true;
      }

      return differenceInHours(new Date(), parseISO(lastEventCreatedAt)) >= 24;
    },
  },
};
</script>

<template lang="pug">
.card(@click="goToPatientDashboard(user._id)")
  .left-column
    .title
      el-avatar(icon="el-icon-user-solid" :src="user.avatar ? user.avatar.key : 'null'")
      .name
        h2 {{ getFullName(user)}}

    .details
      .protocols(v-if="!isLoading")
        .tag(v-if="!user.assignedTreatments.length") Sin protocolos asignados
        .tag(v-else :key="user.assignedTreatments[0]._id") {{ getTreatmentName(user.assignedTreatments) }}
        .tag(v-if="user.assignedTreatments.length > 1" v-tooltip.bottom="{content: getTooltip(user.assignedTreatments.slice(1))}") ...
      span(v-if="user.patient.lastEventCreatedAt") Última actividad: Hace {{ user.patient.lastEventCreatedAt | formatDistanceStrict }}
      span(v-else) Sin actividad

  .right-column
    monitoring-tag(type="red" name="Rojas" :count="user.patient.aggregates.red")
    monitoring-tag(type="yellow" name="Amarillas" :count="user.patient.aggregates.yellow")
    monitoring-tag(type="green" name="Verdes" :count="user.patient.aggregates.green")
    monitoring-tag(type="incomplete" name="Incompletas" :count="user.patient.aggregates.incomplete")
    monitoring-tag(v-if="doesPatientRequiresVerification(user)" type="without-checking" name="Sin Revisar")


</template>

<style lang="scss" scoped>
.card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;

  .left-column {
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .details {
      .protocols {
        margin-bottom: 5px;
        .tag {
          display: inline-block;
          margin: 0 5px 5px 0;
        }
      }
    }
  }

  .right-column {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
