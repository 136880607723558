<script>
import leave from "@/mixins/leave";
import labelService from "@/services/labels";
import Spinner from "@/components/ui/Spinner";

export default {
  name: "ModalLabel",
  mixins: [leave],

  components: {
    Spinner,
  },

  props: {
    showDialogModal: {
      type: Boolean,
    },
    currentLabel: {
      type: Object,
    },
  },

  data() {
    return {
      label: {},
      organization: "",
      isPostingLabel: false,
      isUpdate: this.currentLabel._id,
    };
  },

  created() {
    if (this.isUpdate) {
      this.label = this.currentLabel;
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    escape(event) {
      if (event.keyCode == 27) this.close();
    },

    close() {
      this.isPostingLabel = false;
      this.$emit("close");
    },

    getButtonName() {
      if (this.isUpdate) {
        return "Actualizar Etiqueta";
      }
      return "Nueva Etiqueta";
    },

    submit() {
      if (this.isUpdate) {
        this.updateLabel();
      } else {
        this.createLabel();
      }
    },

    async createLabel() {
      this.isPostinLabelgLabel = true;
      const newLabel = await labelService.createLabel(this.label);
      this.$bus.$emit("label-created", newLabel);
      this.isPostingLabel = false;
      this.showLeaveGuard = false;
      this.close();
    },

    updateLabel() {
      this.isPosting = true;

      labelService
        .updateLabel(this.label)
        .then((label) => {
          this.$bus.$emit("label-updated", label);
          this.close();
        })
        .catch(() => {
          this.isPosting = false;
        });
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver(v-slot="{handleSubmit}")
    form.modal(@submit.prevent="handleSubmit(submit)")
      header.modal__header
        h2.modal__title {{ getButtonName() }}
        .modal__actions
          el-button(type="info" @click="close()") Cancelar
          el-button.border(type="primary" native-type="submit" :loading="isPostingLabel") Guardar
      p.modal__subtitle Los campos con (*) son obligatorios
      .modal__content(v-if="isPostingLabel")
        spinner
      .modal__content(v-else)
        // Treatment Info Section
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="description")
              h3.sign__title Información de la etiqueta
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  ValidationProvider(name="Nombre" , rules="required", v-slot="{ errors }")
                    label.label * Nombre de la etiqueta
                    el-input(v-model="label.name", autofocus)
                    span.has-error {{ errors[0] }}
              .modal__row
                fieldset.modal__field
                  label.label Color
                  el-input.color-label(
                    type="color"
                    v-model="label.color"
                    name="Color"
                    rows="1"
                  )
            article.modal__fields
</template>

<style scoped>
.color-label {
  width: 50%;
}

.modal {
  z-index: 999;
}
</style>
